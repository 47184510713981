import ProfileSubHeader from "../../atoms/ProfileSubHeader";
import ProfileTriviaInfo from "./atoms/ProfileTriviaInfo";

interface ProfileTriviaProps{
    trivia?: string;
}

const ProfileTrivia: React.FC<ProfileTriviaProps> = (props) => {

    return(
        <>
        {props.trivia && props.trivia !== "" &&
            <>
                <ProfileSubHeader headerValue="Mer om mig"/>
                <ProfileTriviaInfo trivia={props.trivia}/>
            </>
        }
        </>
    )
}

export default ProfileTrivia;