import { Container } from "react-bootstrap";
import AdminAccordionSkills from "../../molecules/AdminAccordionSkills/AdminAccordionSkills";
import AdminAccordionJobTitles from "../../molecules/AdminAccordionJobTitles/AdminAccordionJobTitles";
import "../../../index.css";
import "./AdminPage.css";
import AdminHeader from "./atoms/AdminHeader";
import AdminAccordionActivation from "../../molecules/AdminAccordionActivation/AdminAccordionActivation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useNavigate } from "react-router-dom";
import { updateIsMyProfileActive } from "../../../redux/slices/userSlice";

const AdminPage: React.FC = () => {
  const adminValue = process.env.REACT_APP_ADMIN_ROLE;

  const dispatch = useDispatch();
  const redirect = useNavigate();

  const appRoles: string[] | undefined = useSelector(
    (state: RootState) => state.user.appRoles
  );

  useEffect(() => {
    dispatch(updateIsMyProfileActive(false));
  }, [dispatch]);

  useEffect(() => {
    if (
      appRoles &&
      appRoles.length > 0 &&
      appRoles.some((x) => x === adminValue)
    )
      return;
    else redirect("/");
  }, [appRoles, redirect, adminValue]);

  return (
    <Container className="responsive-page-container mt-3" fluid>
      <AdminHeader />
      <AdminAccordionSkills />
      <AdminAccordionActivation />
      <AdminAccordionJobTitles />
    </Container>
  );
};

export default AdminPage;
