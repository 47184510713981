import { ManagerNameDto } from "../models/Dto/ManagerNameDto";
import { OfficeLocationDto } from "../models/Dto/OfficeLocationDto";
import { axiosClient } from "../utilities/axiosClient";

const uri: string = process.env.REACT_APP_BASE_URI!;

export const getAllOfficeLocations = async (): Promise<string[]> => {
    try {
        const response = await axiosClient.get<string[]>(`${uri}api/OfficeLocation/OfficeLocations`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching officeLocations', error);
        throw error;
    }
}

export const GetAllJobTitlesByOfficeLocation = async (officeLocation: string): Promise<string[]> => {
    try {        
        const response = await axiosClient.get<string[]>(`${uri}api/JobTitle/JobTitles/${officeLocation}`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching jobTitles', error);
        throw error;
    }
}

export const getAllManagersByOfficeLocation = async (activeSearchArea: string): Promise<ManagerNameDto[]> => {
    try {        
        const response = await axiosClient.get<ManagerNameDto[]>(`${uri}api/OfficeLocation/Managers/${encodeURIComponent(activeSearchArea)}`);        
        return response.data;
    }
    catch (error) {
        console.error('Error fetching managers', error);
        throw error;
    }
};

export const getOfficeLocation = async (location: string): Promise<OfficeLocationDto> => {
    try {        
        const response = await axiosClient.get<OfficeLocationDto>(`${uri}api/OrgTree/${location}`);        
        return response.data;
    } catch (error) {
        console.error('Error fetching officeLocation', error);
        throw error;
    }
};

export const getRegions = async (): Promise<string[]> => {
    try {        
        const response = await axiosClient.get<string[]>(`${uri}api/OfficeLocation/regions`);        
        return response.data;
    } catch (error) {
        console.error('Error fetching regions', error);
        throw error;
    }
};