import { MailFilled } from "@ant-design/icons";
import { Button } from "react-bootstrap";
import "./ContactButton.css";

interface ButtonEmailProps {
  email: string;
}

const ButtonEmail: React.FC<ButtonEmailProps> = (props) => {
  const handleClick = (e: any) => {
    window.location.href = `mailto:${props.email}`;
    e.preventDefault();
  };

  return (
    <Button
      className="contact-button pt-2 px-3 d-flex align-items-center shadow"
      onClick={handleClick}
    >
      <MailFilled />
    </Button>
  );
};

export default ButtonEmail;
