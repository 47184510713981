import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import BrowsePage from "./components/pages/BrowsePage/BrowsePage";
import OrganisationPage from "./components/pages/OrganisationPage/OrganisationPage";
import SignInPage from "./components/pages/SignInPage/SignInPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./fonts/fonts.css";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  IPublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Header from "./components/organisms/Header/Header";
import "./styles.css";
import UserProfile from "./components/organisms/UserProfile/UserProfile";
import MyProfilePage from "./components/pages/MyProfilePage/MyProfilePage";
import AdminPage from "./components/pages/AdminPage/AdminPage";
import RootPage from "./components/pages/RootPage/RootPage";

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  pca.initialize().then(() => {
    pca.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        pca.setActiveAccount(account);
      }
    });
  });

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
          <Header msalInstance={pca} />
          <Routes>
            <Route path="/" element={<RootPage />} />
            <Route path="/browse" element={<BrowsePage />} />
            <Route path="/organisation" element={<OrganisationPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/userprofile/:userId" element={<UserProfile />} />
            <Route path="/myprofile" element={<MyProfilePage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <SignInPage />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </QueryClientProvider>
  );
}

export default App;
