// import { decodeFromBase64 } from "pdf-lib";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
interface PortraitProps {
  src?: string;
  height?: number;
  alt?: string;
  className?: string;
  tabIndex?: number;
  url?: string;
}

const Portrait: React.FC<PortraitProps> = (props) => {
  const [imageSrc, setImageSrc] = useState<string>("");
  const imageHeight = `${props.height || 125}px`;
  const altTitle = `${props.alt || "Portrait"}`;

  useEffect(() => {
    if (!props.src) return;
    try {
      const byteArray = Array.from(atob(props.src), (c) => c.charCodeAt(0));
      const imageData = btoa(String.fromCharCode.apply(null, byteArray));
      const imageUrl = `data:image/png;base64,${imageData}`;

      setImageSrc(imageUrl);
    } catch {
      return;
    }
  }, [props.src]);

  return (
    <Image
      src={props.url ? props.url : imageSrc}
      height={imageHeight}
      alt={altTitle}
      {...(props.tabIndex != null && { tabIndex: props.tabIndex })}
      className={props.className}
      style={{ borderRadius: "10px" }}
      width={"auto"}
    />
  );
};

export default Portrait;
