import { Card, Row } from "react-bootstrap";

const NoStoriesCard: React.FC = () => {
  return (
    <Card className="my-2">
      <Card.Body>
        <Row>
          <p>Det finns inga stories att visa!</p>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NoStoriesCard;
