import { createSlice } from '@reduxjs/toolkit'
import { AdminMessageDto } from '../../models/Dto/AdminMessageDto'

export interface UserState {
  userName: string
  givenName?: string
  userId: string
  officeLocation?: string
  photo?: string
  isSigningIn: boolean 
  userSelectedSearchArea?: string;
  officeLocations: string[];
  showInfo: boolean;
  latestLogin: string;
  isEligibleForAssignments: boolean;
  isMyProfileActive: boolean;  
  adminMessages: AdminMessageDto[];
  initialOfficeLocation: string;  
  appRoles: string[];
}

const initialState: UserState = {
  userName: "Unknown",
  givenName: "",
  officeLocation: "",
  userId: "",
  photo: "",
  userSelectedSearchArea: "",
  isSigningIn: true,  
  officeLocations: [],
  showInfo: true ,
  latestLogin: "",
  isEligibleForAssignments: true,
  isMyProfileActive: false,
  adminMessages: [],
  initialOfficeLocation: ""  ,
  appRoles: []
} 

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserName: (state, action) => {
      state.userName = action.payload
    },
    updateGivenName: (state, action) => {
      state.givenName = action.payload
    },
    updateUserId: (state, action) => {
      state.userId = action.payload
    },
    updateOfficeLocation: (state, action) => {
      state.officeLocation = action.payload
    },
    updatePhoto: (state, action) => {
      state.photo = action.payload
    },
    updateIsSigningIn: (state, action) => {
      state.isSigningIn = action.payload
    },
    updateUserSelectedSearchArea: (state, action) => {
      state.userSelectedSearchArea = action.payload;
    },
    updateOfficeLocations: (state, action) => {
      state.officeLocations = action.payload;
    },
    updateShowInfo: (state, action) => {
      state.showInfo = action.payload;
    },
    updateLatestLogin: (state, action) => {
      state.latestLogin = action.payload;
    },
    updateIsEligibleForAssignments: (state, action) => {
      state.isEligibleForAssignments = action.payload;
    },
    updateIsMyProfileActive: (state, action) => {
      state.isMyProfileActive = action.payload;
    },
    updateAdminMessages: (state, action) => {
      state.adminMessages = action.payload;
    },
    updateInitialOfficeLocation: (state, action) => {
      state.initialOfficeLocation = action.payload;
    }    
    ,
    setAppRoles: (state, action) => {
      state.appRoles = action.payload;
    },
    resetStore: (state) =>{
          state.userName = "Unknown";
          state.givenName = "";
          state.officeLocation = "";
          state.userId = "";
          state.photo = "";
          state.userSelectedSearchArea = "";
          state.isSigningIn = true;
          state.officeLocations = [];
          state.showInfo = true ;
          state.latestLogin = "";
          state.isEligibleForAssignments = true;
          state.isMyProfileActive = false;
          state.adminMessages = [];
          state.initialOfficeLocation = ""  ;
          state.appRoles = [];
    }
  }
})

export const { 
  updateUserId, 
  updateUserName, 
  updateGivenName, 
  updateOfficeLocation, 
  updatePhoto, 
  updateIsSigningIn, 
  updateUserSelectedSearchArea, 
  updateOfficeLocations, 
  updateShowInfo, 
  updateLatestLogin, 
  updateIsEligibleForAssignments, 
  updateIsMyProfileActive, 
  // setIsUserAdmin, 
  updateAdminMessages, 
  updateInitialOfficeLocation, 
  setAppRoles,
  resetStore,
} 
  = userSlice.actions


export default userSlice.reducer