import { Col, Row } from "react-bootstrap";
import "./SearchResults.css";
import {
  BrowseEmployeeDto,
  Pagination,
} from "../../../models/Dto/EmployeeSummaryDto";
import Loading from "../../atoms/ReactQueryLoading/Loading";
import EmployeeSummaryCard from "../../molecules/EmployeeSummaryCard/EmployeeSummaryCard";
import React, { SetStateAction } from "react";
import PageSelector from "../../molecules/PageSelector/PageSelector";
import SearchQuery from "../../molecules/SearchQuery/SearchQuery";
import { SearchType } from "../../../models/Enums/SearchType";
import Error from "../../atoms/ReactQueryError/ErrorHandler";
import { SkillCountDto } from "../../../models/Dto/SkillCountDto";

interface SearchResultsProps {
  searchResults?: BrowseEmployeeDto[];
  isLoading: boolean;
  errorMessage?: string;
  pagination: Pagination;
  searchType: SearchType;
  searchValue: string;
  searchInfo: string;
  didSearchReturnEmployee: boolean;
  selectedManager: string;
  isShowOnlyFreeEmployeesChecked: boolean;
  skills: SkillCountDto[];
  expirationDate: string;
  triggerReRender: boolean;
  setTriggerReRender: React.Dispatch<SetStateAction<boolean>>;
  setPagination: React.Dispatch<SetStateAction<Pagination>>;
}

const SearchResults: React.FC<SearchResultsProps> = (props) => {
  if (props.isLoading) {
    return <Loading />;
  }

  if (props.errorMessage) {
    return <Error queryError={props.errorMessage} />;
  }

  return (
    <>
      <Row className="mt-3">
        <Col className="d-flex justify-content-start">
          <SearchQuery
            triggerReRender={props.triggerReRender}
            setTriggerReRender={props.setTriggerReRender}
            skills={props.skills}
            searchResults={props.searchResults}
            didSearchReturnEmployee={props.didSearchReturnEmployee}
            searchType={props.searchType}
            searchInfo={props.searchInfo}
            selectedManager={props.selectedManager}
            isShowOnlyFreeEmployeesChecked={
              props.isShowOnlyFreeEmployeesChecked
            }
            expirationDate={props.expirationDate}
          />
          {/* <SearchResultsLimiter
            pagination={props.pagination}
            setPagination={props.setPagination}
          /> */}
        </Col>
      </Row>
      <Row className="mt-3">
        {props.searchResults &&
          props.searchResults.length > 0 &&
          props.searchResults.map((employee) => (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={6}
              xs={8}
              className="mx-auto"
              key={employee.userId}
            >
              <EmployeeSummaryCard employee={employee} />
            </Col>
          ))}
      </Row>
      <PageSelector
        pagination={props.pagination}
        setPagination={props.setPagination}
      />
    </>
  );
};

export default SearchResults;
