import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./OrganisationTreeMobile.css";
import { OfficeLocationDto } from "../../../models/Dto/OfficeLocationDto";
import EmployeeTree from "./EmployeeTree";
import { useQuery } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getOfficeLocation } from "../../../services/OfficeLocationService";

const OrganisationTreeMobile: React.FC = () => {
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const includePhoto = false;
  const { data, error, isLoading } = useQuery<OfficeLocationDto, Error>({
    queryFn: () => getOfficeLocation(!officeLocation ? "" : officeLocation),
    queryKey: ["officeEmployees", officeLocation],
  });

  if (isLoading) {
    return (
      <Container
        fluid
        className="mt-3 d-flex justify-content-center align-items-center"
      >
        <span>
          <h3>
            Laddar... <LoadingOutlined />
          </h3>
        </span>
      </Container>
    );
  }

  if (error) {
    return (
      <Container fluid className="mt-3 d-flex justify-content-center">
        <span>
          <h2>Error: {error.message}</h2>
        </span>
      </Container>
    );
  }

  return (
    <Row>
      <Col>
        <Container fluid className="my-3">
          <Row className="consid-stad text-center">
            <Col className="d-flex justify-content-center">
              <h1>Consid {officeLocation} Organisationsträd</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={10} md={10} sm={10} xs={12}>
              {data && (
                <EmployeeTree
                  employees={data.employees}
                  includePhoto={includePhoto}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default OrganisationTreeMobile;
