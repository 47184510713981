import { Button } from "react-bootstrap";
import "./ButtonCancel.css";

interface ButtonCancelProps {
  text: string;
  isLarge?: boolean;
  handleClick: () => void;
  disabled?: boolean;
  className?: string;
}

const ButtonCancel: React.FC<ButtonCancelProps> = (props) => {
  return (
    <Button
      onClick={props.handleClick}
      size={!props.isLarge ? "sm" : "lg"}
      className={`consid-cancel-btn py-2 px-3 ${props.className}`}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
};

export default ButtonCancel;
