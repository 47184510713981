import { Card } from "react-bootstrap";
import "../AdminAccordionSkills.css";
import { SkillFromDbDto } from "../../../../models/Dto/SkillFromDbDto";
import { StoryboardSkillAdd } from "../../../../models/Dto/StoryBoardSkillAdd";
import CardList from "./CardList";

interface CardSkillProps {
  skills: SkillFromDbDto[];
  handleSkillClick: (skill: StoryboardSkillAdd | undefined) => void;
}

const CardSkill: React.FC<CardSkillProps> = (props) => {
  return (
    <Card
      className="card-style"
      style={{
        overflowY: "scroll",
      }}
    >
      <CardList
        handleSkillClick={props.handleSkillClick}
        skills={props.skills}
      />
    </Card>
  );
};

export default CardSkill;
