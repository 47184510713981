import { BrowseDto, BrowseEmployeeDto } from "../models/Dto/EmployeeSummaryDto";
import { EmployeeTotalDto } from "../components/organisms/UserProfile/models/EmployeeTotalDto";
import { EmployeeDetailsUpdateDto } from "../models/Dto/EmployeeDetailsUpdateDto";
import { EmployeeActivationDto } from "../models/Dto/EmployeeActivationDto";
import { UpdateMissingInfoDto } from "../models/Dto/UpdateMissingInfoDto";
import { CarouselDto } from "../models/Dto/CarouselDto";
import { UpdateShowTutorial } from "../models/Dto/UpdateShowTutorialDto";
import { EmployeeSetAssignmentEligibiltyDto } from "../models/Dto/EmployeeSetAssignmentEligibilityDto";
import { SearchFilters } from "../models/interfaces/SearchFilters";
import { axiosClient } from "../utilities/axiosClient";

const uri: string = process.env.REACT_APP_BASE_URI!;

export const getBrowseEmployee = async (
    location: string,
    requestedPage: number,
    limit: number,
    searchString?: string,
    filters?: SearchFilters,
    showOnlyUnassigned?: boolean,
    expirationDate?: string,
  ): Promise<BrowseDto> => {
    const requestedPageQuery = `?requestedPage=${requestedPage}`;
    const limitQuery = `&limit=${limit}`;
    const searchQuery = searchString === undefined || searchString === "" ? "" : `&searchValue=${searchString}`;
    const filterQuery = filters ? getFilterQuery(filters) : "";
    const unassignedQuery = showOnlyUnassigned ? `&showOnlyUnAssigned=${showOnlyUnassigned}` : "";
    const expirationQuery = expirationDate === undefined || expirationDate === "" ? "" : `&expirationDate=${expirationDate}`;
  
    try {        
      const response = await axiosClient.get<BrowseDto>(
        `${uri}api/Employee/${location}${requestedPageQuery}${limitQuery}${searchQuery}${filterQuery}${unassignedQuery}${expirationQuery}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching employees', error);
      throw error;
    }
  };
  

const getFilterQuery=(filters:SearchFilters) => {
    if(filters.client) return `&clientFilter=${filters.client}`;
    if(filters.jobTitle) return `&jobTitleFilter=${filters.jobTitle}`;
    if(filters.manager) return `&managerFilter=${filters.manager}`;
    if(filters.skill) return `&skillFilter=${filters.skill}`;
    if(filters.teamId) return `&teamFilter=${filters.teamId}`;
    else return "";
}

export const getPhoto = async (userId: string): Promise<string> => {
    try {        
        const response = await axiosClient.get<string>(`${uri}api/Employee/${userId}/photo`);        
        return response.data;
    } catch (error) {
        console.error('Error fetching employee details', error);
        throw error;
    }
};

export const getEmployeeDetails = async (userId: string): Promise<EmployeeTotalDto> => {
    try {        
        const response = await axiosClient.get<EmployeeTotalDto>(`${uri}api/Employee/EmployeeTotal/${userId}`);        
        return response.data;
    } catch (error) {
        console.error('Error fetching employee details', error);
        throw error;
    }
};

export const updateEmployeeDetails = async (userDetails: EmployeeDetailsUpdateDto): Promise<boolean> => {
    try {        
        const response = await axiosClient.put<boolean>(`${uri}api/EmployeeDetailsUpdate`, userDetails);     
        return response.data;
    } catch (error) {
        console.error('Error updating details', error);
        throw error;
    }
};

export const getAllEmployeesByManagerId = async (id: string): Promise<BrowseEmployeeDto[]> => {
    try {        
        const response = await axiosClient.get<BrowseEmployeeDto[]>(`${uri}api/Employee/GetAllEmployeesByManager`, {
            params: { id: id }
        });        
        return response.data;
    }
    catch (error) {
        console.error('Error fetching employees by manager display name', error);
        throw error;
    }
};

export const getBrowseEmployeeAssignment = async (location: string, searchString?: string): Promise<BrowseEmployeeDto[]> => {
    
    const urlSearchValue = searchString !== undefined ? `?searchValue=${searchString}` : "";
    try {         
        const response = await axiosClient.get<BrowseEmployeeDto[]>(`${uri}api/Employee/GetEmployeesForAssignmentSearch/${location}${urlSearchValue}`);        
        return response.data;
    } catch (error) {
        console.error('Error fetching employees', error);
        throw error;
    }
};

export const getEmployeesByJobTitle = async (location: string, jobTitle: string) : Promise<BrowseEmployeeDto[]> => {
    try {
    const encodedJobTitle = encodeURIComponent(jobTitle)
    const response = await axiosClient.get<BrowseEmployeeDto[]>(`${uri}api/Employee/GetEmployeesByJobTitle/${encodedJobTitle}?location=${location}`);
    return response.data;
    }
    catch (error) {
        console.error('Error fetching employees', error);
        throw error;
    }    
};

export const getEmployeesBySkillId = async (location: string, skillId: number) : Promise<BrowseEmployeeDto[]> => {
    try {
    const response = await axiosClient.get<BrowseEmployeeDto[]>(`${uri}api/Employee/skillId/${skillId}?officeLocation=${location}`);
    return response.data;
    }
    catch (error) {
        console.error('Error fetching employees', error);
        throw error;
    }    
};

export const getActivationStatusByOfficeLocation = async (officeLocation: string) : Promise<EmployeeActivationDto[]> => {
    try {        
    const response = await axiosClient.get<EmployeeActivationDto[]>(`${uri}api/EmployeeActivationByOfficeLocation?officeLocation=${officeLocation}`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching employees', error);
        throw error;
    }    
};

export const updateEmployeeActivation = async (employee: EmployeeActivationDto) : Promise<string> => {
    try {        
    const response = await axiosClient.put<string>(`${uri}api/EmployeeActivation`, employee);        
    return response.data;
    }
    catch (error) {
        console.error('Error updating status', error);
        throw error;
    }    
};

export const getMissingInfo = async (userId: string) : Promise<string[]> => {
    try {               
    const response = await axiosClient.get<string[]>(`${uri}api/Employee/missingInfo/${userId}`);            
    return response.data;
    }
    catch (error) {
        console.error('Error fetching missing info', error);
        throw error;
    }    
};

export const isMissingInfoDisplayed = async (userId: string) : Promise<boolean> => {
    try {               
    const response = await axiosClient.get<boolean>(`${uri}api/Employee/missingInfo/display/${userId}`);          
    return response.data;
    }
    catch (error) {
        console.error('Error fetching response', error);
        throw error;
    }    
};

export const updateMissingInfo = async (update: UpdateMissingInfoDto) : Promise<boolean> => {
    try {                        
        const response = await axiosClient.put<boolean>(`${uri}api/Employee/missingInfo/update/`, update); 
        return response.data;
    }
    catch (error) {
        console.error('Error updating missing info', error);
        throw error;
    }    
};

export const getCarousel = async (userId: string) : Promise<CarouselDto> => {
    try {               
    const response = await axiosClient.get<CarouselDto>(`${uri}api/Employee/carousel/${userId}`);          
    return response.data;
    }
    catch (error) {
        console.error('Error fetching carousel images', error);
        throw error;
    }    
};

export const dontShowTutorial = async (update: UpdateShowTutorial) : Promise<boolean> => {
    try {                      
        const response = await axiosClient.put<boolean>(`${uri}api/Employee/carousel/${update.userId}`, update); 
        return response.data;
    }
    catch (error) {
        console.error('Error updating latest login', error);
        throw error;
    }    
};

export const showTutorial = async (userId: string) : Promise<boolean> => {
    try {         
        const response = await axiosClient.get<boolean>(`${uri}api/Employee/carousel/showTutorial/${userId}`); 
        return response.data;
    }
    catch (error) {
        console.error('Error fetching Show Tutorial info', error);
        throw error;
    }    
};

export const setAssignmentEligibility = async (user: EmployeeSetAssignmentEligibiltyDto): Promise<boolean> => {
    try {                 
        const response = await axiosClient.post<boolean>(`${uri}api/Employee/toggleAssignmentToNotViable`, user);
        return response.data;
    }
    catch (error) {
        console.error('Error setting AssignmentEligibilty', error);
        return false;
    }
}