import { Container, Row, Col, Image } from "react-bootstrap";

const SignInPageBackground: React.FC = () => {
  const logoPath: string = "./Consid-logo-liggande-vit.png";
  return (
    <Container className="d-flex justify-content-center">
      <Row className="d-flex justify-content-center mt-3">
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Image className="img-fluid" src={logoPath} alt="Logo" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignInPageBackground;
