import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ProfileSubHeader from "../../atoms/ProfileSubHeader";
import { EmployeeAssignmentDto } from "../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import ProfileAssignments from "./atoms/ProfileAssignments";

const ProfilePreviousAssignments = (props: {
  previousAssignments?: EmployeeAssignmentDto[];
}): React.JSX.Element => {
  const [
    isThereMoreThanTwoPreviousAssignments,
    setIsThereMoreThanTwoPreviousAssignments,
  ] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  useEffect(() => {
    if (props.previousAssignments && props.previousAssignments.length > 2) {
      setIsThereMoreThanTwoPreviousAssignments(true);
    }
  }, [props.previousAssignments]);

  return (
    <>
      <ProfileSubHeader headerValue="Tidigare uppdrag" />
      <Row className="mt-3 mb-4 gx-0">
        {isThereMoreThanTwoPreviousAssignments ? (
          <>
            <Col
              xs={12}
              className="d-flex justify-content-center w-100 mx-auto"
            >
              <Form.Switch
                checked={showDetails}
                label="Visa detaljer"
                onChange={(e) => setShowDetails(e.target.checked)}
              />
            </Col>
            <ProfileAssignments
              showDetails={showDetails}
              assignments={props.previousAssignments}
              customStarColor="#cd7f32"
            />
          </>
        ) : (
          <ProfileAssignments
            showDetails={true}
            assignments={props.previousAssignments}
            customStarColor="#cd7f32"
          />
        )}
      </Row>
    </>
  );
};

export default ProfilePreviousAssignments;
