import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./OrganisationTreeDesktop.css";
import { useQuery } from "@tanstack/react-query";
import { OfficeLocationDto } from "../../../models/Dto/OfficeLocationDto";
import EmployeeTree from "./EmployeeTree";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { getOfficeLocation } from "../../../services/OfficeLocationService";
import Error from "../../atoms/ReactQueryError/ErrorHandler";
import Loading from "../../atoms/ReactQueryLoading/Loading";

const OrganisationTree: React.FC = () => {
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const includePhoto = true;
  const { data, error, isLoading } = useQuery<OfficeLocationDto, Error>({
    queryFn: () => getOfficeLocation(!officeLocation ? "" : officeLocation),
    queryKey: ["officeEmployees", officeLocation],
  });

  if (isLoading) {
    return <Loading loadingMessage="Laddar organisationsschema..." />;
  }

  if (error) {
    return <Error queryError={error.message} />;
  }

  return (
    <Row>
      <Col>
        <Container fluid className="my-3">
          <Row className="consid-stad text-center">
            <Col
              xl={{ span: 7, order: "last" }}
              lg={{ span: 6, order: "last" }}
              md={{ span: 5, order: "last" }}
              className="consid-stad d-flex flex-column text-end pe-5 mb-3"
            >
              <h1>Consid {officeLocation}</h1>
              <h1>Organisationsträd</h1>
            </Col>
            <Col
              xl={{ span: 5, order: "first" }}
              lg={{ span: 6, order: "first" }}
              md={{ span: 7, order: "first" }}
              className="ps-5"
            >
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {data && (
                    <EmployeeTree
                      employees={data.employees}
                      includePhoto={includePhoto}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default OrganisationTree;
