import React from "react";
import { Row } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { EmployeeAssignmentDto } from "../../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import styles  from "./AssignmentTeams.module.css";

interface AssignmentTeamProps {
  assignment: EmployeeAssignmentDto;
}

const AssignmentTeams = (props: AssignmentTeamProps): React.JSX.Element => {
  const navigate = useNavigate();
  const handleTeamClick = (teamId: number, navigate: NavigateFunction) => {
    return (event: React.MouseEvent) => {
      if (teamId) {
        const filter: SearchFilters = { teamId: teamId.toString() };
        sessionStorage.setItem("filters", JSON.stringify(filter));
        sessionStorage.setItem("searchType", "5");
        sessionStorage.setItem("searchValue", "");
        event.preventDefault();
        navigate("/browse");
      }
    };
  };

  return props.assignment.teams && props.assignment.teams.length > 0 ? (
    <Row className="mb-1">
      <label className="col-6 text-end form-label">Team: </label>

      <div className="col-6 hstack flex-wrap gx-0">
        {props.assignment.teams.map((team, teamIndex) => (
          <React.Fragment key={team.id}>
            <span
              className={styles["team-link"]}
              onClick={(e) => handleTeamClick(team.id!, navigate)(e)}
            >
              {team.teamName}
            </span>
            {teamIndex < props.assignment.teams!.length - 1 ? ", " : ""}
          </React.Fragment>
        ))}
      </div>
    </Row>
  ) : (
    <></>
  );
}

export default AssignmentTeams;