import { Row } from "react-bootstrap";
import ProfileSubHeader from "../../atoms/ProfileSubHeader";
import { EmployeeAssignmentDto } from "../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import ProfileAssignments from "./atoms/ProfileAssignments";

const ProfileCurrentAssignments = (props: {
  currentAssignments: EmployeeAssignmentDto[];
}): React.JSX.Element => (
  <>
    <ProfileSubHeader headerValue="Nuvarande uppdrag" />
    <Row className="mt-3 gx-0">
      {props.currentAssignments && props.currentAssignments!.length > 0 ? (
        <ProfileAssignments
          assignments={props.currentAssignments}
          showDetails={true}
        />
      ) : (
        <h6 className="text-center">* Saknar uppdrag *</h6>
      )}
    </Row>
  </>
);

export default ProfileCurrentAssignments;
