import { Card, CloseButton, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface OfficeLocationSelecterProps {
  selectedOfficeLocation: string | undefined;
  setSelectedOfficeLocation: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  userOfficeLocation: string | undefined;
  responseText: string | undefined;
  setResponseText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const OfficeLocationSelecter: React.FC<OfficeLocationSelecterProps> = (
  props
) => {
  const officeLocations: string[] | undefined = useSelector(
    (state: RootState) => state.user.officeLocations
  );

  const handleSearchAreaChange = (officeLocation: string) => {
    props.setSelectedOfficeLocation(officeLocation);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Form.Label>
          <h3 style={{ color: "#701131" }}>Välj kontor</h3>
        </Form.Label>
        <Form.Select
          className="wrapper fs-6"
          value={props.selectedOfficeLocation}
          onChange={(e) => handleSearchAreaChange(e.target.value)}
        >
          <option value={props.userOfficeLocation}>
            {props.userOfficeLocation}
          </option>

          <option disabled>-----------</option>
          {officeLocations?.map(
            (office) =>
              office !== props.userOfficeLocation && (
                <option key={office} value={office}>
                  {office}
                </option>
              )
          )}
        </Form.Select>
      </Row>
      <Row>
        <Col>
          {props.responseText && (
            <Card style={{ border: "1px solid #701131" }} className="mb-3">
              <Card.Body className="hstack" style={{ color: "#701131" }}>
                <h5>{props.responseText}</h5>
                <CloseButton
                  onClick={() => props.setResponseText(undefined)}
                  className="p-1"
                />
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OfficeLocationSelecter;
