import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { StarFilled } from "@ant-design/icons";
import { SkillDto } from "../../organisms/UserProfile/models/SkillDto";

interface ProfileSkillListItemProps {
  skill: SkillDto;
  skillName: string;
}

const renderTooltip = (props: ProfileSkillListItemProps) => (
  <Tooltip id="button-tooltip">
    {props.skillName}
  </Tooltip>
);

const ProfileSkillListItem: React.FC<ProfileSkillListItemProps> = (props) => {
  return (
    <Row className="mb-2">
      <Col className="d-flex justify-content-end text-truncate ms-2">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(props)}
        >
          <p className="my-0 text-end text-truncate">{props.skillName}</p>
        </OverlayTrigger>
      </Col>
      <Col className="d-flex justify-content-start align-items-center">
        {Array.from({ length: props.skill.skillLevel }).map((item, index) => (
          <StarFilled key={`user_skill_star${index + 1}`} />
        ))}
      </Col>
    </Row>
  );
};

export default ProfileSkillListItem;
