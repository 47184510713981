import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./ButtonPrimary.css";

interface ButtonPrimaryProps {
  text: string;
  isLarge?: boolean;
  handleClick: () => void;
  className?: string;
  disabled?: boolean;
  tooltipText?: string[];
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = (props) => {
  const tooltip = (
    <Tooltip className={`tooltip-bg-crimson`}>
      {props.tooltipText &&
        props.tooltipText.map((errorText, index) => (
          <li key={`li_error_${index}`}>{errorText}</li>
        ))}
    </Tooltip>
  );
  if (props.tooltipText && props.disabled)
    return (
      <OverlayTrigger overlay={tooltip} placement="top">
        <span className="w-auto">
          <Button
            onClick={props.handleClick}
            size={!props.isLarge ? "sm" : "lg"}
            className={`consid-primary-btn px-4 py-2 ${props.className}`}
            disabled={props.disabled}
          >
            {props.text}
          </Button>
        </span>
      </OverlayTrigger>
    );
  else
    return (
      <span className="w-auto">
        <Button
          onClick={props.handleClick}
          size={!props.isLarge ? "sm" : "lg"}
          className={`consid-primary-btn px-4 py-2 ${props.className}`}
          disabled={props.disabled}
        >
          {props.text}
        </Button>
      </span>
    );
};

export default ButtonPrimary;
