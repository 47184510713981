import { SetStateAction, useEffect, useState } from "react";
import { BrowseEmployeeDto } from "../../../../models/Dto/EmployeeSummaryDto";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAssignmentsByOfficeLocation } from "../../../../services/AssignmentService";
import { Form } from "react-bootstrap";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../../models/Enums/SearchType";

interface AssignmentTemplateProps {
  activeSearchArea: string;
  searchType: SearchType;
  selectedAssignment: string;
  setSelectedAssignment: React.Dispatch<SetStateAction<string>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
}

const AssignmentTemplate: React.FC<AssignmentTemplateProps> = (props) => {
  const queryClient = useQueryClient();
  const noAssignment: string = "Saknar uppdrag";

  const { data, isLoading } = useQuery<string[]>({
    queryKey: ["assignments", props.activeSearchArea],
    queryFn: () => getAssignmentsByOfficeLocation(props.activeSearchArea),
  });

  const handleChange = async (assignment: string) => {
    props.setSelectedAssignment(assignment);

    const filter: SearchFilters = { client: assignment };
    sessionStorage.setItem("filters", JSON.stringify(filter));

    queryClient.invalidateQueries({ queryKey: ["employees"] });
  };

  useEffect(() => {
    if (props.searchType === SearchType.AssignmentSearch) {
      if (data && !data.find((x) => x === props.selectedAssignment)) {
        props.setSelectedAssignment("");
      }
    }
    queryClient.invalidateQueries({ queryKey: ["employees"] });
  }, [props.activeSearchArea]);

  if (isLoading) return <p>Laddar uppdragsgivare...</p>;

  return (
    <Form.Select
      value={
        data?.find((x) => x === props.selectedAssignment)
          ? props.selectedAssignment
          : "aaa"
      }
      onChange={(e) => handleChange(e.target.value)}
      className="wrapper"
    >
      <option value={""} disabled={props.selectedAssignment !== ""}>
        Välj en uppdragsgivare
      </option>
      {data && data.length !== 0 && data.includes(noAssignment) && (
        <option disabled>---------------------</option>
      )}
      {data && data.includes(noAssignment) && (
        <option key="lediga_konsulter" value={noAssignment}>
          Lediga konsulter
        </option>
      )}
      {data && data.length !== 0 && (
        <>
          {data.length !== 1 || data[0] !== noAssignment ? (
            <option disabled>---------------------</option>
          ) : null}
        </>
      )}
      {data &&
        data.map(
          (assignment) =>
            assignment !== noAssignment && (
              <option key={`skill_${assignment}`} value={assignment}>
                {assignment}
              </option>
            )
        )}
    </Form.Select>
  );
};

export default AssignmentTemplate;
