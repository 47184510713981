import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getSkillsWithCount } from "../../../../services/SkillService";
import { Form } from "react-bootstrap";
import { SetStateAction, useEffect } from "react";
import { BrowseEmployeeDto } from "../../../../models/Dto/EmployeeSummaryDto";
import { SkillCountDto } from "../../../../models/Dto/SkillCountDto";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../../models/Enums/SearchType";

interface SkillTemplateProps {
  activeSearchArea: string;
  selectedSkillId: number;
  setSelectedSkillId: React.Dispatch<SetStateAction<number>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
  searchType: SearchType;
}

const SkillTemplate: React.FC<SkillTemplateProps> = (props) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery<SkillCountDto[]>({
    queryKey: ["skillsWithCount", props.activeSearchArea],
    queryFn: () => getSkillsWithCount(props.activeSearchArea),
  });

  const handleChange = (id: number) => {
    props.setSelectedSkillId(id);

    if (id === 0) return;
    const filter: SearchFilters = { skill: id.toString() };
    sessionStorage.setItem("filters", JSON.stringify(filter));

    queryClient.invalidateQueries({ queryKey: ["employees"] });
  };

  useEffect(() => {
    if (props.searchType === SearchType.SkillSearch)
      if (
        (data && !data.find((x) => x.skill.id === props.selectedSkillId)) ||
        data?.length === 0
      ) {
        props.setSelectedSkillId(0);
        const filter: SearchFilters = {};
        sessionStorage.setItem("filters", JSON.stringify(filter));
      }
    queryClient.invalidateQueries({ queryKey: ["employees"] });
  }, [props, queryClient, data]);

  if (isLoading) return <p>Laddar kompetenser...</p>;
  return (
    <Form.Select
      value={props.selectedSkillId}
      onChange={(e) => handleChange(+e.target.value)}
      className="wrapper"
    >
      <option value={0} disabled={props.selectedSkillId === 0}>
        Välj en kompetens
      </option>
      {data && data?.length > 0 && <option disabled>-----------------</option>}
      {data &&
        data.map((item) => (
          <option key={`skill_${item.skill.name}`} value={item.skill.id}>
            {item.skill.name}
          </option>
        ))}
    </Form.Select>
  );
};

export default SkillTemplate;
