import { useQueryClient } from "@tanstack/react-query";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./ProfileEmploymentReportsTo.css";

interface ProfileEmploymentReportsToProps {
  managerId?: string;
  managerDisplayName?: string;
}

const ProfileEmploymentReportsTo: React.FC<ProfileEmploymentReportsToProps> = (
  props
) => {
  const queryClient = useQueryClient();
  const redirect = useNavigate();
  const handleClick = () => {
    if (!props.managerId) return;
    queryClient.invalidateQueries({ queryKey: ["employeedetails"] });
    redirect(`/userprofile/${props.managerId}`);
  };

  return (
    <Row>
      <Col className="d-flex justify-content-end">
        <label>Närmsta chef: </label>
      </Col>
      <Col className="d-flex justify-content-start gx-0">
        <p className="manager-link my-0" onClick={handleClick}>
          {props.managerDisplayName}
        </p>
      </Col>
    </Row>
  );
};

export default ProfileEmploymentReportsTo;
