import { SkillFromDbDto } from "../models/Dto/SkillFromDbDto";
import { StoryboardSkillAdd } from "../models/Dto/StoryBoardSkillAdd";
import { SkillCountDto } from "../models/Dto/SkillCountDto";
import { axiosClient } from "../utilities/axiosClient";
import { AdminMessageDto } from "../models/Dto/AdminMessageDto";

const uri: string = process.env.REACT_APP_BASE_URI!;

export const getSkillsWithCount = async (officeLocation: string): Promise<SkillCountDto[]> => {
    try {              
        const response = await axiosClient.get<SkillCountDto[]>(`${uri}api/Skill/SkillCount?officeLocation=${officeLocation}`);                
        return response.data;
    }
    catch (error) {
        console.error('Error fetching skill count', error);
        throw error;
    }
}

export const getSkillName = async (id: number): Promise<SkillFromDbDto> => {
    try {        
        const response = await axiosClient.get<SkillFromDbDto>(`${uri}api/Skill/Skills/${id}`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching skill', error);
        throw error;
    }
}

export const putSkill = async (storyboardSkillUpdate: StoryboardSkillAdd): Promise<boolean> => {
    try {                       
        const response = await axiosClient.put<boolean>(`${uri}api/Skill/Skills`, storyboardSkillUpdate);
        return response.data;
    }
    catch (error) {
        console.error('Error updating skill', error);
        throw error;
    }
}

export const deleteSkill = async (storyboardSkillUpdate: StoryboardSkillAdd): Promise<boolean> => {
    try {                       
        const response = await axiosClient.delete<boolean>(`${uri}api/Skill/Skills`, { data: storyboardSkillUpdate });
        return response.data;
    }
    catch (error) {
        console.error('Error deleting skill', error);
        throw error;
    }
}

export const getAllSkills = async (): Promise<SkillFromDbDto[]> => {
    try {
        const response = await axiosClient.get<SkillFromDbDto[]>(`${uri}api/Skill/Skills`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching Skills', error);
        throw error;
    }
}

export const postNewSkill = async (storyboardSkillAdd: StoryboardSkillAdd): Promise<boolean> => {
    try {                      
        const response = await axiosClient.post<boolean>(`${uri}api/Skill/Skills`, storyboardSkillAdd);
        return response.data;
    }
    catch (error) {
        console.error('Error posting new skill', error);
        throw error;
    }
}

export const doesSkillExist = async (skillName: string): Promise<boolean> => {
    const encondedSkillname = encodeURIComponent(skillName);
    try {        
        const response = await axiosClient.get<boolean>(`${uri}api/Skill/Skills/skillExist/${encondedSkillname}`);        
        return response.data;
    }
    catch (error) {
        console.error('Error fetching Skills', error);
        throw error;
    }
}

export const skillMessageRequest = async (request: AdminMessageDto): Promise<string> => {
    try {                      
        const response = await axiosClient.post<string>(`${uri}api/Skill/`, request);
        return response.data;
    }
    catch (error) {
        console.error('Error posting new skillRequest', error);
        throw error;
    }
}

export const removeSkillMessage = async (skillName: string): Promise<number> => {
    try {                      
        const response = await axiosClient.delete<number>(`${uri}api/Skill/${skillName}`);
        return response.data;
    }
    catch (error) {
        console.error('Error posting new skillRequest', error);
        throw error;
    }
}

export const getAnAdminsMessages = async (): Promise<AdminMessageDto[]> => {
    try {         
        const response = await axiosClient.get<AdminMessageDto[]>(`${uri}api/Skill/GetAnAdminsMessages`);
        return response.data;
    }
    catch (error) {
        console.error('Error getting messages', error);
        throw error;
    }
}
