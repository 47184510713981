import {Col, Row } from "react-bootstrap";
import Portrait from "../../../atoms/DummyPhotos/Portrait";

interface NoProfilePhotoProps {}

const NoProfilePhoto: React.FC<NoProfilePhotoProps> = () => {
    return(
        <Row className="mb-3">
            <Col className="d-flex justify-content-center">
                <Portrait url="/nophoto.svg" />
            </Col>
        </Row>
    )
}

export default NoProfilePhoto