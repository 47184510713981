import { Container } from "react-bootstrap";
import HomeStoryboard from "../../molecules/HomeStoryboard/HomeStoryboard";
import { useQuery } from "@tanstack/react-query";
import { getAllOfficeLocations } from "../../../services/OfficeLocationService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updateIsMyProfileActive,
  updateOfficeLocations,
} from "../../../redux/slices/userSlice";

const HomePage: React.FC = () => {
  const dispatch = useDispatch();

  const { data } = useQuery<string[]>({
    queryKey: ["officeLocations"],
    queryFn: () => getAllOfficeLocations(),
  });

  useEffect(() => {
    dispatch(updateIsMyProfileActive(false));
    if (!data) return;
    dispatch(updateOfficeLocations(data));
  }, [data, dispatch]);

  return (
    <Container className="responsive-page-container" fluid>
      <HomeStoryboard />
    </Container>
  );
};

export default HomePage;
