import { useCallback, useEffect, useState } from "react";
import { EmployeeAssignmentDto } from "../interfaces/EmployeeAssignmentDto";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import ModalError from "./ModalError";
import ModalNoClient from "./ModalNoClient";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useQueryClient } from "@tanstack/react-query";
import {
  doesClientExist,
  haveAssignmentAddNoClient,
  noAssignmentsAddNoClient,
  removeNoClient,
} from "../../../../services/AssignmentService";
import { setAssignmentEligibility } from "../../../../services/EmployeeService";
import { EmployeeSetAssignmentEligibiltyDto } from "../../../../models/Dto/EmployeeSetAssignmentEligibilityDto";
import "./MyProfileCurrentAssignmentForm.css";
import { GetTeamsDto } from "../../../../models/Dto/GetTeamsDto";

interface CurrentAssignmentProps {
  addNew: boolean;
  allCurrentAssignments: string[];
  inputValue: string;
  selectedItem: string;
  showTeamErrorModal: boolean;
  userAssignments: EmployeeAssignmentDto[] | undefined;
  isChecked: boolean;
  firstTimeAssignment: boolean;
  handleSelectChangeClient: (selectedClients: EmployeeAssignmentDto[]) => void;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  setShowAssignmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  setAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setShowTeamErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFirstTimeAssignment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAssignmentExisting: React.Dispatch<React.SetStateAction<boolean>>;
  isAssignmentExisting: boolean;
  setSelectedOffice: React.Dispatch<React.SetStateAction<string>>;
  selectedOffice: string;
  setIsNoClientChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isNoClientChecked: boolean;
  isAnAssignmentActive: boolean;
  setIsAnAssignmentActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyProfileCurrentAssignmentForm: React.FC<CurrentAssignmentProps> = (
  props
) => {
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showNoClientModal, setShowNoClientModal] = useState<boolean>(false);
  const [noClientConfirmed, setNoClientConfirmed] = useState<boolean>(false);
  const [noClientType, setNoClientType] = useState<boolean>();
  const [errorText, setErrorText] = useState<string>("");
  const [showNoAssignmentModalInfo, setShowNoAssigmentModalInfo] =
    useState<boolean>(false);
  const [
    isThereAnOngoingOrUpcomingAssignment,
    setIsThereAnOngoingOrUpcomingAssignment,
  ] = useState<boolean>(false);
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const officeLocations: string[] = useSelector(
    (state: RootState) => state.user.officeLocations
  );
  const queryClient = useQueryClient();
  const noAssignmentModalText =
    "Du har markerat att det i din roll inte är aktuellt med uppdrag. Nuvarande och kommande uppdrag kommer därmed inte längre visas på din profilsida.";
  const newAssignment: string = "Annat uppdrag";
  const noAssignment: string = "Saknar uppdrag";
  const setDefault: string = "Ta bort saknar uppdrag";
  const [showTooLongClientToolTip, setShowTooLongClientTooltip] =
    useState<boolean>(false);
  const tooltipTooLongText =
    "Du har nått gränsen för hur lång en uppdragstitel får vara";
  const { setSelectedItem } = props;

  const onChangeHandler = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === newAssignment) {
      props.setFirstTimeAssignment(true);
      props.setSelectedItem(e.target.value);
      props.setInputValue("");
      props.setAddNew(true);
      props.setIsNoClientChecked(false);

      return;
    }
    if (
      // Fångar upp när en dublett läggs till
      props.userAssignments &&
      props.userAssignments.some(
        (assignment) => assignment.client === e.target.value
      )
    ) {
      setErrorText("Du försöker lägga till ett värde som redan förekommer.");
      setShowErrorModal(true);
      return;
    }
    // Ifall "Saknar uppdrag" är checked men uncheckas
    if (e.target.value === setDefault) {
      const response = await removeNoClient(userId);
      if (response) {
        props.setSelectedItem("");
        props.setIsNoClientChecked(false);
        queryClient.invalidateQueries({ queryKey: ["mydetails"] });
      }
      return;
    }
    if (
      // Alla cases när "Saknar uppdrag har valts"
      e.target.value === noAssignment
    ) {
      props.setAddNew(false);
      noClientHandler(e);
      props.setIsNoClientChecked(true);
      props.setSelectedItem("");
      return;
    } else {
      props.setAddNew(false);
      props.setSelectedItem(e.target.value);
      props.setIsNoClientChecked(false);
      return;
    }
  };

  const noClientHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== noAssignment) return;
    else {
      if (
        // Kontrollerar ifall användaren har aktiva uppdrag
        props.userAssignments?.some(
          (x) =>
            new Date(x.assignmentStartDate!) < new Date() &&
            (x.assignmentEndDate === null ||
              new Date(x.assignmentEndDate ? x.assignmentEndDate : new Date()) >
                new Date())
        )
      ) {
        setNoClientType(true);
        setShowNoClientModal(true);
      } else {
        setNoClientType(false);
        setShowNoClientModal(true);
      }
    }
  };

  const updateNoClient = useCallback(async () => {
    if (noClientType) {
      // Hanterar utfall där användaren har aktiva uppdrag
      const response = await haveAssignmentAddNoClient(userId);
      if (response) {
        setSelectedItem(noAssignment);
        queryClient.invalidateQueries({ queryKey: ["mydetails"] });
        queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
        setNoClientConfirmed(false);
      }
    } else {
      // Hanterar utfall där användaren inte har några aktiva uppdrag
      const response = await noAssignmentsAddNoClient(userId);
      if (response) {
        setSelectedItem(noAssignment);
        queryClient.invalidateQueries({ queryKey: ["mydetails"] });
        queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
        setNoClientConfirmed(false);
      }
    }
  }, [noClientType, queryClient, userId, setSelectedItem]);

  const existingClientHandler = async (): Promise<boolean> => {
    const dto: GetTeamsDto = {
      assignment: props.selectedItem,
      officeLocation: props.selectedOffice,
    };
    const response = await doesClientExist(dto);
    if (response) {
      setErrorText(
        `Uppdraget "${props.selectedItem}" existerar redan i databasen. Välj "${props.selectedItem}" från listan av uppdrag istället, eller fyll i ett annat uppdragsnamn, för att skapa upp ett nytt uppdrag.`
      );
      setShowErrorModal(true);
    } else {
      props.setShowAssignmentModal(true);
      props.setSelectedItem(props.inputValue);
      props.setFirstTimeAssignment(true);
    }
    return response;
  };

  useEffect(() => {
    if (props.inputValue.length !== 50 && !showTooLongClientToolTip) return;
    if (props.inputValue.length === 50 && !showTooLongClientToolTip)
      setShowTooLongClientTooltip(true);
    if (props.inputValue.length !== 50 && showTooLongClientToolTip)
      setShowTooLongClientTooltip(false);
  }, [showTooLongClientToolTip, props.inputValue]);

  useEffect(() => {
    if (!noClientConfirmed) return;
    else updateNoClient();
  }, [noClientConfirmed, updateNoClient]);

  useEffect(() => {
    const isThereAnOngoingOrUpcomingAssignmentHandler = () => {
      if (props.userAssignments) {
        const today = new Date();
        for (const assignment of props.userAssignments) {
          if (assignment.client === "Saknar uppdrag") {
            continue;
          }

          const startDate = new Date(
            assignment.assignmentStartDate ? assignment.assignmentStartDate : ""
          );
          const endDate = assignment.assignmentEndDate
            ? new Date(assignment.assignmentEndDate)
            : new Date(9999, 11, 31);

          if (startDate <= today && today <= endDate) {
            setIsThereAnOngoingOrUpcomingAssignment(true);
            return;
          }

          if (startDate > today) {
            setIsThereAnOngoingOrUpcomingAssignment(true);
            return;
          }
        }
      }

      setIsThereAnOngoingOrUpcomingAssignment(false);
    };
    isThereAnOngoingOrUpcomingAssignmentHandler();
  }, [props.userAssignments]);

  const checkBoxHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    if (checked && isThereAnOngoingOrUpcomingAssignment) {
      setErrorText(
        "Du har aktiva uppdrag eller kommande uppdrag. För att aktivera detta val, så måste du först ta bort dessa."
      );
      setShowErrorModal(true);
      return;
    } else {
      setShowErrorModal(false);
      setErrorText("");
    }

    let user: EmployeeSetAssignmentEligibiltyDto = {
      employeeId: userId,
    };

    const toggleIsEligible = async () => {
      await setAssignmentEligibility(user);
      queryClient.invalidateQueries({ queryKey: ["mydetails"] });
    };

    toggleIsEligible();
    if (checked) setShowNoAssigmentModalInfo(true);
  };

  const renderTooltip = () => (
    <Tooltip id="button-tooltip">
      Lägg till ett uppdrag, för att bocka i rutan.
    </Tooltip>
  );

  const noClientCheckBoxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    if (checked) {
      const simulatedEvent = {
        target: {
          value: noAssignment,
        },
      } as React.ChangeEvent<HTMLSelectElement>;

      onChangeHandler(simulatedEvent);
    } else {
      const simulatedRemove = {
        target: {
          value: setDefault,
        },
      } as React.ChangeEvent<HTMLSelectElement>;

      onChangeHandler(simulatedRemove);
    }
  };

  const tooltipTooLong = (
    <Tooltip id="tooltip-tooLong" className={`tooltip-bg-crimson`}>
      {tooltipTooLongText}
    </Tooltip>
  );

  const handleSelectOffice = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    props.setSelectedOffice(e.target.value);
  };

  return (
    <Row>
      <ModalError
        show={props.showTeamErrorModal}
        text={errorText}
        handleClickYes={() => {
          props.setShowTeamErrorModal(false);
          setErrorText("");
        }}
        onHide={() => props.setShowTeamErrorModal(false)}
      />

      <ModalError
        show={showErrorModal}
        text={errorText}
        handleClickYes={() => {
          setShowErrorModal(false);
          setErrorText("");
        }}
        onHide={() => setShowErrorModal(false)}
      />
      <ModalError
        show={showNoAssignmentModalInfo}
        text={noAssignmentModalText}
        handleClickYes={() => {
          setShowNoAssigmentModalInfo(false);
        }}
        onHide={() => setShowNoAssigmentModalInfo(false)}
      />
      <ModalNoClient
        show={showNoClientModal}
        text="Ändra till saknar uppdrag?"
        handleClickYes={() => setShowNoClientModal(false)}
        handleClickNo={() => setShowNoClientModal(false)}
        onHide={() => setShowNoClientModal(false)}
        setNoClientConfirmed={setNoClientConfirmed}
        userAssignments={props.userAssignments}
        setSelectedItem={props.setSelectedItem}
        setAddNew={props.setAddNew}
        isNoClientChecked={props.isNoClientChecked}
        setIsNoClientChecked={props.setIsNoClientChecked}
      />
      <h3
        className="mobile-display d-flex justify-content-center mb-4"
        style={{ color: "#701131" }}
      >
        Uppdrag
      </h3>
      <Row className="hstack">
        <Col xl={6} lg={6} md={6} sm={6} xs={12}>
          <Form.Label>Välj stad för uppdraget</Form.Label>
          <Form.Select
            className="w-100 me-3"
            value={props.selectedOffice}
            onChange={handleSelectOffice}
            disabled={props.isChecked || props.isNoClientChecked}
          >
            <option value={officeLocation}>{officeLocation}</option>
            <option disabled>-------------</option>
            {officeLocations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </Form.Select>

          <>
            <Form.Label className="mt-2">Lägg till uppdrag</Form.Label>
            <Form.Select
              disabled={props.isChecked || props.isNoClientChecked}
              value={props.selectedItem}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            >
              <option value="" disabled={true}>
                Välj en uppdragsgivare
              </option>
              <option
                value={newAssignment}
                disabled={props.addNew}
                style={{ color: "blueviolet" }}
              >
                Mitt uppdrag är ej med i listan
              </option>
              {props.allCurrentAssignments &&
                props.allCurrentAssignments.length > 0 &&
                !(
                  props.allCurrentAssignments.length === 1 &&
                  props.allCurrentAssignments[0] === "Saknar uppdrag"
                ) && <option disabled>---------------</option>}
              {props.allCurrentAssignments &&
                props.allCurrentAssignments
                  .filter(
                    (assignment) =>
                      assignment !== "" && assignment !== noAssignment
                  )
                  .sort()
                  .map((assignment) => (
                    <option key={assignment} value={assignment}>
                      {assignment}
                    </option>
                  ))}
            </Form.Select>
          </>
        </Col>
        {props.addNew && !props.isChecked && !props.isNoClientChecked && (
          <Col xs={12} className="mobile-display mt-3 hstack">
            <OverlayTrigger
              show={showTooLongClientToolTip}
              placement="top"
              overlay={tooltipTooLong}
            >
              <Form.Control
                type="text"
                value={props.inputValue}
                onChange={(e) => {
                  props.setInputValue(e.target.value);
                }}
                className="w-50"
                maxLength={50}
              />
            </OverlayTrigger>
            <ButtonSecondary
              text="Skapa nytt"
              disabled={!props.inputValue}
              className="ms-3"
              handleClick={() => {
                existingClientHandler();
              }}
            />
          </Col>
        )}
        <Col
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={5}
          className="vstack d-flex justify-content-start align-items-start mt-4 mt-sm-0 ms-2"
        >
          <Form.Group>
            <Form.Label>Uppdragsstatus</Form.Label>
            <OverlayTrigger placement="top" overlay={renderTooltip()}>
              <Form.Check
                label="Jag har uppdrag"
                checked={props.isAnAssignmentActive}
                readOnly
                disabled={props.isChecked || props.isNoClientChecked}
              />
            </OverlayTrigger>
            <Form.Check
              label="Jag saknar uppdrag"
              checked={props.isNoClientChecked}
              onChange={noClientCheckBoxHandler}
              disabled={props.isChecked}
              className="mt-3"
            />
            <Form.Check
              label="I min roll används inte uppdrag"
              checked={props.isChecked}
              onChange={checkBoxHandler}
              className="mt-3"
              disabled={props.isNoClientChecked}
            />
          </Form.Group>
        </Col>
      </Row>
      {props.addNew && !props.isChecked && !props.isNoClientChecked && (
        <Row className="desktop-display">
          <Col className="mt-3 hstack">
            <OverlayTrigger
              show={showTooLongClientToolTip}
              placement="top"
              overlay={tooltipTooLong}
            >
              <Form.Control
                type="text"
                value={props.inputValue}
                onChange={(e) => {
                  props.setInputValue(e.target.value);
                }}
                className="w-50"
                maxLength={50}
                placeholder="Skriv in uppdragets namn..."
              />
            </OverlayTrigger>
            <ButtonSecondary
              text="Skapa nytt"
              disabled={!props.inputValue}
              className="ms-3"
              handleClick={() => {
                props.setSelectedItem(props.inputValue);
              }}
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};

export default MyProfileCurrentAssignmentForm;
