import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EmployeeDto } from "../../../models/Dto/EmployeeDto";
import "./EmployeeTree.css";
import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

interface EmployeeTreeProps {
  employees?: EmployeeDto[];
  includePhoto?: boolean;
}

const EmployeeTree: React.FC<EmployeeTreeProps> = ({
  employees,
  includePhoto,
}) => {
  const [visibleSubordinates, setVisibleSubordinates] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleSubordinatesVisibility = (id: string) => {
    setVisibleSubordinates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  if (!employees || employees.length === 0) {
    return null;
  }

  return (
    <>
      {employees.map((employee) => {
        const isManager = employee.employees && employee.employees.length > 0;
        const photoColSize = includePhoto ? 2 : 0;
        const iconColSize = isManager ? 2 : 0;
        const infoColSize = includePhoto
          ? isManager
            ? 8
            : 10
          : isManager
          ? 10
          : 12;

        return (
          <React.Fragment key={`employee${employee.userId}`}>
            <Row className="employee-row shadow mt-2">
              {includePhoto && (
                <Col
                  xl={photoColSize}
                  lg={photoColSize}
                  md={photoColSize}
                  sm={photoColSize}
                  xs={photoColSize}
                >
                  {employee.photo ? (
                    employee.photo
                  ) : (
                    <h1>
                      <FontAwesomeIcon icon={faQuestion} />
                    </h1>
                  )}
                </Col>
              )}
              <Col
                xl={infoColSize}
                lg={infoColSize}
                md={infoColSize}
                sm={infoColSize}
                xs={infoColSize}
                className="vstack d-flex justify-content-center"
              >
                <h2 className="ms-2 text-start">{employee.displayName}</h2>
                <h5 className="ms-2 text-start">
                  {employee.jobTitle || "*Uppgift saknas"}
                </h5>
              </Col>
              {isManager && (
                <Col
                  xl={iconColSize}
                  lg={iconColSize}
                  md={iconColSize}
                  sm={iconColSize}
                  xs={iconColSize}
                  className="d-flex justify-content-end align-items-center"
                >
                  {visibleSubordinates[employee.id] ? (
                    <DownCircleOutlined
                      onClick={() => toggleSubordinatesVisibility(employee.id)}
                      style={{ fontSize: "25px", color: "#B5223F" }}
                      className="icon-hover"
                    />
                  ) : (
                    <RightCircleOutlined
                      onClick={() => toggleSubordinatesVisibility(employee.id)}
                      style={{ fontSize: "25px" }}
                      className="icon-hover"
                    />
                  )}
                </Col>
              )}
            </Row>
            {visibleSubordinates[employee.id] && (
              <div className={includePhoto ? "subordinate-row" : ""}>
                <EmployeeTree
                  employees={employee.employees}
                  includePhoto={includePhoto}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default EmployeeTree;
