import { UpdateEmployeeAssignmentDto } from "../components/pages/MyProfilePage/interfaces/UpdateEmployeeAssignmentDto";
import { AssignmentTeamDto } from "../models/Dto/AssignmentTeamDto";
import { EmployeeTeamDto } from "../models/Dto/EmployeeTeamDto";
import { EmployeeTeamsAssignmentDto } from "../models/Dto/EmployeeTeamsAssignmentDto";
import { ExistingTeamDto } from "../models/Dto/ExistingTeamDto";
import { GetTeamsDto } from "../models/Dto/GetTeamsDto";
import { axiosClient } from "../utilities/axiosClient";

const uri: string = process.env.REACT_APP_BASE_URI!;

export const GetAllAssignments = async (): Promise<string[]> => {
    try {     
        const response = await axiosClient.get<string[]>(`${uri}api/Assignment/GetAllAssignments`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching assignments', error);
        throw error;
    }
}

export const getAssignmentsByOfficeLocation = async (officeLocation: string): Promise<string[]> => {
    try {        
        const response = await axiosClient.get<string[]>(`${uri}api/Assignment/${officeLocation}`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching assignments', error);
        throw error;
    }
}

export const getAllTeamsByAnAssignment = async (request: GetTeamsDto): Promise<EmployeeTeamDto[]> => {
    try {
const response = await axiosClient.post<EmployeeTeamDto[]>(`${uri}api/Assignment/AllTeamsInAnAssignment`, request);
return response.data;
    }
    catch (error) {
        console.error('Error fetching teams by assignment', error);
        throw error;
    }
}

export const getAllCurrentAssignments = async (officeLocation: string): Promise<string[]> => {
    try {                    
        const response = await axiosClient.get<string[]>(`${uri}api/Assignment/AllCurrentAssignments/${officeLocation}`);      
        return response.data;
    }
    catch (error) {
        console.error('Error fetching assignments', error);
        throw error;
    }
}

export const updateEmployeeAssignment = async (updateAssignment: UpdateEmployeeAssignmentDto): Promise<boolean> => {
    try {       
        const formattedAssignment = {...updateAssignment};
        formattedAssignment.client = formattedAssignment.client?.trim()              
        const response = await axiosClient.put<boolean>(`${uri}api/Assignment/`, formattedAssignment);       
        return response.data;
    }
    catch (error) {
        console.error('Error updating assignments', error);
        throw error;
    }
}

export const deleteEmployeeAssignment = async (deleteAssignment: UpdateEmployeeAssignmentDto): Promise<boolean> => {
    try {                      
        const response = await axiosClient.delete<boolean>(`${uri}api/Assignment/`, {data: deleteAssignment});               
        return response.data;
    }
    catch (error) {
        console.error('Error deleting assignment', error);
        throw error;
    }
}

export const noAssignmentsAddNoClient = async (userId: string): Promise<boolean> => {
    try {  
        const response = await axiosClient.get<boolean>(`${uri}api/Assignment/NoAssignmentsAddNoClient/${userId}`);       
        return response.data;
    }
    catch (error) {
        console.error('Error updating assignment', error);
        throw error;
    }
}

export const haveAssignmentAddNoClient = async (userId: string): Promise<boolean> => {
    try {           
        const response = await axiosClient.get<boolean>(`${uri}api/Assignment/HaveAssignmentAddNoClient/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error updating assignment', error);
        throw error;
    }
}

export const removeNoClient = async (userId: string): Promise<boolean> => {
    try {           
        const response = await axiosClient.get<boolean>(`${uri}api/Assignment/RemoveNoClient/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error updating assignment', error);
        throw error;
    }
}

export const doesClientExist = async (dto: GetTeamsDto): Promise<boolean> => {
    try {        
        const params = new URLSearchParams({
            officeLocation: dto.officeLocation,
            assignment: dto.assignment
        });
        const response = await axiosClient.get<boolean>(`${uri}api/Assignment/DoesClientExist?${params.toString()}`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error checking client existence', error);
        throw error;
    }
};

export const doesTeamExist = async (request: ExistingTeamDto): Promise<boolean> => {
    try {   
        const inputUrl: string = `?Id=${request.id}&TeamName=${request.teamName}`;                
        const response = await axiosClient.get<boolean>(`${uri}api/Assignment/DoesTeamExist${inputUrl}`);
        return response.data; 
        }
        catch (error) {
            console.error('Error performing teamcheck', error);
            throw error;
        }
}

export const addAnAssignment = async (newAssignment: UpdateEmployeeAssignmentDto): Promise<boolean> => {
    try {   
        const formattedAssignment = {...newAssignment};
        formattedAssignment.client = formattedAssignment.client?.trim()
        const response = await axiosClient.post<boolean>(`${uri}api/Assignment/Assignment/`, formattedAssignment);
        return response.data;
        }
    catch (error) {
            console.error('Error creating assignment', error);
            throw error;
        }
}

export const getTeamById = async (teamId: number): Promise<ExistingTeamDto> => {
    try {   
        const response = await axiosClient.get<ExistingTeamDto>(`${uri}api/Assignment/Teams/${teamId}`);
        console.log(response)
        return response.data;
        }
    catch (error) {
            console.error('Error creating assignment', error);
            throw error;
        }
}

export const getTeamsWithAndWithoutEmployee = async (userId: string, assignmentId: number): Promise<EmployeeTeamsAssignmentDto> => {
    try {        
        const inputUrl: string = `api/Assignment/Assignment/GetTeamsWithAndWithoutEmployee/${userId}?assignmentId=${assignmentId}`; 
        const response = await axiosClient.get<EmployeeTeamsAssignmentDto>(`${uri}${inputUrl}`);
        
        return response.data;
    } catch (error) {
        console.error('Error fetching teams', error);
        throw error;
    }
}

export const getAllTeamsByOfficeLocation = async (officeLocation: string): Promise<AssignmentTeamDto[]> => {
    try {           
        const response = await axiosClient.get<AssignmentTeamDto[]>(`${uri}api/Assignment/Assignment/GetAllTeamsByOfficeLocation/${officeLocation}`);
        return response.data; 
        }
        catch (error) {
            console.error('Error fetching teams', error);
            throw error;
        }
}
