import React, { useEffect, useState } from "react";
import {
  Badge,
  CloseButton,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { UpdateEmployeeAssignmentDto } from "./interfaces/UpdateEmployeeAssignmentDto";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import {
  deleteEmployeeAssignment,
  doesTeamExist,
  getAllTeamsByAnAssignment,
  getTeamsWithAndWithoutEmployee,
  updateEmployeeAssignment,
} from "../../../services/AssignmentService";
import { EmployeeTeamDto } from "../../../models/Dto/EmployeeTeamDto";
import { EmployeeAssignmentDto } from "./interfaces/EmployeeAssignmentDto";
import "./EditAssignmentModal.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetTeamsDto } from "../../../models/Dto/GetTeamsDto";
import { EmployeeDetailsDto } from "../../organisms/UserProfile/models/EmployeeDetailsDto";
import { ExistingTeamDto } from "../../../models/Dto/ExistingTeamDto";
import ModalError from "./atoms/ModalError";
import { EmployeeTeamsAssignmentDto } from "../../../models/Dto/EmployeeTeamsAssignmentDto";

export interface EditAssignmentModalProps {
  assignment: EmployeeAssignmentDto;
  editShow: boolean;
  inputValue: string;
  details: EmployeeDetailsDto;
  selectedOfficeLocationForEdit: string;
  maximumNumberOfEmployeeTeamsInAnAssignment: number;
  setAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setEditShow: React.Dispatch<React.SetStateAction<boolean>>;
  onHide?: () => void;
  handleClickNo: () => void;
  handleClickYes: () => void;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  setSelectedOfficeLocationForEdit: React.Dispatch<
    React.SetStateAction<string>
  >;
}

export interface DescriptionValidator {
  isWordsOK: boolean;
  showRemainingTooltip: boolean;
}

const EditAssignmentModal: React.FC<EditAssignmentModalProps> = (props) => {

  const [startDate, setStartDate] = useState<string | undefined>(
    props.assignment.assignmentStartDate
      ? new Date(props.assignment.assignmentStartDate)
          .toLocaleDateString()
          .substring(0, 10)
      : undefined
  );
  const [endDate, setEndDate] = useState<string | undefined>(
    props.assignment.assignmentEndDate
      ? new Date(props.assignment.assignmentEndDate)
          .toLocaleDateString()
          .substring(0, 10)
      : ""
  );
  const [description, setDescription] = useState<string | undefined>(
    props.assignment.assignmentDescription
  );
  const [client, setClient] = useState<string | undefined>(
    props.assignment.client
  );
  const [teams, setTeams] = useState<EmployeeTeamDto[]>(
    props.assignment.teams || []
  );
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const descriptionError: string = `Ett ord i din uppdragsbeskrivning överstiger 30 tecken`;
  const [errorText, setErrorText] = useState<string[]>([]);
  const [remainingFocus, setRemainingFocus] = useState<boolean>(false);
  const maximumDescriptionLetterLength: number = 200;
  const maximumDescriptionLetterWarning: number = 150;
  const [showRemainingLettersToolTip, setShowRemainingLettersTooltip] =
    useState<boolean>(false);
  const [showWordsToolTip, setShowWordsTooltip] = useState<boolean>(false);
  const [tooltipRemainingText, setTooltipRemainingText] = useState<string>("");
  const [tooltipWordsText, setTooltipWordsText] = useState<string>("");
  const [tooltipBgColor, setTooltipBgColor] = useState<string>("");
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const toolTipDateErrorMessage: string = "Startdatum ej angivet";
  const toolTipNoClientErrorMessage: string = "Uppdragsgivare ej angivet";
  const [showTooLongClientToolTip, setShowTooLongClientTooltip] =
    useState<boolean>(false);
  const tooltipNewTeamText = "Du måste fylla i ett teamnamn.";
  const [errorList, setErrorList] = useState<string[]>([]);

  //TEAMS
  const [selectedTeam, setSelectedTeam] = useState<EmployeeTeamDto>();
  const [selectedTeamToRemove, setSelectedTeamToRemove] =
    useState<EmployeeTeamDto>();
  const [employeeTeamsInAnAssignment, setEmployeeTeamsInAnAssignment] =
    useState<EmployeeTeamDto[]>([]);
  const [request, setRequest] = useState<GetTeamsDto>();

  const [addNewTeam, setAddNewTeam] = useState<boolean>();
  const [selectedNewTeamOffice, setSelectedNewTeamOffice] =
    useState<string>("");
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [newTeamCreatedWhileEditing, setNewTeamCreatedWhileEditing] =
    useState<boolean>(false);
  const [
    listOfNewTeamsToAnExistingAssignment,
    setListOfNewTeamsToAnExistingAssignment,
  ] = useState<EmployeeTeamDto[]>([]);
  const [existingTeamNameError, setExistingTeamNameError] = useState<string>();
  const [showNewTeamNameErrorModal, setShowNewTeamNameErrorModal] =
    useState<boolean>(false);
  const [listOfTeamsWithEmployee, setListOfTeamsWithEmployee] = useState<
    EmployeeTeamDto[]
  >([]);
  const [listOfTeamsWithoutEmployee, setListOfTeamsWithoutEmployee] = useState<
    EmployeeTeamDto[]
  >([]);

  const { data: employeeTeamList } = useQuery<
    EmployeeTeamsAssignmentDto,
    Error
  >({
    queryKey: ["requestTeamsWithAndWithoutEmployee", props.assignment.id],
    queryFn: () => getTeamsWithAndWithoutEmployee(userId, props.assignment.id!),
    enabled: !!userId && !!props.assignment.id,
  });

  const { data: teamList } = useQuery<EmployeeTeamDto[], Error>({
    queryKey: [request],
    queryFn: () => getAllTeamsByAnAssignment(request!),
    enabled: !!request,
  });

  useEffect(() => {
    setListOfTeamsWithEmployee(
      employeeTeamList ? employeeTeamList.listOfTeamsWithEmployee : []
    );

    setListOfTeamsWithoutEmployee(
      employeeTeamList ? employeeTeamList.listOfTeamsWithoutEmployee : []
    );
  }, [employeeTeamList]);

  useEffect(() => {
    let tempErrorList = [...errorText];

    // Adding error messages
    if (
      client &&
      !startDate &&
      !tempErrorList.includes(toolTipDateErrorMessage)
    ) {
      tempErrorList.push(toolTipDateErrorMessage);
    }
    if (
      !client &&
      startDate &&
      !tempErrorList.includes(toolTipNoClientErrorMessage)
    ) {
      tempErrorList.push(toolTipNoClientErrorMessage);
    }

    // Removing error messages
    if (client && startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipDateErrorMessage
      );
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoClientErrorMessage
      );
    }
    if (!client && startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipDateErrorMessage
      );
    }
    if (client && !startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoClientErrorMessage
      );
    }

    setErrorText(tempErrorList);
  }, [startDate, client]);

  useEffect(() => {
    const response = IsDescriptionInputInvalid(description ?? "");
    setShowWordsTooltip(!response.isWordsOK);
    setShowRemainingLettersTooltip(
      response.showRemainingTooltip && remainingFocus
    );

    const validateInput = () => {
      let tempErrorList = [...errorText];
      if (!response.isWordsOK) {
        if (!tempErrorList.some((x) => x === descriptionError))
          tempErrorList.push(descriptionError);
      } else {
        tempErrorList = tempErrorList.filter((e) => e !== descriptionError);
      }

      setErrorText(tempErrorList);
      return tempErrorList.length === 0;
    };

    validateInput();

    if (!response.isWordsOK) {
      setIsInputValid(false);
      return;
    }
    setIsInputValid(true);
  }, [description, remainingFocus]);

  useEffect(() => {
    if (client && client.length !== 50 && !showTooLongClientToolTip) return;
    if (client && client.length === 50 && !showTooLongClientToolTip)
      setShowTooLongClientTooltip(true);
    if (client && client.length !== 50 && showTooLongClientToolTip)
      setShowTooLongClientTooltip(false);
  }, [client]);

  useEffect(() => {
    if (props.assignment.client && props.selectedOfficeLocationForEdit) {
      const newRequest: GetTeamsDto = {
        assignment: props.assignment.client,
        officeLocation: props.selectedOfficeLocationForEdit,
      };
      setRequest(newRequest);
    }
  }, [props.assignment.client, props.selectedOfficeLocationForEdit]);

  useEffect(() => {
    let updatedErrors = [...errorList];
    const errorIndex = updatedErrors.indexOf(tooltipNewTeamText);

    if (selectedNewTeamOffice === "" || newTeamName === "") {
      if (errorIndex === -1) {
        updatedErrors.push(tooltipNewTeamText);
      }
    } else {
      if (errorIndex !== -1) {
        updatedErrors.splice(errorIndex, 1);
      }
    }

    setErrorList(updatedErrors);
  }, [selectedNewTeamOffice, newTeamName]);

  useEffect(() => {
    const existingList = [...employeeTeamsInAnAssignment];
    const empAss = props.details.assignments?.find(
      (x) => x.id === props.assignment.id
    );

    if (empAss?.teams) {
      empAss.teams.forEach((team) => {
        if (!existingList.some((x) => x.id === team.id)) {
          existingList.push(team);
        }
      });
      setEmployeeTeamsInAnAssignment(existingList);
    }
  }, [props.details, props.assignment]);

  useEffect(() => {
    setStartDate(
      props.assignment.assignmentStartDate
        ? new Date(props.assignment.assignmentStartDate)
            .toLocaleDateString()
            .substring(0, 10)
        : undefined
    );
    setEndDate(
      props.assignment.assignmentEndDate
        ? new Date(props.assignment.assignmentEndDate)
            .toLocaleDateString()
            .substring(0, 10)
        : undefined
    );
    setDescription(props.assignment.assignmentDescription);
    setClient(props.assignment.client);
    setTeams(props.assignment.teams || []);
  }, [props.assignment]);

  const handleChangeClient = (value: string) => {
    setClient(value);
  };

  const handleChangeStartDate = (value: string) => {
    setStartDate(value);
  };

  const handleChangeEndDate = (value: string) => {
    setEndDate(value);
  };

  const handleChangeCurrentDescription = (value: string) => {
    setDescription(value);
  };

  const editAssignmentHandler = async () => {
    let tempTeamList = [...teams];

    if (
      selectedTeam &&
      !listOfNewTeamsToAnExistingAssignment.some(
        (x) => x.id === selectedTeam.id
      )
    ) {
      tempTeamList.push(selectedTeam);
    }

    if (selectedTeamToRemove) {
      tempTeamList = tempTeamList.filter(
        (x) => x.id !== selectedTeamToRemove.id
      );
    }

    listOfNewTeamsToAnExistingAssignment.forEach((team) => {
      if (!tempTeamList.some((x) => x.id === team.id)) {
        tempTeamList.push(team);
      }
    });

    let filteredTeamList: EmployeeTeamDto[] = [];
    if (tempTeamList.length < 6) {
      filteredTeamList = tempTeamList;
    }
    const updateAssignment = {
      userId: userId,
      assignmentId: props.assignment.id ? props.assignment.id : 0,
      client: client,
      assignmentStartDate: startDate ? new Date(startDate) : undefined,
      assignmentEndDate: endDate ? new Date(endDate) : undefined,
      teams: filteredTeamList,
      assignmentDescription: description,
      officeLocation: props.selectedOfficeLocationForEdit,
    };

    const response = await updateEmployeeAssignment(updateAssignment);
    if (!response) return;
    closeHandler();
    setListOfNewTeamsToAnExistingAssignment([]);
    setSelectedTeam(undefined);
    setSelectedTeamToRemove(undefined);
    queryClient.invalidateQueries({ queryKey: ["mydetails"] });
    queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
    queryClient.invalidateQueries({
      queryKey: ["requestTeamsWithAndWithoutEmployee"],
    });
  };

  const deleteEmployeeAssignmentHandler = async () => {
    const deleteAssignment: UpdateEmployeeAssignmentDto = {
      userId: userId,
      assignmentId: props.assignment.id ? props.assignment.id : 0,
    };
    const response = await deleteEmployeeAssignment(deleteAssignment);
    if (!response) return;
    queryClient.invalidateQueries({ queryKey: ["mydetails"] });
    queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
    closeHandler();
  };

  const closeHandler = () => {
    props.handleClickYes();
    props.setEditShow(false);
    handleChangeStartDate("");
    handleChangeCurrentDescription("");
    handleChangeEndDate("");
    handleChangeClient("");
  };

  const dateChecker = () => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        return true;
      } else return false;
    } else return false;
  };

  const tooltipTextHandler = (letters: number) => {
    let text: string;
    let lettersLeft = maximumDescriptionLetterLength - letters;
    text = `${lettersLeft} tecken kvar...`;
    return text;
  };

  const isWordLengthOk = (value: string): boolean => {
    const words = value.split(/\s+/);
    const maxWordLength = 30;
    if (words.some((word) => word.length > maxWordLength)) {
      setTooltipWordsText(
        `Det är inte tillåtet att ange ord över ${maxWordLength} tecken.`
      );
      setTooltipBgColor("crimson");
      return false;
    } else {
      setTooltipWordsText("");
      return true;
    }
  };

  const isDescriptionTotalLettersOk = (value: string): boolean => {
    value = value.trimStart();
    if (value.length <= maximumDescriptionLetterWarning) {
      return true;
    }
    if (
      value.length > maximumDescriptionLetterWarning &&
      value.length < maximumDescriptionLetterLength
    ) {
      setTooltipBgColor("darkorange");
      setTooltipRemainingText(tooltipTextHandler(value.length));
      return false;
    }
    if (value.length === maximumDescriptionLetterLength) {
      setTooltipBgColor("crimson");
      setTooltipRemainingText("Max antal tecken nådd!");
      return false;
    }
    return true;
  };

  const tooltipRemaining = (
    <Tooltip id="tooltip-remaining" className={`tooltip-bg-${tooltipBgColor}`}>
      {tooltipRemainingText}
    </Tooltip>
  );

  const tooltipWords = (
    <Tooltip id="tooltip-words" className={`tooltip-bg-crimson`}>
      {tooltipWordsText}
    </Tooltip>
  );

  const IsDescriptionInputInvalid = (value: string): DescriptionValidator => {
    const isWordsOk = isWordLengthOk(value);
    const showTooltip = !isDescriptionTotalLettersOk(value);
    const response: DescriptionValidator = {
      isWordsOK: isWordsOk,
      showRemainingTooltip: showTooltip,
    };
    return response;
  };

  const handleFocusDescription = () => {
    setRemainingFocus(true);
  };

  const handleBlurDescription = () => {
    setRemainingFocus(false);
  };

  const teamWithdrawHandler = (values: string) => {
    const parts = values.split("¤");
    const id: number = parseInt(parts[0], 10);
    const teamName: string = parts[1];
    const team: EmployeeTeamDto = {
      id: id,
      teamName: teamName,
    };
    setSelectedTeamToRemove(team);
    queryClient.invalidateQueries({ queryKey: ["mydetails"] });
  };

  const newTeamHandler = async () => {
    const teamRequest: ExistingTeamDto = {
      id: props.assignment.id ?? 0,
      teamName: newTeamName,
    };
    if (
      listOfNewTeamsToAnExistingAssignment.some(
        (x) =>
          x.teamName.toLocaleLowerCase() === newTeamName.toLocaleLowerCase()
      )
    ) {
      props.setEditShow(false);
      setExistingTeamNameError("Du har redan skapat ett team med detta namn.");
      setShowNewTeamNameErrorModal(true);
      return;
    }
    if (props.assignment.teams?.length) {
      if (
        listOfNewTeamsToAnExistingAssignment.length +
          props.assignment.teams?.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment ||
        (listOfNewTeamsToAnExistingAssignment.length +
          props.assignment.teams?.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment - 1 &&
          selectedTeam?.teamName !== "") ||
        (listOfNewTeamsToAnExistingAssignment.length +
          props.assignment.teams?.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment &&
          selectedTeam?.teamName !== "" &&
          selectedTeamToRemove?.teamName !== "")
      ) {
        setExistingTeamNameError(
          `Du har nått maxgränsen ${props.maximumNumberOfEmployeeTeamsInAnAssignment} st teams per uppdrag.`
        );
        setShowNewTeamNameErrorModal(true);
        return;
      }
    }

    if (!props.assignment.teams?.length) {
      if (
        listOfNewTeamsToAnExistingAssignment.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment ||
        (listOfNewTeamsToAnExistingAssignment.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment - 1 &&
          selectedTeam?.teamName !== "") ||
        (listOfNewTeamsToAnExistingAssignment.length >=
          props.maximumNumberOfEmployeeTeamsInAnAssignment &&
          selectedTeam?.teamName !== "" &&
          selectedTeamToRemove?.teamName !== "")
      ) {
        setExistingTeamNameError(
          `Du har nått maxgränsen ${props.maximumNumberOfEmployeeTeamsInAnAssignment} st teams per uppdrag.`
        );
        setShowNewTeamNameErrorModal(true);
        return;
      }
    }

    const response = await doesTeamExist(teamRequest);
    if (!response) {
      setAddNewTeam(false);
      const team: EmployeeTeamDto = {
        teamName: newTeamName,
      };
      const tempList = [...teams];
      tempList.push(team);
      setTeams(tempList);
      const temporaryList = [...listOfNewTeamsToAnExistingAssignment];
      temporaryList.push(team);
      setListOfNewTeamsToAnExistingAssignment(temporaryList);
      setNewTeamCreatedWhileEditing(true);
      setNewTeamName("");
      props.setEditShow(true);
    } else {
      setExistingTeamNameError(
        "Du försöker skapa ett team som redan existerar. Välj teamet från listan av befintliga team."
      );
      setShowNewTeamNameErrorModal(true);
      return;
    }
  };

  const removeNewTeamHandler = (teamName: string) => {
    let tempList = [...listOfNewTeamsToAnExistingAssignment];
    tempList = tempList.filter((x) => x.teamName !== teamName);
    setListOfNewTeamsToAnExistingAssignment(tempList);
  };

  const selectedTeamHandler = (teamId: string) => {
    const team = listOfTeamsWithoutEmployee.find((x) => x.id === +teamId);
    setSelectedTeam(team);
  };

  return (
    <>
      <Modal show={confirmDelete} centered>
        <Modal.Header className="edit-modal">
          <h4>Vill du verkligen ta bort "{props.assignment.client}"?</h4>
        </Modal.Header>
        <Modal.Body>
          <h1 className="text-center">
            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
          </h1>
        </Modal.Body>
        <Modal.Footer className="edit-modal">
          <ButtonCancel
            text="Ångra"
            handleClick={() => {
              setConfirmDelete(false);
              props.setEditShow(true);
              setDescription("");
              setSelectedTeam(undefined);
              setSelectedTeamToRemove(undefined);
              setClient(props.assignment.client);
              setStartDate(props.assignment.assignmentStartDate?.toString());
              setEndDate(props.assignment.assignmentEndDate?.toString());
            }}
          />
          <ButtonPrimary
            text="Ta bort"
            handleClick={() => {
              deleteEmployeeAssignmentHandler();
              setConfirmDelete(false);
              closeHandler();
              props.setAddNew(false);
            }}
          />
        </Modal.Footer>
      </Modal>
      <Modal show={props.editShow} className="mx-auto" centered>
        <Modal.Header className="edit-modal w-auto">
          <h5>
            Redigera {props.assignment.client ? props.assignment.client : ""}
          </h5>
        </Modal.Header>
        <Modal.Body className="hstack">
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="vstack">
              <Col>
                <Form.Group>
                  <Form.Label>Nuvarande kunduppdrag</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    disabled
                    required
                    value={client}
                    className="read-only-cursor"
                  />
                  <Form.Label className="mt-3">Uppdragsbeskrivning</Form.Label>
                  <OverlayTrigger
                    show={showWordsToolTip}
                    placement="top"
                    overlay={tooltipWords}
                  >
                    <Form.Control
                      value={description}
                      onChange={(e) =>
                        handleChangeCurrentDescription(e.target.value)
                      }
                      onFocus={handleFocusDescription}
                      onBlur={handleBlurDescription}
                      as="textarea"
                      placeholder="Beskriv ditt uppdrag..."
                      rows={7}
                      maxLength={200}
                    />
                  </OverlayTrigger>
                  <OverlayTrigger
                    show={showRemainingLettersToolTip}
                    placement="bottom"
                    overlay={tooltipRemaining}
                  >
                    <div></div>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6}>
              <Col>
                <Form.Group>
                  <Form.Label>Ort</Form.Label>
                  <Form.Control
                    type="text"
                    disabled
                    readOnly
                    required
                    value={props.selectedOfficeLocationForEdit}
                    className="read-only-cursor mb-3"
                  />
                  <Form.Label>Gå ur team</Form.Label>
                  <Form.Select
                    value={selectedTeamToRemove?.id || 0}
                    className="mb-3"
                    disabled={listOfTeamsWithEmployee.length === 0}
                    onChange={(e) => teamWithdrawHandler(e.target.value)}
                  >
                    <option value={0} disabled={!selectedTeamToRemove}>
                      {listOfTeamsWithEmployee &&
                      listOfTeamsWithEmployee.length > 0
                        ? "Välj team att gå ur"
                        : "Du tillhör inget team"}
                    </option>
                    {listOfTeamsWithEmployee &&
                      listOfTeamsWithEmployee.map((team) => (
                        <option key={team.id} value={team.id}>
                          {team.teamName}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Label>Gå med i team</Form.Label>
                  <Form.Select
                    value={selectedTeam?.id || ""}
                    className="mb-3"
                    disabled={listOfTeamsWithoutEmployee?.length === 0}
                    onChange={(e) => selectedTeamHandler(e.target.value)}
                  >
                    <option value="" disabled>
                      {listOfTeamsWithoutEmployee &&
                      listOfTeamsWithoutEmployee?.length > 0
                        ? "Välj team"
                        : "Inga existerande team"}
                    </option>
                    {listOfTeamsWithoutEmployee &&
                      listOfTeamsWithoutEmployee.map((team) => (
                        <option value={team.id}>{team.teamName}</option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Col className="d-flex justify-content-center aling-items-center">
                  <ButtonSecondary
                    text="Skapa nytt team"
                    handleClick={() => {
                      setAddNewTeam(true);
                      props.setEditShow(false);
                    }}
                    className="mx-auto"
                  />
                </Col>
              </Col>
            </Col>
            <Row>
              <Col
                className="d-flex hstack gap-1 mt-2"
                style={{ flexWrap: "wrap" }}
              >
                {newTeamCreatedWhileEditing &&
                  listOfNewTeamsToAnExistingAssignment &&
                  listOfNewTeamsToAnExistingAssignment.length > 0 &&
                  listOfNewTeamsToAnExistingAssignment.map((newTeam, index) => (
                    <Badge
                      key={`newTeam_${index}`}
                      bg="secondary"
                      className="d-flex align-items-center"
                    >
                      {newTeam.teamName}
                      <CloseButton
                        onClick={() => removeNewTeamHandler(newTeam.teamName)}
                      />
                    </Badge>
                  ))}
              </Col>
            </Row>
            <Row className="hstack my-3">
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Form.Label>Startdatum</Form.Label>
                <Form.Control
                  type="date"
                  required
                  disabled={client === "Saknar uppdrag"}
                  value={startDate ? startDate.toString().substring(0, 10) : ""}
                  onChange={(e) => handleChangeStartDate(e.target.value)}
                ></Form.Control>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <Form.Label>Slutdatum</Form.Label>
                <Form.Control
                  type="date"
                  required
                  disabled={client === "Saknar uppdrag"}
                  value={endDate ? endDate.toString().substring(0, 10) : ""}
                  onChange={(e) => handleChangeEndDate(e.target.value)}
                ></Form.Control>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonSecondary
            text="Radera post"
            handleClick={() => {
              setConfirmDelete(true);
              props.setEditShow(false);
              props.setSelectedItem("");
            }}
          />
          <ButtonCancel
            text="Avbryt"
            handleClick={() => {
              props.handleClickNo();
              setSelectedTeam(undefined);
              setSelectedTeamToRemove(undefined);
              setEmployeeTeamsInAnAssignment([]);
              props.setSelectedItem("");
              setClient(props.assignment.client);
              setDescription(props.assignment.assignmentDescription);
              setStartDate(
                props.assignment.assignmentStartDate
                  ?.toString()
                  .substring(0, 10)
              );
              setEndDate(
                props.assignment.assignmentEndDate?.toString().substring(0, 10)
              );
              setListOfNewTeamsToAnExistingAssignment([]);
              queryClient.invalidateQueries({
                queryKey: ["requestTeamsWithAndWithoutEmployee"],
              });
            }}
          />
          <ButtonPrimary
            text="Uppdatera"
            handleClick={() => editAssignmentHandler()}
            disabled={!startDate || dateChecker() || !isInputValid || !client}
            tooltipText={errorText}
          />
        </Modal.Footer>
      </Modal>
      {addNewTeam && (
        <Modal show={addNewTeam} centered>
          <Modal.Header style={{ backgroundColor: "black", color: "white" }}>
            <h6>Skapa nytt team</h6>
          </Modal.Header>
          <Modal.Body>
            <Row className="hstack">
              <Col>
                <Form.Group>
                  <Form.Label>Teamets namn</Form.Label>
                  <Form.Control
                    type="text"
                    value={newTeamName}
                    placeholder="Fyll i teamnamn..."
                    onChange={(e) => setNewTeamName(e.target.value)}
                    maxLength={30}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "black" }}>
            <ButtonCancel
              text="Avbryt"
              handleClick={() => {
                setAddNewTeam(false);
                setNewTeamName("");
                setSelectedNewTeamOffice("");
                props.setEditShow(true);
              }}
            />
            <ButtonPrimary
              text="Spara team"
              handleClick={() => {
                newTeamHandler();
              }}
              disabled={newTeamName === ""}
              tooltipText={errorList}
            />
          </Modal.Footer>
        </Modal>
      )}
      {showNewTeamNameErrorModal && (
        <ModalError
          show={showNewTeamNameErrorModal}
          text={existingTeamNameError ?? ""}
          centered={true}
          handleClickYes={() => {
            setShowNewTeamNameErrorModal(false);
            setExistingTeamNameError("");
          }}
        />
      )}
    </>
  );
};
export default EditAssignmentModal;
