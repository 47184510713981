import React from "react";
import ProfileSubHeader from "../../atoms/ProfileSubHeader";
import { EmployeeAssignmentDto } from "../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import ProfileAssignments from "./atoms/ProfileAssignments";

const ProfileUpcomingAssignments = (props: {
  upcomingAssignment?: EmployeeAssignmentDto[];
}): React.JSX.Element =>
  props.upcomingAssignment && props.upcomingAssignment.length > 0 ? (
    <>
      <ProfileSubHeader headerValue="Kommande uppdrag" />
      <ProfileAssignments
        assignments={props.upcomingAssignment}
        showDetails={true}
        customStarColor="#c0c0c0"
      />
    </>
  ) : (
    <></>
  );

export default ProfileUpcomingAssignments;
