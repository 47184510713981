import { SetStateAction, useEffect } from "react";
import { BrowseEmployeeDto } from "../../../../models/Dto/EmployeeSummaryDto";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetAllJobTitlesByOfficeLocation } from "../../../../services/OfficeLocationService";
import { Form } from "react-bootstrap";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../../models/Enums/SearchType";

interface JobTitleSearchProps {
  activeSearchArea: string;
  selectedJobTitle: string;
  triggerReRender: boolean;
  setTriggerReRender: React.Dispatch<SetStateAction<boolean>>;
  setSelectedJobTitle: React.Dispatch<SetStateAction<string>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
  searchType: SearchType;
}

const JobTitleTemplate: React.FC<JobTitleSearchProps> = (props) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<string[]>({
    queryKey: ["jobTitles", props.activeSearchArea],
    queryFn: () => GetAllJobTitlesByOfficeLocation(props.activeSearchArea),
  });

  const handleChange = async (jobTitle: string) => {
    props.setSelectedJobTitle(jobTitle);

    const filter: SearchFilters = { jobTitle: jobTitle };
    sessionStorage.setItem("filters", JSON.stringify(filter));

    queryClient.invalidateQueries({ queryKey: ["employees"] });
    props.setTriggerReRender(true);
  };

  useEffect(() => {
    if (props.searchType === SearchType.JobTitleSearch) {
      if (data && !data.find((x) => x === props.selectedJobTitle)) {
        props.setSelectedJobTitle("");
      }
    }
    queryClient.invalidateQueries({ queryKey: ["employees"] });
  }, [props, data, queryClient]);

  if (isLoading) return <p>Laddar roller...</p>;

  return (
    <Form.Select
      value={
        data && data.find((x) => x === props.selectedJobTitle)
          ? props.selectedJobTitle
          : ""
      }
      onChange={(e) => handleChange(e.target.value)}
      className="wrapper"
    >
      <option value={""} disabled={props.selectedJobTitle !== ""}>
        Välj en roll
      </option>
      {data && data?.length > 0 && <option disabled>-----------------</option>}
      {data &&
        data.map((jobTitle) => (
          <option key={`skill_${jobTitle}`} value={jobTitle}>
            {jobTitle}
          </option>
        ))}
    </Form.Select>
  );
};

export default JobTitleTemplate;
