import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect } from "react";
import { updateIsSigningIn } from "../../../redux/slices/userSlice";

interface WelcomeRedirectPageProps {
  refresh: () => void;
}

const WelcomeRedirectPage: React.FC<WelcomeRedirectPageProps> = (props) => {
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const userName: string | undefined = useSelector(
    (state: RootState) => state.user.givenName
  );
  const { refresh } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(updateIsSigningIn(false));
      refresh();
    }, 3000);

    return () => clearTimeout(timer);
  }, [dispatch, refresh]);

  return (
    <Container>
      <Row>
        <Col className="text-center mt-5">
          {userName ? <h1>Välkommen {userName}!</h1> : <h1>Välkommen!</h1>}
          {officeLocation ? (
            <h2>Du tillhör {officeLocation}.</h2>
          ) : (
            <h2>
              Du verkar inte tillhöra något kontor, vilket kan begränsa vissa
              funktioner i applikationen!
            </h2>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomeRedirectPage;
