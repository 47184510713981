import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { updateUserSelectedSearchArea } from "../../../redux/slices/userSlice";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SearchFilters } from "../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../models/Enums/SearchType";
import { SetStateAction } from "react";
import { getRegions } from "../../../services/OfficeLocationService";

interface SearchAreaProps {
  searchType: SearchType;
  setSelectedManager: React.Dispatch<SetStateAction<string>>;
}

const SearchArea: React.FC<SearchAreaProps> = (props) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const initialOfficeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const activeSearchArea: string | undefined = useSelector(
    (state: RootState) => state.user.userSelectedSearchArea
  );
  const officeLocations: string[] | undefined = useSelector(
    (state: RootState) => state.user.officeLocations
  );

  const { data: regions } = useQuery({
    queryKey: ["regions"],
    queryFn: () => getRegions(),
  });

  const handleSearchAreaChange = (searchArea: string) => {
    dispatch(updateUserSelectedSearchArea(searchArea));
    const filters = sessionStorage.getItem("filters");
    const parsedFilters = filters ? (JSON.parse(filters) as SearchFilters) : "";
    // if (props.searchType !== SearchType.JobTitleSearch)
    if (parsedFilters !== "" && parsedFilters.manager) {
      props.setSelectedManager("");
      sessionStorage.setItem("filters", "");
    }
    if (
      parsedFilters !== "" &&
      (parsedFilters.skill ||
        parsedFilters.client ||
        parsedFilters.jobTitle ||
        parsedFilters.teamId)
    ) {
      sessionStorage.setItem("filters", "");
    }
    queryClient.invalidateQueries({ queryKey: ["employees"] });
  };

  return (
    <Col className="search-city-section hstack d-flex justify-content-start">
      <Form.Select
        className="wrapper"
        value={activeSearchArea || ""}
        onChange={(e) => handleSearchAreaChange(e.target.value)}
      >
        {initialOfficeLocation && (
          <option value={initialOfficeLocation}>{initialOfficeLocation}</option>
        )}
        <option value={"all"}>Alla kontor</option>
        <option disabled>-----------</option>
        {officeLocations?.map(
          (office) =>
            office !== initialOfficeLocation && (
              <option key={office} value={office}>
                {office}
              </option>
            )
        )}
        {regions && <option disabled>-----------</option>}
        {regions &&
          regions.map((region, index) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
      </Form.Select>
    </Col>
  );
};

export default SearchArea;
