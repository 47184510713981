import { ListGroup } from "react-bootstrap";
import "../AdminAccordionSkills.css";
import { SkillFromDbDto } from "../../../../models/Dto/SkillFromDbDto";
import { StoryboardSkillAdd } from "../../../../models/Dto/StoryBoardSkillAdd";

interface CardListProps {
  skills: SkillFromDbDto[];
  handleSkillClick: (skill: StoryboardSkillAdd | undefined) => void;
}

const CardList: React.FC<CardListProps> = (props) => {
  return (
    <ListGroup variant="flush" className="p-0 m-0">
      {props.skills.map((skill, index) => (
        <ListGroup.Item
          key={skill.id}
          onClick={() => props.handleSkillClick(skill)}
          style={{ cursor: "pointer" }}
        >
          {skill.name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default CardList;
