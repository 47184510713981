import { Modal, Row } from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { EmployeeAssignmentDto } from "../interfaces/EmployeeAssignmentDto";
import { useQueryClient } from "@tanstack/react-query";
import "./ModalNoClient.css";
import ButtonCancel from "../../../atoms/ButtonCancel/ButtonCancel";

export interface ModalNoClientProps {
  show: boolean;
  handleClickYes: () => void;
  handleClickNo: () => void;
  text: string;
  onHide?: () => void;
  setNoClientConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  userAssignments: EmployeeAssignmentDto[] | undefined;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  setAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNoClientChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isNoClientChecked: boolean;
}

const ModalNoClient: React.FC<ModalNoClientProps> = (props) => {
  const queryClient = useQueryClient();
  return (
    <Modal onHide={props.onHide} show={props.show} className="mx-auto">
      <Modal.Header className="modal-header-styler w-auto">
        <h5>{props.text}</h5>
      </Modal.Header>

      <Modal.Body>
        {props.userAssignments &&
          props.userAssignments.some(
            (assignment) =>
              assignment.assignmentEndDate === null &&
              new Date(assignment.assignmentStartDate!) < new Date()
          ) && (
            <Row>
              <h6>Följande "nuvarande uppdrag" kommer tas bort:</h6>
            </Row>
          )}
        {props.userAssignments &&
          props.userAssignments.length > 0 &&
          props.userAssignments.map((assignment) => (
            <Row key={assignment.id}>
              {assignment.assignmentEndDate === null &&
                assignment.assignmentStartDate &&
                new Date(assignment.assignmentStartDate) < new Date() && (
                  <li>{assignment.client}</li>
                )}
            </Row>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <ButtonCancel
          text="Ångra"
          handleClick={() => {
            props.handleClickNo();
            props.setSelectedItem("");
            props.setIsNoClientChecked(false);
          }}
        />
        <ButtonSecondary
          text="Ja, ändra"
          handleClick={() => {
            props.handleClickYes();
            props.setNoClientConfirmed(true);
            props.setSelectedItem("");
            props.setAddNew(false);
            queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNoClient;
