import { Col, Row } from "react-bootstrap";

interface ProfileTriviaInfoProps {
  trivia?: string;
}

const ProfileTrivia: React.FC<ProfileTriviaInfoProps> = (props) => {
  return (
    <Row className="d-flex justify-content-center my-2">
      <Col xs={10} sm={10} md={9} l={8} xl={9}>
        <p className="assignment-description text-center my-0">
          {props.trivia}
        </p>
      </Col>
    </Row>
  );
};

export default ProfileTrivia;
