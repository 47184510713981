import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

const StoryHeader: React.FC = () => {
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );

  return (
    <h2>
      Senaste nytt -&nbsp;
      <span className="d-block d-md-inline">{officeLocation}</span>
    </h2>
  );
};

export default StoryHeader;
