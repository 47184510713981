import { Col, Row } from "react-bootstrap";

interface ProfileEmploymentHiredSinceProps {
  hiredSince: string;
}

const ProfileEmploymentHiredSince: React.FC<
  ProfileEmploymentHiredSinceProps
> = (props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-end">
        <label>Anställd sedan:</label>
      </Col>
      <Col className="d-flex justify-content-start gx-0">
        <p className="my-0">{props.hiredSince.substring(0, 10)}</p>
      </Col>
    </Row>
  );
};

export default ProfileEmploymentHiredSince;
