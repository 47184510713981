import { PhoneFilled } from "@ant-design/icons";
import { Col, Row } from "react-bootstrap";

interface ProfilePhoneProps {
  businessPhones: string[];
}

const ProfilePhone: React.FC<ProfilePhoneProps> = (props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-center">
        {props.businessPhones.length > 0 && (
          <p className="my-0 d-flex align-items-center">
            <PhoneFilled className="pe-2" />
            {props.businessPhones[0]}
          </p>
        )}
      </Col>
    </Row>
  );
};

export default ProfilePhone;
