import { Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { updateOfficeLocation } from "../../../../redux/slices/userSlice";
import "./OfficeLocationSelector.css";
import { useQueryClient } from "@tanstack/react-query";

export interface OfficeLocationSelecterProps {
  mobileOrDesktop: string;
}

const OfficeLocationSelecter: React.FC<OfficeLocationSelecterProps> = (
  props
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const [selectedOffice, setSelectedOffice] = useState<string>(
    officeLocation ?? ""
  );

  const officeLocations: string[] | undefined = useSelector(
    (state: RootState) => state.user.officeLocations
  );

  const initOffice: string | undefined = useSelector(
    (state: RootState) => state.user.initialOfficeLocation
  );

  useEffect(() => {
    if (selectedOffice && selectedOffice !== officeLocation) {
      dispatch(updateOfficeLocation(selectedOffice));
      queryClient.invalidateQueries({ queryKey: ["storyboard"] });
    }
  }, [selectedOffice, dispatch, officeLocation, queryClient]);

  return (
    <Col className={`${props.mobileOrDesktop} d-flex justify-content-center`}>
      <Form.Select
        value={selectedOffice}
        className="w-75"
        onChange={(e) => setSelectedOffice(e.target.value)}
      >
        {initOffice && (
          <option key={`office_init`} value={initOffice}>
            {initOffice}
          </option>
        )}
        <option value={"Alla kontor"}>Alla kontor</option>
        <option disabled>---------</option>
        {officeLocations
          .filter((office) => office !== initOffice)
          .map((office, index) => (
            <option key={`office_${index}`} value={office}>
              {office}
            </option>
          ))}
      </Form.Select>
    </Col>
  );
};

export default OfficeLocationSelecter;
