import { Accordion, Col, Row } from "react-bootstrap";
import OfficeLocationSelecter from "./OfficeLocationSelecter";
import EmployeeDisplayer from "../atoms/EmployeeDisplayer";
import { useState } from "react";
import { EmployeeActivationDto } from "../../../../models/Dto/EmployeeActivationDto";
import { getActivationStatusByOfficeLocation } from "../../../../services/EmployeeService";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import AdminActivationInfoText from "../atoms/AdminActivationInfoText";

export interface AccordionActivationProps {
  confirmChange: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  responseText: string | undefined;
  selectedEmployeeToUpdate: EmployeeActivationDto | undefined;
  setSelectedEmployeeToUpdate: React.Dispatch<
    React.SetStateAction<EmployeeActivationDto | undefined>
  >;
  setResponseText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const AccordionActivation: React.FC<AccordionActivationProps> = (props) => {
  const userOfficeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const [selectedOfficeLocation, setSelectedOfficeLocation] = useState<
    string | undefined
  >(userOfficeLocation);
  const [showInitialInfo, setShowInitialInfo] = useState<boolean>(true);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const { data: employees } = useQuery<EmployeeActivationDto[], Error>({
    queryKey: ["employees", selectedOfficeLocation],
    queryFn: () => getActivationStatusByOfficeLocation(selectedOfficeLocation!),
    enabled: !!selectedOfficeLocation,
  });

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header
          onClick={handleAccordionToggle}
          className="flex-grow-1"
        >
          Hantera personalaktivering
        </Accordion.Header>
        <Accordion.Body className="shadow rounded">
          <Row>
            {showInitialInfo && (
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-3">
                <AdminActivationInfoText
                  setShowInitialInfo={setShowInitialInfo}
                />
              </Col>
            )}
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <OfficeLocationSelecter
                selectedOfficeLocation={selectedOfficeLocation}
                setSelectedOfficeLocation={setSelectedOfficeLocation}
                userOfficeLocation={userOfficeLocation}
                responseText={props.responseText}
                setResponseText={props.setResponseText}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <EmployeeDisplayer
                employees={employees}
                confirmChange={props.confirmChange}
                setShowModal={props.setShowModal}
                setSelectedEmployeeToUpdate={props.setSelectedEmployeeToUpdate}
                selectedEmployeeToUpdate={props.selectedEmployeeToUpdate}
                selectedOfficeLocation={selectedOfficeLocation}
                setShowInitialInfo={setShowInitialInfo}
                showInitialInfo={showInitialInfo}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionActivation;
