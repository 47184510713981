import { Modal } from "react-bootstrap";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";

interface ModalYesOrNoProps{
    show: boolean;
    handleClickNo: () => void;
    handleClickYes: () => void;
    text: string;
    onHide?: () => void;
}

const ModalYesOrNo: React.FC<ModalYesOrNoProps> = (props) => {

    return (
        <Modal onHide={props.onHide} show={props.show} className="mx-auto">
            <Modal.Header className="w-auto">{props.text}</Modal.Header>
            <Modal.Footer>
                <ButtonSecondary text="Nej" handleClick={props.handleClickNo} />
                <ButtonSecondary text="Ja" handleClick={props.handleClickYes}/>
            </Modal.Footer>
        </Modal>

    )
}

export default ModalYesOrNo;