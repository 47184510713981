import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col } from "react-bootstrap";
import { StoryboardDto } from "../../../../models/Dto/StoryboardDto";
import PaginationDropdown from "./PaginationDropdown";

interface PaginationPageTogglerProps {
  requestedPage: number;
  setRequestedPage: React.Dispatch<React.SetStateAction<number>>;
  data: StoryboardDto;
}

const PaginationPageToggler: React.FC<PaginationPageTogglerProps> = (props) => {
  return (
    <Col className="d-flex justify-content-center align-items-center hstack">
      {props.requestedPage !== 1 && (
        <LeftOutlined
          className="arrow-paging me-1 fs-4"
          onClick={() => props.setRequestedPage(props.requestedPage - 1)}
        />
      )}
      <PaginationDropdown
        data={props.data}
        setRequestedPage={props.setRequestedPage}
        requestedPage={props.requestedPage}
      />
      {props.requestedPage !== props.data.storyboardPagination.totalPages && (
        <RightOutlined
          className="arrow-paging ms-1 fs-4"
          onClick={() => props.setRequestedPage(props.requestedPage + 1)}
        />
      )}
    </Col>
  );
};

export default PaginationPageToggler;
