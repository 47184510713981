import { Col, Row } from "react-bootstrap";

interface ProfileExperienceProps {
  experienceSince: number;
}

const ProfileExperience: React.FC<ProfileExperienceProps> = (props) => {
  const yearNow: number = new Date().getFullYear();
  const yearsInBiz = yearNow - props.experienceSince;

  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <h6>
          {yearsInBiz !== 0
            ? `${yearsInBiz} år i branschen`
            : "Mindre än ett års erfarenhet"}
        </h6>
      </Col>
    </Row>
  );
};

export default ProfileExperience;
