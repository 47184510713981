import React from "react";
import { Col, Row } from "react-bootstrap";

interface ProfileEmailProps {
  email: string;
}

const ProfileEmail: React.FC<ProfileEmailProps> = (props) => {
  const renderEmail = () => {
    if (props.email.length > 45) {
      const atIndex = props.email.indexOf("@");
      if (atIndex !== -1) {
        const part1 = props.email.substring(0, atIndex + 1);
        const part2 = props.email.substring(atIndex + 1);
        return (
          <>
            {part1}
            <br />
            {part2}
          </>
        );
      }
    }
    return props.email;
  };

  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <p className="my-0">{renderEmail()}</p>
      </Col>
    </Row>
  );
};

export default ProfileEmail;
