import { Image } from "react-bootstrap";

const ConsidLogoWhite: React.FC = () => {
  const basePath = process.env.PUBLIC_URL;
  return (
    <Image
      src={`${basePath}/Consid-logo-liggande-vit.png`}
      height={"40px"}
      alt="Consid-Logo"
      tabIndex={1}
      className="mx-0 px-0"
    />
  );
};

export default ConsidLogoWhite;
