import {
  Badge,
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { EmployeeAssignmentDto } from "./interfaces/EmployeeAssignmentDto";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { EmployeeDetailsDto } from "../../organisms/UserProfile/models/EmployeeDetailsDto";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import { EmployeeTeamDto } from "../../../models/Dto/EmployeeTeamDto";
import {
  addAnAssignment,
  getAllTeamsByAnAssignment,
  removeNoClient,
} from "../../../services/AssignmentService";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import "./AssignmentModal.css";
import { useQuery } from "@tanstack/react-query";
import { UpdateEmployeeAssignmentDto } from "./interfaces/UpdateEmployeeAssignmentDto";
import ModalError from "./atoms/ModalError";
import { GetTeamsDto } from "../../../models/Dto/GetTeamsDto";

export interface AssignmentModalProps {
  show: boolean;
  handleClickNo: () => void;
  handleClickYes: () => void;
  text: string;
  onHide?: () => void;
  assignments?: EmployeeAssignmentDto[];
  setShowAssignmentModal: React.Dispatch<React.SetStateAction<boolean>>;
  details: EmployeeDetailsDto;
  selectedItem: string;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  setAddNew: React.Dispatch<React.SetStateAction<boolean>>;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  setIsAssignmentSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isAssignmentSelected: boolean;
  setShowTeamErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  showTeamErrorModal: boolean;
  inputValue: string;
  firstTimeAssignment: boolean;
  setFirstTimeAssignment: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAssignmentExisting: React.Dispatch<React.SetStateAction<boolean>>;
  isAssignmentExisting: boolean;
  setSelectedOffice: React.Dispatch<React.SetStateAction<string>>;
  selectedOffice: string;
  maximumNumberOfEmployeeTeamsInAnAssignment: number;
  showLocalTeamErrorModal: boolean;
  setShowLocalTeamErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface DescriptionValidator {
  isWordsOK: boolean;
  showRemainingTooltip: boolean;
}

const AssignmentModal: React.FC<AssignmentModalProps> = (props) => {
  const dummyTeam: EmployeeTeamDto = {
    teamName: "",
  };
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [description, setDescription] = useState<string>("");
  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const officeLocation: string = useSelector(
    (state: RootState) => state.user.officeLocation ?? ""
  );
  const descriptionError: string = `Ett ord i din uppdragsbeskrivning överstiger 30 tecken.`;
  const [errorText, setErrorText] = useState<string[]>([]);
  const [remainingFocus, setRemainingFocus] = useState<boolean>(false);
  const maximumDescriptionLetterLength: number = 200;
  const maximumDescriptionLetterWarning: number = 150;
  const [showRemainingLettersToolTip, setShowRemainingLettersTooltip] =
    useState<boolean>(false);
  const [showWordsToolTip, setShowWordsTooltip] = useState<boolean>(false);
  const [tooltipRemainingText, setTooltipRemainingText] = useState<string>("");
  const [tooltipWordsText, setTooltipWordsText] = useState<string>("");
  const [tooltipBgColor, setTooltipBgColor] = useState<string>("");
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const toolTipDateErrorMessage: string = "Startdatum ej angivet.";
  const toolTipNoClientErrorMessage: string = "Uppdragsgivare ej angivet.";

  // TEAMS RELATERAT
  const [request, setRequest] = useState<GetTeamsDto>();
  const [addNewTeam, setAddNewTeam] = useState<boolean>(false);

  const [listOfTeamInAnAssignment, setListOfTeamsInAnAssignment] = useState<
    EmployeeTeamDto[]
  >([]);
  const [selectedTeam, setSelectedTeam] = useState<EmployeeTeamDto>(dummyTeam);
  const [addedTeams, setAddedTeams] = useState<EmployeeTeamDto[]>([]);
  const [teamInputValue, setTeamInputValue] = useState<string>("");
  //const [isSelectedTeamExisting, setIsSelectedTeamExisting] = useState<boolean>(true);
  //const [teams, setTeams] = useState<EmployeeTeamDto[]>([]);
  //const [employeeTempTeams, setEmployeeTempTeams] = useState<{[key: string]: number;}>({});
  const [teamErrorText, setTeamErrorText] = useState<string>("");
  const [showTeamsSelect, setShowTeamsSelect] = useState<boolean>(true);
  //const [employeeStartingOffice, setEmployeeStartingOffice] =useState<string>("");
  //const [selectedTeamOffice, setSelectedTeamOffice] = useState<string>(employeeStartingOffice);
  //const [tempTeamID, setTempTeamId] = useState<number>(0);
  const toolTipNewTeamNotConfirmedErrorMessage: string = `Teamet har inte lagts till. Klicka "Skapa nytt" för att lägga till det eller "Ångra" för att ta bort det.`;
  const toolTipNoLettersErrorMessage: string = "Ange ett teamnamn.";

  const { data: teamList } = useQuery<EmployeeTeamDto[], Error>({
    queryKey: ["requestTeams", request],
    queryFn: () => getAllTeamsByAnAssignment(request!),
    enabled: !!request,
  });

  useEffect(() => {
    if (teamList) {
      setListOfTeamsInAnAssignment(teamList);
    }
  }, [teamList]);

  useEffect(() => {
    let tempErrorList = [...errorText];
    const confirmErrorExists = tempErrorList.some((x) =>
      x.includes(toolTipNewTeamNotConfirmedErrorMessage)
    );
    const noLetterErrorExists = tempErrorList.some((x) =>
      x.includes(toolTipNoLettersErrorMessage)
    );

    if (
      teamInputValue.length > 0 &&
      teamInputValue.length < 50 &&
      confirmErrorExists === false
    ) {
      tempErrorList.push(toolTipNewTeamNotConfirmedErrorMessage);
    }
    if (teamInputValue.length === 0 && confirmErrorExists) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNewTeamNotConfirmedErrorMessage
      );
    }
    if (
      teamInputValue.length === 0 &&
      addNewTeam &&
      noLetterErrorExists === false
    ) {
      tempErrorList.push(toolTipNoLettersErrorMessage);
    }
    if (teamInputValue.length > 0 && noLetterErrorExists) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoLettersErrorMessage
      );
    }
    if (selectedTeam.teamName === "") {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoLettersErrorMessage
      );
    }

    setErrorText(tempErrorList);
  }, [teamInputValue, addNewTeam, selectedTeam.teamName]);

  useEffect(() => {
    if (addedTeams.length === 0) {
      setShowTeamsSelect(true);
      setSelectedTeam(dummyTeam);
    }
  }, [addedTeams]);

  useEffect(() => {
    if (props.selectedItem && props.selectedOffice) {
      let newRequest: GetTeamsDto = {
        assignment: props.selectedItem,
        officeLocation: props.selectedOffice,
      };
      setRequest(newRequest);
    }

    let tempErrorList = [...errorText];

    // Adding error messages
    if (
      props.selectedItem &&
      !startDate &&
      !tempErrorList.includes(toolTipDateErrorMessage)
    ) {
      tempErrorList.push(toolTipDateErrorMessage);
    }
    if (
      !props.selectedItem &&
      startDate &&
      !tempErrorList.includes(toolTipNoClientErrorMessage)
    ) {
      tempErrorList.push(toolTipNoClientErrorMessage);
    }

    // Removing error messages
    if (props.selectedItem && startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipDateErrorMessage
      );
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoClientErrorMessage
      );
    }
    if (!props.selectedItem && startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipDateErrorMessage
      );
    }
    if (props.selectedItem && !startDate) {
      tempErrorList = tempErrorList.filter(
        (e) => e !== toolTipNoClientErrorMessage
      );
    }

    setErrorText(tempErrorList);
  }, [startDate, props.selectedItem, props.selectedOffice]);

  useEffect(() => {
    const response = IsDescriptionInputInvalid(description ?? "");
    setShowWordsTooltip(!response.isWordsOK);
    setShowRemainingLettersTooltip(
      response.showRemainingTooltip && remainingFocus
    );

    const validateInput = () => {
      let tempErrorList = [...errorText];
      if (!response.isWordsOK) {
        if (!tempErrorList.some((x) => x === descriptionError)) {
          tempErrorList.push(descriptionError);
        }
      } else {
        tempErrorList = tempErrorList.filter((e) => e !== descriptionError);
      }

      setErrorText(tempErrorList);
      return tempErrorList.length === 0;
    };

    validateInput();

    if (!response.isWordsOK) {
      setIsInputValid(false);
      return;
    }
    setIsInputValid(true);
  }, [description, remainingFocus]);

  const handleChangeStartDate = (value: string) => {
    setStartDate(value);
  };

  const handleChangeEndDate = (value: string) => {
    setEndDate(value);
  };

  const handleChangeCurrentDescription = (value: string) => {
    setDescription(value);
  };

  const tooltipTextHandler = (letters: number) => {
    let text: string;
    let lettersLeft = maximumDescriptionLetterLength - letters;
    text = `${lettersLeft} tecken kvar...`;
    return text;
  };

  const isWordLengthOk = (value: string): boolean => {
    const words = value.split(/\s+/);
    const maxWordLength = 30;
    if (words.some((word) => word.length > maxWordLength)) {
      setTooltipWordsText(
        `Det är inte tillåtet att ange ord över ${maxWordLength} tecken.`
      );
      setTooltipBgColor("crimson");
      return false;
    } else {
      setTooltipWordsText("");
      return true;
    }
  };

  const isDescriptionTotalLettersOk = (value: string): boolean => {
    value = value.trimStart();
    if (value.length <= maximumDescriptionLetterWarning) {
      return true;
    }
    if (
      value.length > maximumDescriptionLetterWarning &&
      value.length < maximumDescriptionLetterLength
    ) {
      setTooltipBgColor("darkorange");
      setTooltipRemainingText(tooltipTextHandler(value.length));
      return false;
    }
    if (value.length === maximumDescriptionLetterLength) {
      setTooltipBgColor("crimson");
      setTooltipRemainingText("Max antal tecken nådd!");
      return false;
    }
    return true;
  };

  const tooltipRemaining = (
    <Tooltip id="tooltip-remaining" className={`tooltip-bg-${tooltipBgColor}`}>
      {tooltipRemainingText}
    </Tooltip>
  );

  const tooltipWords = (
    <Tooltip id="tooltip-words" className={`tooltip-bg-crimson`}>
      {tooltipWordsText}
    </Tooltip>
  );

  const IsDescriptionInputInvalid = (value: string): DescriptionValidator => {
    const isWordsOk = isWordLengthOk(value);
    const showTooltip = !isDescriptionTotalLettersOk(value);
    const response: DescriptionValidator = {
      isWordsOK: isWordsOk,
      showRemainingTooltip: showTooltip,
    };
    return response;
  };

  const teamHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (addedTeams.some((team) => team.teamName === e.target.value)) {
      props.setShowTeamErrorModal(true);
      setSelectedTeam(dummyTeam);
    }

    const team = teamList?.find((x) => x.id === +e.target.value);

    const teamDto: EmployeeTeamDto = {
      teamName: team?.teamName ?? "",
      id: team?.id,
    };
    if (+e.target.value === -1) {
      setAddNewTeam(true);
      //setIsSelectedTeamExisting(false);
      setSelectedTeam(teamDto);
    } else {
      setAddNewTeam(false);
      //setIsSelectedTeamExisting(true);
      setSelectedTeam(teamDto);
      addTeamsToList(teamDto);
    }
  };

  const addTeamsToList = (teamDto: EmployeeTeamDto) => {
    if (addedTeams.some((x) => x.teamName === teamDto.teamName)) {
      setTeamErrorText("Du har redan lagt till detta team");
      props.setShowLocalTeamErrorModal(true);
    } else {
      let tempList = [...addedTeams];
      tempList.push(teamDto);
      setAddedTeams(tempList);
      setTeamErrorText("");
      setSelectedTeam(teamDto);
    }
  };

  const removeTeamFromList = (teamDto: EmployeeTeamDto) => {
    setAddedTeams((prevTeams) =>
      prevTeams.filter((team) => {
        return team.teamName !== teamDto.teamName;
      })
    );
  };

  const moreTeamsHandler = () => {
    if (
      addedTeams.some(
        (x) =>
          x.teamName.toLocaleLowerCase() === teamInputValue.toLocaleLowerCase()
      )
    ) {
      setTeamErrorText("Du har redan skapat ett team med detta namn.");
      props.setShowAssignmentModal(false);
      props.setShowLocalTeamErrorModal(true);
      return;
    }
    if (addedTeams.length >= 5) {
      setTeamErrorText(
        `Du har nått maxgränsen ${props.maximumNumberOfEmployeeTeamsInAnAssignment} st teams per uppdrag.`
      );
      props.setShowAssignmentModal(false);
      props.setShowLocalTeamErrorModal(true);
      return;
    } else {
      setAddNewTeam(true);
      setSelectedTeam(dummyTeam);
      setShowTeamsSelect(true);
    }
  };

  /* const selectedTeamOfficeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedTeamOffice(e.target.value);
  }; */

  const newAssignmentHandler = async () => {
    const tempTeams: EmployeeTeamDto[] = addedTeams.map((team) => ({
      teamName: team.teamName,
      officeLocation: officeLocation,
      id: team.id ?? undefined,
    }));

    const newAssignment: UpdateEmployeeAssignmentDto = {
      userId: userId,
      client: props.selectedItem,
      assignmentStartDate: startDate ? new Date(startDate) : undefined,
      assignmentEndDate: endDate ? new Date(endDate) : undefined,
      teams: tempTeams,
      assignmentDescription: description,
      officeLocation: props.selectedOffice,
    };
    if (props.details.assignments) {
      let tempAssignment: EmployeeAssignmentDto[] = [
        ...props.details.assignments,
      ];
      tempAssignment.push(newAssignment);

      /* const empDet: EmployeeDetailsUpdateDto = {
        userId: userId,
        assignments: tempAssignment,
        experienceSince: props.details.experienceSince,
        skills: props.details.skills.filter((skill) => skill.skillId !== -1),
        trivia: props.details.trivia,
      }; */
      //const response = await updateEmployeeDetails(empDet);
      const response = await addAnAssignment(newAssignment);
      if (!response) return;
      if (
        response &&
        newAssignment.assignmentEndDate === undefined &&
        props.assignments?.some((x) => x.client === "Saknar uppdrag")
      ) {
        await removeNoClient(userId);
      }
      props.handleClickYes();
      props.setShowAssignmentModal(false);
      props.setSelectedItem("");
      props.setAddNew(false);
      props.setInputValue("");
      setEndDate("");
      setShowPrevious(false);
      handleChangeStartDate("");
      handleChangeCurrentDescription("");
      props.setFirstTimeAssignment(false);
      //dispatch(setOfficeLocation(employeeStartingOffice));
      setDescription("");
      setStartDate("");
      setEndDate("");
      setSelectedTeam(dummyTeam);
      setAddNewTeam(false);
      setTeamInputValue("");
      setAddedTeams([]);
    }
  };

  const dateChecker = () => {
    if (startDate && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        return true;
      } else return false;
    } else return false;
  };

  const handleFocusDescription = () => {
    setRemainingFocus(true);
  };

  const handleBlurDescription = () => {
    setRemainingFocus(false);
  };

  const newInputHandler = (value: string) => {
    const tempObject: EmployeeTeamDto = {
      teamName: value ? teamInputValue : "",
      id: undefined,
    };
    setSelectedTeam(tempObject);
    addTeamsToList(tempObject);
  };

  return (
    <>
      <Modal show={props.show} className="mx-auto" centered>
        <Modal.Header className="modal-header-styler w-auto">
          <Row className="vstack mb-1">
            <Col className="mb-2">
              <h5>
                {props.text} {props.selectedItem}
              </h5>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="w-100">
                <Form.Label>Startdatum: {props.selectedItem}</Form.Label>
                <Form.Control
                  type="date"
                  required
                  value={startDate ? startDate.toString().substring(0, 10) : ""}
                  onChange={(e) => handleChangeStartDate(e.target.value)}
                  className="mb-3"
                ></Form.Control>
              </Form.Group>
            </Col>
            {props.isAssignmentSelected && showTeamsSelect && (
              <>
                <Col>
                  <Form.Label>Eventuellt team</Form.Label>
                  <Form.Select
                    value={selectedTeam.id}
                    onChange={(e) => {
                      teamHandler(e);
                    }}
                  >
                    {listOfTeamInAnAssignment.length > 0 ? (
                      <>
                        <option value={0}>Välj ett team</option>
                        {listOfTeamInAnAssignment
                          .filter(
                            (team) =>
                              team.teamName !== "" &&
                              !addedTeams.some((x) => x.id === team.id)
                          )
                          .sort()
                          .map((team) => (
                            <option key={`teamId_${team.id}`} value={team.id}>
                              {team.teamName}
                            </option>
                          ))}
                        <option value={-1}>Mitt team är ej med i listan</option>
                      </>
                    ) : (
                      <>
                        <option value={-2} disabled>
                          Inget team valt
                        </option>
                        <option value="Annat team">Skapa nytt team</option>
                      </>
                    )}
                  </Form.Select>
                </Col>
                <Row className="hstack">
                  {addedTeams.length > 0 && (
                    <Row>
                      <Col>
                        <Form.Label>Teams:</Form.Label>
                      </Col>
                    </Row>
                  )}
                  <Row className="d-flex justify-content-start hstack">
                    <Col className="d-flex gap-2" style={{ flexWrap: "wrap" }}>
                      {addedTeams.map((team) => (
                        <Badge
                          key={team.id}
                          bg="secondary"
                          className="d-flex align-items-center hstack mb-3"
                        >
                          {team.teamName}
                          <CloseButton
                            className="ms-2"
                            onClick={() => removeTeamFromList(team)}
                          />
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                </Row>
              </>
            )}
            {!showTeamsSelect && (
              <Row>
                <Col
                  xl={7}
                  lg={7}
                  md={7}
                  sm={7}
                  xs={7}
                  className="d-flex vstack"
                >
                  {addedTeams.length > 0 && (
                    <Row>
                      <Col>
                        <Form.Label>Teams:</Form.Label>
                      </Col>
                    </Row>
                  )}
                  {/* <Row className="d-flex justify-content-start align-items-center hstack"> */}
                  <Col
                    className="d-flex justify-content-start align-items-center hstack gap-2"
                    style={{ flexWrap: "wrap" }}
                  >
                    {addedTeams.length > 0 &&
                      addedTeams.map((team) => (
                        <Badge
                          bg="secondary"
                          className="d-flex align-items-center hstack mb-3"
                          key={team.id}
                        >
                          {team.teamName}
                          <CloseButton
                            className="ms-2"
                            onClick={() => removeTeamFromList(team)}
                          />
                        </Badge>
                      ))}
                  </Col>
                  {/* </Row> */}
                </Col>
                <Col
                  xl={5}
                  lg={5}
                  md={5}
                  sm={5}
                  xs={5}
                  className="d-flex justify-content-end align-items-end"
                >
                  <ButtonSecondary
                    text="Lägg till fler team"
                    handleClick={moreTeamsHandler}
                  />
                </Col>
              </Row>
            )}
            {addNewTeam && (
              <>
                <Row>
                  <Form.Label>Nytt teamnamn</Form.Label>
                </Row>
                <Row className="mb-3 hstack">
                  <Col xl={12} lg={12} md={12} sm={12} xs={7}>
                    <Form.Control
                      type="text"
                      maxLength={30}
                      value={teamInputValue}
                      onChange={(e) => {
                        setTeamInputValue(e.target.value);
                      }}
                      className="w-100"
                      placeholder="Skriv in teamets namn..."
                    />
                  </Col>
                </Row>

                {teamInputValue.length > 0 && teamInputValue.length < 50 ? (
                  <Row>
                    <Col className="d-flex justify-content-end hstack">
                      <ButtonCancel
                        text="Ångra"
                        handleClick={() => {
                          setAddNewTeam(false);
                          setSelectedTeam(dummyTeam);
                        }}
                      />

                      <Button
                        disabled={!teamInputValue || teamInputValue.length > 50}
                        className="blinking-button ms-2"
                        onClick={() => {
                          newInputHandler(teamInputValue);
                          setAddNewTeam(false);
                          setTeamInputValue("");
                          setShowTeamsSelect(false);
                        }}
                      >
                        Skapa nytt
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col className="d-flex justify-content-end hstack">
                      <ButtonCancel
                        text="Ångra"
                        handleClick={() => {
                          setAddNewTeam(false);
                          setSelectedTeam(dummyTeam);
                        }}
                      />
                      <ButtonSecondary
                        text="Skapa nytt"
                        disabled={!teamInputValue || teamInputValue.length > 50}
                        className="ms-2"
                        handleClick={() => {
                          newInputHandler(teamInputValue);
                          setAddNewTeam(false);
                          setTeamInputValue("");
                          setShowTeamsSelect(false);
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            <Row>
              <Form.Group className="my-3" controlId="assignmentdescription">
                <Form.Label>Uppdragsbeskrivning:</Form.Label>
                <OverlayTrigger
                  show={showWordsToolTip}
                  placement="top"
                  overlay={tooltipWords}
                >
                  <Form.Control
                    value={description}
                    onChange={(e) =>
                      handleChangeCurrentDescription(e.target.value)
                    }
                    onFocus={handleFocusDescription}
                    onBlur={handleBlurDescription}
                    as="textarea"
                    placeholder="Beskriv ditt uppdrag..."
                    rows={3}
                    maxLength={200}
                  />
                </OverlayTrigger>
                <OverlayTrigger
                  show={showRemainingLettersToolTip}
                  placement="bottom"
                  overlay={tooltipRemaining}
                >
                  <div></div>
                </OverlayTrigger>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="d-flex justify-content-between hstack w-100">
                <Form.Label>Slutdatum: {props.selectedItem}</Form.Label>
                <Form.Check
                  type="switch"
                  label="Ange slutdatum?"
                  checked={showPrevious}
                  onChange={(e) => setShowPrevious(e.target.checked)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group className="w-100">
                  <Form.Label />
                  <Form.Control
                    type="date"
                    required={showPrevious}
                    disabled={!showPrevious}
                    value={endDate ? endDate.toString().substring(0, 10) : ""}
                    onChange={(e) => handleChangeEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonCancel
            text="Avbryt"
            handleClick={() => {
              props.handleClickNo();
              props.setSelectedItem("");
              props.setInputValue("");
              props.setAddNew(false);
              setDescription("");
              setStartDate("");
              setEndDate("");
              props.setFirstTimeAssignment(false);
              setSelectedTeam(dummyTeam);
              setAddNewTeam(false);
              setTeamInputValue("");
              setAddedTeams([]);
            }}
          />
          <ButtonPrimary
            text="Spara"
            handleClick={() => newAssignmentHandler()}
            disabled={
              !startDate ||
              dateChecker() ||
              !isInputValid ||
              teamInputValue.length > 0 ||
              (teamInputValue.length === 0 && addNewTeam === true)
            }
            tooltipText={errorText}
          />
        </Modal.Footer>
      </Modal>
      {props.showLocalTeamErrorModal && (
        <ModalError
          text={teamErrorText}
          show={props.showLocalTeamErrorModal}
          centered={true}
          handleClickYes={() => {
            props.setShowLocalTeamErrorModal(false);
            setTeamErrorText("");
            props.setShowAssignmentModal(true);
          }}
        />
      )}
    </>
  );
};

export default AssignmentModal;
