import { Accordion, Col, Row } from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import ButtonCancel from "../../../atoms/ButtonCancel/ButtonCancel";
import { StoryboardSkillAdd } from "../../../../models/Dto/StoryBoardSkillAdd";
import { SkillFromDbDto } from "../../../../models/Dto/SkillFromDbDto";
import AddSkillForm from "./AddSkillForm";
import "../AdminAccordionSkills.css";
import AddUpdateSkillResponse from "../atoms/AddUpdateSkillResponse";
import RemoveSkillForm from "../atoms/RemoveSkillForm";
import CardSkill from "../atoms/CardSkill";

interface SkillProps {
  handleSkillClick: (skill: StoryboardSkillAdd | undefined) => void;
  addSkill: (event?: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleFetchSkills: () => Promise<void>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setUpdateValue: React.Dispatch<React.SetStateAction<string>>;
  removeSkillHandler: (skill: StoryboardSkillAdd | undefined) => void;
  updateSkillHandler: () => void;
  updateSkill: (skillId: number, newName: string) => Promise<void>;
  userValue: string;
  updateValue: string;
  skills: SkillFromDbDto[];
  postResponse: boolean;
  responseText: string;
  postUpdateResponse: boolean;
  showUpdateOkButton: boolean;
  selectedId: StoryboardSkillAdd;
}

const AccordionSkill: React.FC<SkillProps> = (props) => {
  return (
    <Accordion onSelect={props.handleFetchSkills}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Hantera kompetenser</Accordion.Header>
        <Accordion.Body className="shadow rounded">
          <Row>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Row className="accordion-box mt-3 mb-md-0">
                <Col>
                  <AddSkillForm
                    addSkill={props.addSkill}
                    handleInputChange={props.handleInputChange}
                    userValue={props.userValue}
                  />
                </Col>
              </Row>

              <Row className="responsive-padding-left mb-md-0">
                <Col
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  xl={4}
                  xxl={4}
                  className="d-flex justify-content-start align-items-center"
                >
                  <ButtonSecondary
                    handleClick={props.addSkill}
                    text="Lägg till"
                    disabled={
                      !props.userValue ||
                      props.skills.some(
                        (x) =>
                          x.name.toLowerCase() === props.userValue.toLowerCase()
                      )
                    }
                  />
                </Col>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                  xxl={8}
                  className="d-flex justify-content-start align-items-center"
                >
                  <AddUpdateSkillResponse
                    postResponse={props.postResponse}
                    postUpdateResponse={props.postUpdateResponse}
                    responseText={props.responseText}
                  />
                </Col>
              </Row>
              <Row className="accordion-box mt-4 mb-md-0">
                <Col>
                  <RemoveSkillForm
                    setUpdateValue={props.setUpdateValue}
                    showUpdateOkButton={props.showUpdateOkButton}
                    updateValue={props.updateValue}
                  />
                </Col>
              </Row>
              <Row className="responsive-padding-left mb-md-0">
                <Col className="d-flex justify-content-start align-items-center">
                  <ButtonCancel
                    text="Ta bort"
                    disabled={props.selectedId === undefined}
                    handleClick={() =>
                      props.removeSkillHandler(props.selectedId)
                    }
                  />
                  <ButtonSecondary
                    text="Ändra"
                    handleClick={props.updateSkillHandler}
                    disabled={props.selectedId === undefined}
                    className="ms-4 mb-md-0"
                  />
                  {props.showUpdateOkButton && (
                    <ButtonSecondary
                      text="Spara"
                      handleClick={() =>
                        props.updateSkill(
                          props.selectedId?.id!,
                          props.updateValue
                        )
                      }
                      className="ms-4 mb-md-0"
                      disabled={
                        props.updateValue === "" ||
                        props.updateValue === props.selectedId?.name
                      }
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={12}>
              <Row className="mt-3 mb-md-0 me-md-2">
                <CardSkill
                  handleSkillClick={props.handleSkillClick}
                  skills={props.skills}
                />
              </Row>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionSkill;
