import { Badge, Card, Col, Row } from "react-bootstrap";
import { EmployeeAssignmentDto } from "./interfaces/EmployeeAssignmentDto";
import React from "react";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";

export interface MyAssignmentsProps {
  assignments?: EmployeeAssignmentDto[];
  editAssignmentHandler: (assignment: EmployeeAssignmentDto) => void;
  isChecked: boolean;
  isInputValid: boolean;
}

const MyAssignmentsMobile: React.FC<MyAssignmentsProps> = (props) => {
  const assignmentText = (assignment: EmployeeAssignmentDto) => {
    let startDate = assignment.assignmentStartDate
      ? new Date(assignment.assignmentStartDate)
      : null;
    let endDate = assignment.assignmentEndDate
      ? new Date(assignment.assignmentEndDate)
      : null;
    if (
      startDate &&
      startDate < new Date() &&
      endDate &&
      endDate < new Date()
    ) {
      return endDate.toLocaleDateString();
    }
    if (startDate && startDate < new Date() && endDate === null) {
      return "Pågående uppdrag";
    }
    if (startDate && startDate > new Date() && endDate === null) {
      return "Kommande uppdrag";
    }
    if (assignment.client === "Saknar uppdrag") return "";
    else return endDate ? endDate.toLocaleDateString() : "Datumfel";
  };

  const getColor = (assignment: EmployeeAssignmentDto): string => {
    const today = new Date();

    const startDate = assignment.assignmentStartDate
      ? new Date(assignment.assignmentStartDate)
      : null;
    const endDate = assignment.assignmentEndDate
      ? new Date(assignment.assignmentEndDate)
      : null;

    if (endDate && endDate < today) {
      return "danger";
    }

    if (startDate && startDate <= today && (!endDate || endDate >= today)) {
      return "success";
    }

    if (startDate && startDate > today) {
      return "warning";
    }

    return "okänt";
  };

  const getAssignmentStatus = (assignment: EmployeeAssignmentDto): string => {
    const today = new Date();

    const startDate = assignment.assignmentStartDate
      ? new Date(assignment.assignmentStartDate)
      : null;
    const endDate = assignment.assignmentEndDate
      ? new Date(assignment.assignmentEndDate)
      : null;

    if (endDate && endDate < today) {
      return "Avslutat uppdrag";
    }

    if (startDate && startDate <= today && (!endDate || endDate >= today)) {
      return "Nuvarande uppdrag";
    }

    if (startDate && startDate > today) {
      return "Kommande uppdrag";
    }

    return "okänt";
  };
  
  return (
    <Row className="full-width">
      {props.assignments &&
        props.assignments.length > 0 &&
        props.assignments
            .filter((assignment) => assignment.client !== "Saknar uppdrag")
            .map((assignment) => {
          const startDate = assignment.assignmentStartDate
            ? new Date(assignment.assignmentStartDate)
            : null;
          return (
            <Col key={`key_${assignment.id}`}>
              {assignment.client !== "Saknar uppdrag" && (
                <Card key={assignment.id} className="mb-4">
                  <Card.Body>
                    <Card.Title>
                      <h5>{assignment.client}</h5>
                    </Card.Title>
                    
                      <Row>
                        <Col className="vstack">
                          <p>Ort (Mobile): {assignment.officeLocation}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="vstack">
                          <p>
                            Startdatum:{" "}
                            {startDate && startDate.toLocaleDateString()}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="vstack">
                          <p>Slutdatum: {assignmentText(assignment)}</p>
                        </Col>
                      </Row>
                      {assignment.teams && assignment.teams.length > 0 && (
                        <Row>
                          <Col xs={12} className="vstack">
                            <p>
                              {assignment.teams.length === 1
                                ? "Mitt team: "
                                : "Mina team: "}
                              {assignment.teams &&
                                assignment.teams.map((team, index) => (
                                  <React.Fragment key={index}>
                                    {team.teamName}
                                    {index < assignment.teams!.length - 1
                                      ? ", "
                                      : ""}
                                  </React.Fragment>
                                ))}
                            </p>
                          </Col>
                        </Row>
                      )}
                      {assignment &&
                        assignment.assignmentDescription !== "" && (
                          <Row>
                            <Col xs={12} className="vstack">
                              <p>
                                Uppdragsbeskrivning:{" "}
                                {assignment.assignmentDescription}
                              </p>
                            </Col>
                          </Row>
                        )}
                    <Row className="mt-3">
                      <Col className="d-flex justify-content-center">
                        <ButtonSecondary
                          text="Redigera"
                          handleClick={() =>
                            props.editAssignmentHandler(assignment)
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-center">
                    <h3>
                      <Badge bg={getColor(assignment)}>
                        {getAssignmentStatus(assignment)}
                      </Badge>
                    </h3>
                  </Card.Footer>
                </Card>
              )}
            </Col>
          );
        })}
    </Row>
  );
};

export default MyAssignmentsMobile;
