import ProfileEmploymentHiredSince from "./atoms/ProfileEmploymentHiredSince";
import ProfileEmploymentReportsTo from "./atoms/ProfileEmploymentReportsTo";

interface ProfileEmploymentProps {
  hiredSince: string;
  managerId?: string;
  managerDisplayName?: string;
}

const ProfileEmployment: React.FC<ProfileEmploymentProps> = (props) => {
  return (
    <>
      {/* <ProfileSubHeader headerValue="Anställning" /> */}
      <ProfileEmploymentHiredSince hiredSince={props.hiredSince} />
      {props.managerId && (
        <ProfileEmploymentReportsTo
          managerDisplayName={props.managerDisplayName}
          managerId={props.managerId}
        />
      )}
    </>
  );
};

export default ProfileEmployment;
