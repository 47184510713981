import { Col, Row } from "react-bootstrap";
import ProfileEmployment from "../ProfileEmployment/ProfileEmployment";
import NoProfilePhoto from "./atoms/NoProfilePhoto";
import ProfileEmail from "./atoms/ProfileEmail";
import ProfileExperience from "./atoms/ProfileExperience";
import ProfileName from "./atoms/ProfileName";
import ProfileOfficeLocation from "./atoms/ProfileOfficeLocation";
import ProfilePhone from "./atoms/ProfilePhone";
import ProfilePhoto from "./atoms/ProfilePhoto";
import ProfileRole from "./atoms/ProfileRole";

interface ProfileHeaderProps {
  name: string;
  businessPhones?: string[];
  email: string;
  officeLocation: string;
  imageSource: string;
  role?: string;
  experienceSince?: number;
  hiredSince: string;
  managerId?: string;
  managerDisplayName: string;
  setShowEnlargedPhoto: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = (props) => {
  return (
    <>
      {props.imageSource ? (
        <ProfilePhoto
          imageSource={props.imageSource}
          setShowEnlargedPhoto={props.setShowEnlargedPhoto}
        />
      ) : (
        <NoProfilePhoto />
      )}
      <ProfileName name={props.name} />
      {props.role && <ProfileRole role={props.role} />}
      {props.experienceSince && (
        <ProfileExperience experienceSince={props.experienceSince} />
      )}

      <Row className="gx-0">
        {props.officeLocation && (
          <ProfileOfficeLocation officeLocation={props.officeLocation} />
        )}
        <ProfileEmployment
          hiredSince={props.hiredSince}
          managerDisplayName={props.managerDisplayName}
          managerId={props.managerId}
        />
      </Row>
      {props.businessPhones && props.businessPhones.length > 0 && (
        <ProfilePhone businessPhones={props.businessPhones} />
      )}
      <ProfileEmail email={props.email} />
    </>
  );
};

export default ProfileHeader;
