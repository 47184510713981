import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { EmployeeDetailsDto } from "../../../organisms/UserProfile/models/EmployeeDetailsDto";

export interface MyProfileTriviaProps {
  showWordsToolTip: boolean;
  tooltipWordsText: string;
  handleChangeTrivia: (value: string) => void;
  handleFocusTrivia: () => void;
  handleBlurTrivia: () => void;
  maximumTriviaLetterLength: number;
  tooltipRemainingText: string;
  showRemainingLettersToolTip: boolean;
  details: EmployeeDetailsDto;
  tooltipBgColor: string;
}

const MyProfileTrivia: React.FC<MyProfileTriviaProps> = (props) => {
  const tooltipWords = (
    <Tooltip id="tooltip-words" className={`tooltip-bg-crimson`}>
      {props.tooltipWordsText}
    </Tooltip>
  );

  const tooltipRemaining = (
    <Tooltip
      id="tooltip-remaining"
      className={`tooltip-bg-${props.tooltipBgColor}`}
    >
      {props.tooltipRemainingText}
    </Tooltip>
  );

  return (
    <Form.Group className="mb-3" controlId="trivia">
      <h3
        className="mobile-display d-flex justify-content-center mb-4"
        style={{ color: "#701131" }}
      >
        Mer om mig
      </h3>
      <Form.Label className="desktop-display">Mer om mig</Form.Label>
      <OverlayTrigger
        show={props.showWordsToolTip}
        placement="top"
        overlay={tooltipWords}
      >
        <Form.Control
          onChange={(e) => props.handleChangeTrivia(e.target.value)}
          onFocus={props.handleFocusTrivia}
          onBlur={props.handleBlurTrivia}
          value={props.details.trivia ?? ""}
          as="textarea"
          placeholder="Berätta lite om dig själv"
          rows={4}
          maxLength={props.maximumTriviaLetterLength}
        />
      </OverlayTrigger>
      <OverlayTrigger
        show={props.showRemainingLettersToolTip}
        placement="bottom"
        overlay={tooltipRemaining}
      >
        <div></div>
      </OverlayTrigger>
    </Form.Group>
  );
};

export default MyProfileTrivia;
