import { Card, CloseButton, Col, Row } from "react-bootstrap";

export interface AdminActivationInfoTextProps {
  setShowInitialInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminActivationInfoText: React.FC<AdminActivationInfoTextProps> = (
  props
) => {
  return (
    <Card>
      <Card.Body>
        <Row className="d-flex justify-content-between hstack">
          <Col xl={11} lg={11} md={11} sm={11} xs={11}>
            <h6>
              Här kan du avaktivera eller återaktivera dina medarbetare. Perfekt
              om du tillfälligt vill att en medarbetare inte ska dyka upp i
              sökningar. Exempelvis vid längre föräldraledigheter eller
              sjukskrivningar.
            </h6>
          </Col>
          <Col
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className="d-flex justify-content-end"
          >
            <CloseButton onClick={() => props.setShowInitialInfo(false)} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AdminActivationInfoText;
