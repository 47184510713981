import { StarFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EmployeeAssignmentDto } from "../../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import AssignmentTeams from "./AssignmentTeams";

interface ProfileAssignmentClientProps {
  assignments?: EmployeeAssignmentDto[];
  showDetails: boolean;
  customStarColor?: string;
}

const ProfileAssignments = (
  props: ProfileAssignmentClientProps
): React.JSX.Element => {
  const [isPreviousAssignment, setIsPreviousAssignment] =
    useState<boolean>(false);
  const [isUpcomingAssignment, setIsUpcomingAssignment] =
    useState<boolean>(false);
  const [starColor, setStarColor] = useState<string>("#ffd700");

  useEffect(() => {
    if (props.assignments) {
      setIsPreviousAssignment(
        props.assignments.some(
          (x) =>
            !!x.assignmentEndDate &&
            new Date(x.assignmentEndDate).getTime() < Date.now()
        )
      );

      setIsUpcomingAssignment(
        props.assignments.some(
          (x) =>
            x.assignmentStartDate &&
            new Date(x.assignmentStartDate).getTime() > Date.now()
        )
      );
    }

    if (props.customStarColor) {
      setStarColor(props.customStarColor);
    }
  }, [props.assignments]);

  return (
    <>
      {props.assignments &&
        props.assignments.map((assignment) => (
          <Col
            lg={props.assignments!.length > 1 ? 6 : 12}
            className="mt-1 mb-4"
            key={assignment.id}
          >
            <h6 className="fw-bold d-flex justify-content-center align-items-center my-auto mb-3">
              <StarFilled className="me-2" style={{ color: starColor }} />
              {assignment.client}
              <StarFilled className="ms-2" style={{ color: starColor }} />
            </h6>
            <AssignmentText label="Ort" value={assignment.officeLocation} />
            {props.showDetails ? (
              <AssignmentTeams assignment={assignment} />
            ) : null}
            {props.showDetails ? (
              <AssignmentText
                label={isPreviousAssignment ? "Startade" : isUpcomingAssignment ? "Startar" : "Sedan"}
                value={assignment.assignmentStartDate
                  ?.toString()
                  .substring(0, 10)}
              />
            ) : null}
            {props.showDetails && assignment.assignmentEndDate ? (
              <AssignmentText
                label={isPreviousAssignment ? "Slutade" : "Slutar"}
                value={assignment.assignmentEndDate
                  ?.toString()
                  .substring(0, 10)}
              />
            ) : null}
            {props.showDetails && assignment.assignmentDescription ? (
              <AssignmentText
                label="Uppdrag"
                value={assignment.assignmentDescription}
              />
            ) : null}
          </Col>
        ))}
    </>
  );
};

function AssignmentText(props: {
  label: string;
  value: string | undefined;
}): React.JSX.Element {
  return (
    <Row className="mb-1">
      <label className="col-6 text-end form-label">{props.label}:</label>
      <p className="col-6 my-0 gx-0">{props.value}</p>
    </Row>
  );
}

export default ProfileAssignments;
