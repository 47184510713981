import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Col,
  Row,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import ConsidLogoWhite from "../../atoms/ConsidLogoWhite";
import "./Header.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IHeaderProps } from "../../../models/interfaces/IHeaderProps";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  resetStore,
  updateIsMyProfileActive,
  updateAdminMessages,
  updateShowInfo,
} from "../../../redux/slices/userSlice";
import { updateMissingInfo } from "../../../services/EmployeeService";
import { UpdateMissingInfoDto } from "../../../models/Dto/UpdateMissingInfoDto";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminMessageDto } from "../../../models/Dto/AdminMessageDto";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import {
  doesSkillExist,
  getAnAdminsMessages,
  postNewSkill,
  removeSkillMessage,
} from "../../../services/SkillService";
import { StoryboardSkillAdd } from "../../../models/Dto/StoryBoardSkillAdd";

const Header: React.FC<IHeaderProps> = (props) => {
  const adminValue = process.env.REACT_APP_ADMIN_ROLE;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleLogout = () => {
    dispatch(resetStore());
    props.msalInstance.logout();
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [lightBulbColor, setLightbulbColor] = useState<string>("green");
  const [listOfMessages, setListOfMessages] = useState<AdminMessageDto[]>([]);
  const [showMails, setShowMails] = useState<boolean>(false);
  const target = useRef(null);

  const userId: string | undefined = useSelector(
    (state: RootState) => state.user.userId
  );

  const showInfo: boolean = useSelector(
    (state: RootState) => state.user.showInfo
  );

  const isMyProfileActive: boolean = useSelector(
    (state: RootState) => state.user.isMyProfileActive
  );

  const adminMessages: AdminMessageDto[] = useSelector(
    (state: RootState) => state.user.adminMessages
  );

  const initOffice: string = useSelector(
    (state: RootState) => state.user.initialOfficeLocation
  );

  const appRoles: string[] = useSelector(
    (state: RootState) => state.user.appRoles
  );

  const isUserAdmin = () =>
      appRoles && appRoles.length > 0 && appRoles.some((x) => x === adminValue);
  
  useEffect(() => {
    const getMessages = async (id: string) => {
      if (isUserAdmin()) {
        const response = await getAnAdminsMessages();
        if (response.length > 0) {
          dispatch(updateAdminMessages(response));
        }
      }
    };

    getMessages(userId);
  }, []);

  useEffect(() => {
    if (adminMessages.length > 0) {
      setLightbulbColor("yellow");
      setListOfMessages(adminMessages);
    } else {
      setLightbulbColor("grey");
      setShowMails(false);
    }
  }, [adminMessages]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRedirect = (path: string, state?: any) => {
    setIsExpanded(false);
    if (state) {
      redirect(path, state);
    } else {
      redirect(path);
    }
  };

  const dispatchMyProfileFalse = () => {
    dispatch(updateIsMyProfileActive(false));
  };

  const handleChangeShow = async () => {
    try {
      const change: UpdateMissingInfoDto = {
        userId: userId,
        show: !showInfo,
      };

      const response = await updateMissingInfo(change);
      if (response) {
        dispatch(updateShowInfo(change.show));
      } else {
        dispatch(updateShowInfo(!change.show));
      }
    } catch (error) {
      dispatch(updateShowInfo(showInfo));
      console.error("Error updating the showResponse:", error);
    }
  };

  const handleNavItemClick = () => {
    setIsExpanded(false);
  };

  const handleLightbulbClick = () => {
    if (listOfMessages.length > 0) {
      setShowMails((prev) => !prev);
    }
  };

  const acceptSkillRequest = async (skillName: string) => {
    const response = await doesSkillExist(skillName);
    if (!response) {
      const story: StoryboardSkillAdd = {
        name: skillName,
        officeLocation: initOffice,
      };
      const result = await postNewSkill(story);
      if (result) {
        const remove = await removeSkillMessage(skillName);
        if (remove > 0) {
          const resultMessages = await getAnAdminsMessages();
          dispatch(updateAdminMessages(resultMessages));
          setListOfMessages(resultMessages);
        }
      }
    }
  };

  const declineSkillRequest = async (skillName: string) => {
    const response = await removeSkillMessage(skillName);
    if (response > 0) {
      const resultMessages = await getAnAdminsMessages();
      dispatch(updateAdminMessages(resultMessages));
      setListOfMessages(resultMessages);
    }
  };
  
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Kompetensförfrågningar</Popover.Header>
      <Popover.Body>
        {listOfMessages && listOfMessages.length > 0 ? (
          listOfMessages.map((message) => (
            <Row key={`id_${message.skillName}`} className="hstack mb-3">
              <Col>
                <p>{message.completeMessage}</p>
              </Col>
              <Col className="d-flex justify-content-around">
                <ButtonCancel
                  text="Avslå"
                  handleClick={() => declineSkillRequest(message.skillName)}
                />
                <ButtonPrimary
                  text="Godkänn"
                  handleClick={() => acceptSkillRequest(message.skillName)}
                />
              </Col>
            </Row>
          ))
        ) : (
          <Row>
            <Col className="d-flex justify-content-center align-items-center my-auto mx-auto text-center">
              <h4>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden"></span>
                </Spinner>
              </h4>
            </Col>
          </Row>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="dark"
      expanded={isExpanded}
      className="bg-body-tertiary py-0"
      onToggle={() => setIsExpanded(!isExpanded)}
      sticky="top"
    >
      <Container fluid className="header-styling py-2">
        <Navbar.Brand
          onClick={() => {
            handleRedirect("/");
            dispatchMyProfileFalse();
          }}
          className="header-logo"
        >
          <ConsidLogoWhite />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mb-2" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "active nav-link text-white" : "nav-link text-white"
              }
              onClick={() => {
                handleNavItemClick();
                dispatchMyProfileFalse();
              }}
            >
              Storyboard
            </NavLink>
            <NavLink
              to="/Browse"
              className={({ isActive }) =>
                isActive ? "active nav-link text-white" : "nav-link text-white"
              }
              onClick={() => {
                handleNavItemClick();
                dispatchMyProfileFalse();
                sessionStorage.setItem("filters", "");
                sessionStorage.setItem("searchType", "0");
                sessionStorage.setItem("pagination", "");
                sessionStorage.setItem("searchValue", "");
                sessionStorage.setItem("noAssignmentFilter", "false");
                if (location.pathname === "/Browse") window.location.reload();
              }}
            >
              Sökfunktion
            </NavLink>
            <NavLink
              to={`/userprofile/${
                props.msalInstance.getActiveAccount()?.localAccountId
              }`}
              className={({ isActive }) =>
                isMyProfileActive
                  ? "active nav-link text-white"
                  : "nav-link text-white"
              }
              onClick={() => {
                handleNavItemClick();
                dispatch(updateIsMyProfileActive(true));
              }}
            >
              Min Profil
            </NavLink>
            {isUserAdmin() && (
              <NavLink
                to="/admin"
                className={({ isActive }) =>
                  isActive
                    ? "active nav-link text-white"
                    : "nav-link text-white"
                }
                onClick={() => {
                  handleNavItemClick();
                  dispatchMyProfileFalse();
                }}
              >
                Admin
              </NavLink>
            )}
          </Nav>
          <hr className="hr-bar" />
          <Nav className="ms-auto">
            {isUserAdmin() && (
              <>
                <OverlayTrigger
                  target={target.current!}
                  placement="bottom-start"
                  overlay={popover}
                  show={showMails}
                  rootClose
                >
                  <h5 className="my-auto">
                    <FontAwesomeIcon
                      icon={faLightbulb}
                      ref={target}
                      className={`p-2 ms-2 ${
                        listOfMessages.length > 0
                          ? "lightbulb-pulse"
                          : "noMessages"
                      }`}
                      style={{ color: lightBulbColor, borderRadius: "20px" }}
                      onClick={handleLightbulbClick}
                    />
                  </h5>
                </OverlayTrigger>
              </>
            )}
            <NavDropdown
              className="header-text"
              title={props.msalInstance.getActiveAccount()?.name}
              id="basic-nav-dropdown"
              show={isDropdownOpen}
              onToggle={toggleDropdown}
            >
              <NavDropdown.Item onClick={() => handleRedirect("/myprofile", {replace: true})}>
                Mina detaljer
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleChangeShow()}>
                Visa startinfo: {showInfo ? "På" : "Av"}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
                Logga ut
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
