import { Col, Form, Row } from "react-bootstrap";

export interface ShowMoreSkillsSwitchProps {
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowMoreSkillsSwitch: React.FC<ShowMoreSkillsSwitchProps> = (props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-center w-100 mx-auto mt-2">
        <Form.Switch
          checked={props.showDetails}
          label="Visa alla kompetenser"
          onChange={(e) => props.setShowDetails(e.target.checked)}
        />
      </Col>
    </Row>
  );
};

export default ShowMoreSkillsSwitch;
