import { Col, Row } from "react-bootstrap";
import { SkillDto } from "../../../organisms/UserProfile/models/SkillDto";
import ProfileSkillListItem from "../ProfileSkillListItem";
import "./MoreThanFiveStyler.css";

export interface MoreThanFiveAndShowDesktopProps {
  skills: SkillDto[];
  sortBySkillLevelThenBySkillName: (skills: SkillDto[]) => SkillDto[];
  getSkillNameById: (skillID: number) => string;
}

const MoreThanFiveAndShowDesktop: React.FC<MoreThanFiveAndShowDesktopProps> = (
  props
) => {
  return (
    <Row className="desktop-skills mt-3 d-flex justify-content-center align-items-start hstack g-0">
      <Col>
        {props.skills &&
          props.skills.length > 0 &&
          props
            .sortBySkillLevelThenBySkillName(props.skills)
            .sort((a, b) => b.skillLevel - a.skillLevel)
            .map(
              (skill, index) =>
                index % 2 === 0 && (
                  <ProfileSkillListItem
                    key={index}
                    skill={skill}
                    skillName={props.getSkillNameById(skill.skillId)}
                  />
                )
            )}
      </Col>
      <Col>
        {props.skills &&
          props.skills.length > 0 &&
          props
            .sortBySkillLevelThenBySkillName(props.skills)
            .sort((a, b) => b.skillLevel - a.skillLevel)
            .map(
              (skill, index) =>
                index % 2 !== 0 && (
                  <ProfileSkillListItem
                    key={index}
                    skill={skill}
                    skillName={props.getSkillNameById(skill.skillId)}
                  />
                )
            )}
      </Col>
    </Row>
  );
};

export default MoreThanFiveAndShowDesktop;
