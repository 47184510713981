import { Col, Container, Form, Row } from "react-bootstrap";
import { EmployeeDetailsDto } from "../../organisms/UserProfile/models/EmployeeDetailsDto";
import { useCallback, useEffect, useState } from "react";
import "./MyProfilePage.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EmployeeTotalDto } from "../../organisms/UserProfile/models/EmployeeTotalDto";
import {
  getEmployeeDetails,
  setAssignmentEligibility,
  updateEmployeeDetails,
} from "../../../services/EmployeeService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SkillFromDbDto } from "../../../models/Dto/SkillFromDbDto";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import ModalYesOrNo from "../../molecules/ModalYesOrNo/ModalYesOrNo";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { getAllCurrentAssignments } from "../../../services/AssignmentService";
import { getAllSkills } from "../../../services/SkillService";
import MyProfileHeader from "./atoms/MyProfileHeader";
import MyProfileCurrentAssignmentForm from "./atoms/MyProfileCurrentAssignmentForm";
import Loading from "../../atoms/ReactQueryLoading/Loading";
import Error from "../../atoms/ReactQueryError/ErrorHandler";
import MyAssignments from "./MyAssignments";
import { EmployeeAssignmentDto } from "./interfaces/EmployeeAssignmentDto";
import AssignmentModal from "./AssignmentModal";
import EditAssignmentModal from "./EditAssignmentModal";
import {
  updateIsMyProfileActive,
  updateIsEligibleForAssignments,
} from "../../../redux/slices/userSlice";
import MyAssignmentsMobile from "./MyAssignmentsMobile";
import MyProfileExperience from "./atoms/MyProfileExperience";
import MyProfileSkillSelectorDesktop from "./atoms/MyProfileSkillSelectorDesktop";
import MyProfileSkillSelectorMobile from "./atoms/MyProfileSkillSelectorMobile";
import MyProfileTrivia from "./atoms/MyProfileTrivia";
import AddSkillRequestModal from "../../molecules/AddSkillRequestModal/AddSkillRequestModal";
import { EmployeeSetAssignmentEligibiltyDto } from "../../../models/Dto/EmployeeSetAssignmentEligibilityDto";

const defaultAssignment: EmployeeAssignmentDto = {
  id: 0,
  client: "",
  assignmentStartDate: null,
  assignmentEndDate: null,
  assignmentDescription: "",
  teams: [],
};

export interface TriviaValidator {
  isWordsOK: boolean;
  showRemainingTooltip: boolean;
}

const MyProfilePage: React.FC = () => {
  const queryClient = useQueryClient();
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const officeLocations: string[] = useSelector(
    (state: RootState) => state.user.officeLocations
  );
  const [details, setDetails] = useState<EmployeeDetailsDto>();
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [selectedOffice, setSelectedOffice] = useState<string>("");
  const [selectedAssignmentToEdit, SetSelectedAssignmentToEdit] =
    useState<EmployeeAssignmentDto>(defaultAssignment);
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const [editShow, setEditShow] = useState<boolean>(false);
  const redirect = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [addNew, setAddNew] = useState<boolean>(false);
  const [isInputValid, setIsInputValid] = useState<boolean>(false);
  const [isAssignmentSelected, setIsAssignmentSelected] =
    useState<boolean>(false);
  const [showTeamErrorModal, setShowTeamErrorModal] = useState<boolean>(false);
  const [showRemainingLettersToolTip, setShowRemainingLettersTooltip] =
    useState<boolean>(false);
  const [showWordsToolTip, setShowWordsTooltip] = useState<boolean>(false);
  const [tooltipRemainingText, setTooltipRemainingText] = useState<string>("");
  const [tooltipWordsText, setTooltipWordsText] = useState<string>("");
  const [tooltipBgColor, setTooltipBgColor] = useState<string>("");
  const [isNoClientChecked, setIsNoClientChecked] = useState<boolean>(false);
  const [firstTimeAssignment, setFirstTimeAssignment] =
    useState<boolean>(false);
  const skillMissingValue: string = "Kompetensnivån måste vara mellan 1 och 5";
  const skillDuplicate: string = "En kompetens är vald flera gånger";
  const triviaError: string = `Ett ord i "Mer om mig" överstiger 30 tecken`;
  const [errorText, setErrorText] = useState<string[]>([]);
  const [remainingFocus, setRemainingFocus] = useState<boolean>(false);
  const maximumTriviaLetterLength: number = 450;
  const maximumTriviaLetterWarning: number = 350;
  const [showSkillRequest, setShowSkillRequest] = useState<boolean>(false);
  const [selectedOfficeLocationForEdit, setSelectedOfficeLocationForEdit] =
    useState<string>(officeLocation ?? "");
  const [isAssignmentExisting, setIsAssignmentExisting] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const [isAnAssignmentActive, setIsAnAssignmentActive] =
    useState<boolean>(false);
  const maximumNumberOfEmployeeTeamsInAnAssignment: number = 5;
  const [showLocalTeamErrorModal, setShowLocalTeamErrorModal] =
    useState<boolean>(false);

  const { data, isLoading, error } = useQuery<EmployeeTotalDto, Error>({
    queryFn: () => getEmployeeDetails(userId),
    queryKey: ["mydetails", { userId }],
  });

  const { data: allCurrentAssignments } = useQuery<string[]>({
    queryFn: () => getAllCurrentAssignments(selectedOffice),
    queryKey: ["currentAssignments", selectedOffice],
  });

  const { data: allSkills } = useQuery<SkillFromDbDto[], Error>({
    queryFn: () => getAllSkills(),
    queryKey: ["skills"],
  });

  const isThereADuplicate = useCallback(() => {
    if (!details) return;
    let result: boolean[] = [];
    details.skills.forEach((x) => {
      if (
        details.skills.filter(
          (y) => y.skillId === x.skillId && y.skillId !== -1
        ).length > 1
      ) {
        result.push(true);
      }
    });
    return result.some((x) => x === true);
  }, [details]);

  const isThereAnInvalidSkillLevel = useCallback(() => {
    if (!details) return;
    return details.skills.some(
      (skill) => !skill || skill.skillLevel < 1 || skill.skillLevel > 5
    );
  }, [details]);

  const handleAddSkill = useCallback(() => {
    if (!details) return;
    let temp = { ...details };
    temp.skills.push({ id: 0, skillLevel: 1, skillId: -1 });
    setDetails(temp);
  }, [details]);

  useEffect(() => {
    if (data) {
      setDetails(data);
      if (data.isEligibleForAssignments !== undefined) {
        dispatch(updateIsEligibleForAssignments(data.isEligibleForAssignments));
      }
    }
  }, [data, setDetails, dispatch]);

  useEffect(() => {
    const updateCheckbox = async () => {
      const user: EmployeeSetAssignmentEligibiltyDto = {
        employeeId: userId,
      };
      await setAssignmentEligibility(user);
    };

    if (
      details?.isEligibleForAssignments === false &&
      details?.assignments &&
      details?.assignments?.length > 0 &&
      details?.assignments?.some(
        (x) =>
          x.client !== "Saknar uppdrag" ||
          details.assignments?.some((x) => x.client === "Saknar uppdrag")
      )
    ) {
      updateCheckbox();
    }
  }, [details, userId]);

  useEffect(() => {
    if (details?.assignments?.some((x) => x.client === "Saknar uppdrag")) {
      setSelectedItem("");
      setIsNoClientChecked(true);
    } else setIsNoClientChecked(false);
  }, [details?.assignments]);

  useEffect(() => {
    if (officeLocations.length > 0 && !selectedOffice && officeLocation) {
      setSelectedOffice(officeLocation);
    }
  }, []);

  /* useEffect(() => {
    if (allCurrentAssignments?.length === 0) {
      setAddNew(true);
    }
  }, [allCurrentAssignments]); */

  useEffect(() => {
    // Add more checks here to validate and set state
    if (!details || !details.skills) return;
    if (details.skills.length === 0) {
      handleAddSkill();
    }
    const response = IsTriviaInputInvalid(details.trivia ?? "");
    setShowWordsTooltip(!response.isWordsOK);
    setShowRemainingLettersTooltip(
      response.showRemainingTooltip && remainingFocus
    );

    const validateInput = () => {
      let errors: string[] = [];

      if (isThereADuplicate()) {
        errors.push(skillDuplicate);
      } else {
        errors = errors.filter((e) => e !== skillDuplicate);
      }
      if (isThereAnInvalidSkillLevel()) {
        errors.push(skillMissingValue);
      } else {
        errors = errors.filter((e) => e !== skillMissingValue);
      }
      if (!response.isWordsOK) {
        errors.push(triviaError);
      } else {
        errors = errors.filter((e) => e !== triviaError);
      }

      setErrorText(errors);
      return errors.length === 0;
    };

    validateInput();

    if (
      isThereADuplicate() ||
      isThereAnInvalidSkillLevel() ||
      !response.isWordsOK
    ) {
      setIsInputValid(false);
      return;
    }
    setIsInputValid(true);
  }, [details, isThereADuplicate, handleAddSkill, remainingFocus]);

  useEffect(() => {
    const isUndefinedOrEmpty =
      selectedItem === "" || selectedItem === undefined;
    const isSpecialCases =
      selectedItem === "Saknar uppdrag" ||
      selectedItem === "Välj uppdragsgivare" ||
      selectedItem === "Annat uppdrag";

    setIsAssignmentSelected(!isSpecialCases && !isUndefinedOrEmpty);
    setShowAssignmentModal(
      !isUndefinedOrEmpty &&
        !showLocalTeamErrorModal &&
        selectedItem !== "Saknar uppdrag" &&
        selectedItem !== "Annat uppdrag"
    );
  }, [selectedItem, showLocalTeamErrorModal]);

  useEffect(() => {
    dispatch(updateIsMyProfileActive(true));
  }, []);

  const handleSelectChangeClient = (
    selectedClients: EmployeeAssignmentDto[]
  ) => {
    if (details) {
      let tempDetails = { ...details };
      tempDetails.assignments = [...selectedClients];
      setDetails(tempDetails);
    }
  };

  const handleSave = async () => {
    if (!details) return;

    let temp: EmployeeDetailsDto = { ...details };
    temp.skills = temp.skills.filter((skill) => skill.skillId !== -1);
    const result = await updateEmployeeDetails(temp);
    if (result) {
      //setShowSaveModal(false);
      redirect(`/userprofile/${userId}`, {replace: true});
    } else {
      alert("Sparades inte");
    }
  };

  const handleRemove = (removeIndex: number) => {
    if (!details) return;
    let temp = { ...details };
    temp.skills.splice(removeIndex, 1);
    setDetails(temp);
  };

  const handleChange = (newValue: number, changeIndex: number) => {
    if (!details) return;
    let temp = { ...details };
    temp.skills[changeIndex].skillId = newValue;
    setDetails(temp);
  };

  const handleChangeTrivia = (value: string) => {
    if (!details) return;
    let temp = { ...details };
    temp.trivia = value;
    setDetails(temp);
  };

  const tooltipTextHandler = (letters: number) => {
    let text: string;
    let lettersLeft = maximumTriviaLetterLength - letters;
    text = `${lettersLeft} tecken kvar...`;
    return text;
  };

  const isWordLengthOk = (value: string): boolean => {
    const words = value.split(/\s+/);
    const maxWordLength = 30;
    if (words.some((word) => word.length > maxWordLength)) {
      setTooltipWordsText(
        `Det är inte tillåtet att ange ord över ${maxWordLength} tecken.`
      );
      setTooltipBgColor("crimson");
      return false;
    } else {
      setTooltipWordsText("");
      return true;
    }
  };

  const isTriviaTotalLettersOk = (value: string): boolean => {
    value = value.trimStart();
    if (value.length <= maximumTriviaLetterWarning) {
      return true;
    }
    if (
      value.length > maximumTriviaLetterWarning &&
      value.length < maximumTriviaLetterLength
    ) {
      setTooltipBgColor("darkorange");
      setTooltipRemainingText(tooltipTextHandler(value.length));
      return false;
    }
    if (value.length === maximumTriviaLetterLength) {
      setTooltipBgColor("crimson");
      setTooltipRemainingText("Max antal tecken nådd!");
      return false;
    }
    return true;
  };

  const IsTriviaInputInvalid = (value: string): TriviaValidator => {
    const isWordsOk = isWordLengthOk(value);
    const showTooltip = !isTriviaTotalLettersOk(value);
    const response: TriviaValidator = {
      isWordsOK: isWordsOk,
      showRemainingTooltip: showTooltip,
    };
    return response;
  };

  const handleChangeExperience = (value: number) => {
    if (!details) return;
    let temp = { ...details };
    temp.experienceSince = value;
    setDetails(temp);
  };

  const handleSkillLevelChange = (value: string, index: number) => {
    if (!details) return;
    let result = parseInt(value);
    let temp = { ...details };

    if (Number.isNaN(result)) {
      temp.skills[index].skillLevel = 0;
    } else {
      temp.skills[index].skillLevel = result > 5 ? 5 : result < 1 ? 1 : result;
    }
    setDetails(temp);
  };

  const editAssignmentHandler = (assignment: EmployeeAssignmentDto) => {
    SetSelectedAssignmentToEdit(assignment);
    setAddNew(false);
    setEditShow(true);
    setSelectedOfficeLocationForEdit(assignment.officeLocation!);
  };

  const handleSaveAssignment = () => {
    queryClient.invalidateQueries({ queryKey: ["mydetails"] });
    queryClient.invalidateQueries({ queryKey: ["currentAssignments"] });
  };

  const handleReset = () => {
    queryClient.invalidateQueries({ queryKey: ["mydetails"] });
    setDetails(data);
    setShowResetModal(false);
  };

  const handleFocusTrivia = () => {
    setRemainingFocus(true);
  };

  const handleBlurTrivia = () => {
    setRemainingFocus(false);
  };

  if (isLoading) return <Loading loadingMessage="Laddar Mina detaljer..." />;
  if (error) return <Error queryError={error.message} />;

  if (details)
    return (
      <Container className="update-profile-container">
        <Container>
          <MyProfileHeader />
          <Row className="shadow bg-white rounded p-3 mb-3 vstack">
            <Col className="mb-3">
              <Form.Group controlId="client">
                <MyProfileCurrentAssignmentForm
                  allCurrentAssignments={
                    allCurrentAssignments ? allCurrentAssignments : []
                  }
                  handleSelectChangeClient={handleSelectChangeClient}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  setShowAssignmentModal={setShowAssignmentModal}
                  userAssignments={details.assignments}
                  addNew={addNew}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  setAddNew={setAddNew}
                  showTeamErrorModal={showTeamErrorModal}
                  setShowTeamErrorModal={setShowTeamErrorModal}
                  isChecked={!details.isEligibleForAssignments}
                  setFirstTimeAssignment={setFirstTimeAssignment}
                  isAssignmentExisting={isAssignmentExisting}
                  setIsAssignmentExisting={setIsAssignmentExisting}
                  selectedOffice={selectedOffice}
                  setSelectedOffice={setSelectedOffice}
                  firstTimeAssignment={firstTimeAssignment}
                  setIsNoClientChecked={setIsNoClientChecked}
                  isNoClientChecked={isNoClientChecked}
                  isAnAssignmentActive={isAnAssignmentActive}
                  setIsAnAssignmentActive={setIsAnAssignmentActive}
                />
              </Form.Group>
            </Col>

            <Col className="desktop-display">
              <MyAssignments
                assignments={details.assignments}
                editAssignmentHandler={editAssignmentHandler}
                isChecked={details.isEligibleForAssignments}
                isInputValid={isInputValid}
                selectedOfficeLocationForEdit={selectedOfficeLocationForEdit}
                setSelectedOfficeLocationForEdit={
                  setSelectedOfficeLocationForEdit
                }
                isAnAssignmentActive={isAnAssignmentActive}
                setIsAnAssignmentActive={setIsAnAssignmentActive}
              />
            </Col>
            <Col className="mobile-display d-flex justify-content-center ">
              <MyAssignmentsMobile
                assignments={details.assignments}
                editAssignmentHandler={editAssignmentHandler}
                isChecked={details.isEligibleForAssignments}
                isInputValid={isInputValid}
              />
            </Col>
          </Row>
          <Row className="desktop-display shadow bg-white rounded p-3 mb-3 vstack">
            <MyProfileSkillSelectorDesktop
              allSkills={allSkills}
              details={details}
              handleAddSkill={handleAddSkill}
              handleChange={handleChange}
              handleRemove={handleRemove}
              handleSkillLevelChange={handleSkillLevelChange}
              isThereADuplicate={isThereADuplicate}
              isThereAnInvalidSkillLevel={isThereAnInvalidSkillLevel}
              skillDuplicate={skillDuplicate}
              skillMissingValue={skillMissingValue}
              setShowSkillRequest={setShowSkillRequest}
              showSkillRequest={showSkillRequest}
            />
          </Row>
          <Row className="mobile-display shadow bg-white rounded p-3 mb-3 vstack">
            <MyProfileSkillSelectorMobile
              allSkills={allSkills}
              details={details}
              handleAddSkill={handleAddSkill}
              handleChange={handleChange}
              handleRemove={handleRemove}
              handleSkillLevelChange={handleSkillLevelChange}
              isThereADuplicate={isThereADuplicate}
              isThereAnInvalidSkillLevel={isThereAnInvalidSkillLevel}
              skillDuplicate={skillDuplicate}
              skillMissingValue={skillMissingValue}
              setShowSkillRequest={setShowSkillRequest}
              showSkillRequest={showSkillRequest}
            />
          </Row>
          <Row className="shadow bg-white rounded p-3 mb-3 vstack">
            <MyProfileExperience
              details={details}
              handleChangeExperience={handleChangeExperience}
            />
          </Row>
          <Row className="shadow bg-white rounded p-3 mb-3 vstack">
            <MyProfileTrivia
              handleBlurTrivia={handleBlurTrivia}
              handleChangeTrivia={handleChangeTrivia}
              handleFocusTrivia={handleFocusTrivia}
              maximumTriviaLetterLength={maximumTriviaLetterLength}
              showRemainingLettersToolTip={showRemainingLettersToolTip}
              showWordsToolTip={showWordsToolTip}
              tooltipRemainingText={tooltipRemainingText}
              tooltipWordsText={tooltipWordsText}
              details={details}
              tooltipBgColor={tooltipBgColor}
            />
          </Row>
          <Row className="button-row d-flex justify-content-end mt-4 mx-0">
            <ButtonCancel
              className="w-auto me-4 mb-3"
              handleClick={() => setShowResetModal(true)}
              text="Återställ"
            />
            {!isInputValid && (
              <ButtonPrimary
                className="w-auto mb-3"
                handleClick={handleSave}
                text="Spara"
                disabled={!isInputValid}
                tooltipText={errorText}
              />
            )}
            {isInputValid && (
              <ButtonPrimary
                className="w-auto mb-3"
                handleClick={handleSave}
                text="Spara"
                disabled={!isInputValid}
              />
            )}
            <ModalYesOrNo
              show={showResetModal}
              text="Är du säker på att du vill ångra dina ändringar?"
              handleClickNo={() => setShowResetModal(false)}
              handleClickYes={handleReset}
              onHide={() => setShowResetModal(false)}
            />
            <AssignmentModal
              show={showAssignmentModal}
              //onHide={() => setShowAssignmentModal(false)}
              text={`Nytt kunduppdrag: `}
              handleClickNo={() => setShowAssignmentModal(false)}
              handleClickYes={handleSaveAssignment}
              assignments={details.assignments}
              setShowAssignmentModal={setShowAssignmentModal}
              details={details}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setAddNew={setAddNew}
              setInputValue={setInputValue}
              isAssignmentSelected={isAssignmentSelected}
              setIsAssignmentSelected={setIsAssignmentSelected}
              showTeamErrorModal={showTeamErrorModal}
              setShowTeamErrorModal={setShowTeamErrorModal}
              inputValue={inputValue}
              firstTimeAssignment={firstTimeAssignment}
              setFirstTimeAssignment={setFirstTimeAssignment}
              isAssignmentExisting={isAssignmentExisting}
              setIsAssignmentExisting={setIsAssignmentExisting}
              selectedOffice={selectedOffice}
              setSelectedOffice={setSelectedOffice}
              maximumNumberOfEmployeeTeamsInAnAssignment={
                maximumNumberOfEmployeeTeamsInAnAssignment
              }
              showLocalTeamErrorModal={showLocalTeamErrorModal}
              setShowLocalTeamErrorModal={setShowLocalTeamErrorModal}
            />
            <EditAssignmentModal
              editShow={editShow}
              inputValue={inputValue}
              setEditShow={setEditShow}
              handleClickYes={() => setEditShow(false)}
              handleClickNo={() => setEditShow(false)}
              assignment={selectedAssignmentToEdit}
              onHide={() => setEditShow(false)}
              setAddNew={setAddNew}
              setSelectedItem={setSelectedItem}
              details={details}
              selectedOfficeLocationForEdit={selectedOfficeLocationForEdit}
              setSelectedOfficeLocationForEdit={
                setSelectedOfficeLocationForEdit
              }
              maximumNumberOfEmployeeTeamsInAnAssignment={
                maximumNumberOfEmployeeTeamsInAnAssignment
              }
            />
            <AddSkillRequestModal
              showSkillRequest={showSkillRequest}
              setShowSkillRequest={setShowSkillRequest}
            />
          </Row>
        </Container>
      </Container>
    );
  else return <Loading loadingMessage="Laddar mina detaljer..." />;
};

export default MyProfilePage;
