import {
  Col,
  Collapse,
  Form,
  FormCheck,
  FormLabel,
  Offcanvas,
  Row,
} from "react-bootstrap";
import ManagerTemplate from "../SearchTemplates/atoms/ManagerTemplate";
import AssignmentTemplate from "../SearchTemplates/atoms/AssignmentTemplate";
import SkillTemplate from "../SearchTemplates/atoms/SkillTemplate";
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { SearchType } from "../../../models/Enums/SearchType";
import { BrowseEmployeeDto } from "../../../models/Dto/EmployeeSummaryDto";
import { useQueryClient } from "@tanstack/react-query";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import JobTitleTemplate from "../SearchTemplates/atoms/JobTitleTemplate";
import TeamTemplate from "../SearchTemplates/atoms/TeamTemplate";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";

export interface SearchOffcanvasProps {
  showOffcanvas: boolean;
  activeSearchArea: string;
  searchType: SearchType;
  selectedManager: string;
  showFilterButton: boolean;
  hideFilterButton: boolean;
  isShowOnlyFreeEmployeesChecked: boolean;
  selectedJobTitle: string;
  selectedAssignment: string;
  selectedSkillId: number;
  selectedTeam: number;
  showMonthBar: boolean;
  manualDate?: string;
  selectedOption: string;
  showManualDateInput: boolean;
  expirationDate: string;
  triggerReRender: boolean;
  setTriggerReRender: React.Dispatch<SetStateAction<boolean>>;
  setSelectedSkillId: React.Dispatch<SetStateAction<number>>;
  setSelectedJobTitle: React.Dispatch<SetStateAction<string>>;
  setSelectedAssignment: React.Dispatch<SetStateAction<string>>;
  setSelectedTeam: React.Dispatch<SetStateAction<number>>;
  setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>;
  resetTemplates: () => void;
  setHideFilterButton: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilterButton: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedManager: React.Dispatch<SetStateAction<string>>;
  setSearchValue: React.Dispatch<SetStateAction<string>>;
  setSearchType: React.Dispatch<SetStateAction<SearchType>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
  setIsShowOnlyFreeEmployeesChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowMonthBar: React.Dispatch<React.SetStateAction<boolean>>;
  setManualDate: React.Dispatch<SetStateAction<string | undefined>>;
  setSelectedOption: React.Dispatch<SetStateAction<string>>;
  setShowManualDateInput: React.Dispatch<React.SetStateAction<boolean>>;
  setExpirationDate: React.Dispatch<SetStateAction<string>>;
}

const SearchOffcanvas: React.FC<SearchOffcanvasProps> = (props) => {
  const queryClient = useQueryClient();
  const [showDateWarning, setShowDateWarning] = useState<boolean>(false);
  const { setSearchInfo, setSearchType } = props;
  const options = ["1", "3", "6", "Välj datum"];

  const handleTemplateChange = useCallback(
    (template: number) => {
      if (template === SearchType.FreeSearch) {
        queryClient.invalidateQueries({ queryKey: ["employees"] });
      }
      if (
        template === SearchType.AssignmentSearch ||
        template === SearchType.TeamSearch
      ) {
        props.setIsShowOnlyFreeEmployeesChecked(false);
      }
      setSearchInfo("");
      setSearchType(template);
      sessionStorage.setItem("filters", "");
    },
    [setSearchInfo, setSearchType, queryClient]
  );

  useEffect(() => {
    if (props.manualDate !== undefined) {
      const date = new Date(props.manualDate);
      setSessionStorageExpirationFilter(date);
    }
  }, [props.manualDate]);

  useEffect(() => {
    if (props.manualDate !== undefined) {
      const date = new Date(props.manualDate);
      setSessionStorageExpirationFilter(date);
    }

    if (props.manualDate) {
      const today = new Date();
      const todaystring = today.toISOString().substring(0, 10);

      if (todaystring === props.expirationDate) {
        setShowDateWarning(true);
      } else {
        setShowDateWarning(false);
      }
    }
  }, [props.expirationDate]);

  useEffect(() => {
    if (!props.showMonthBar) props.setManualDate(undefined);
    props.setSelectedOption("");
  }, [props.showMonthBar]);

  const setSessionStorageNoAssignmentFilter = (value: boolean) => {
    sessionStorage.setItem("noAssignmentFilter", JSON.stringify(value));
  };

  const setSessionStorageExpirationFilter = (selectedDate: Date) => {
    sessionStorage.setItem("expirationFilter", JSON.stringify(selectedDate));
    if (selectedDate > new Date()) {
      queryClient.invalidateQueries({ queryKey: ["employees"] });
    }
  };

  const getFilterLabel = (value: number): string => {
    switch (value) {
      case 4:
        return "Vald chef";
      case 3:
        return "Vald uppdragsgivare";
      case 1:
        return "Vald kompetens";
      case 2:
        return "Vald roll";
      case 5:
        return "Valt team";
      default:
        return "";
    }
  };

  const handleHide = () => {
    props.setShowOffcanvas(false);
    props.setHideFilterButton(false);
    props.setShowFilterButton(true);
  };

  const handleCheckChangeShowOnly = () => {
    const newValue = !props.isShowOnlyFreeEmployeesChecked;
    props.setIsShowOnlyFreeEmployeesChecked(newValue);
    setSessionStorageNoAssignmentFilter(newValue);
  };

  const handleCheckChangeShowExpiring = () => {
    const newValue = !props.showMonthBar;
    props.setShowMonthBar(newValue);
    props.setExpirationDate("");
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = ("0" + (tomorrow.getMonth() + 1)).slice(-2);
    const day = ("0" + tomorrow.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedValue = e.target.value;
    props.setSelectedOption(e.target.value);
    const response = getDate(selectedValue);
    setSessionStorageExpirationFilter(response);
    const convertedDate = response.toISOString().substring(0, 10);
    if (response > new Date()) {
      props.setExpirationDate(convertedDate);
    }
  };
  const minDate = getTomorrowDate();

  const getDate = (selectedValue: string): Date => {
    const currentDate = new Date();
    switch (selectedValue) {
      case "1":
        props.setShowManualDateInput(false);
        props.setManualDate(selectedValue);
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case "3":
        props.setShowManualDateInput(false);
        props.setManualDate(selectedValue);
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case "6":
        props.setShowManualDateInput(false);
        props.setManualDate(selectedValue);
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case "Välj datum":
        props.setShowManualDateInput(true);
        props.setExpirationDate("");
        break;
      default:
        break;
    }
    return currentDate;
  };

  const dateHandler = (value: string) => {
    props.setManualDate(value);
    props.setExpirationDate(value.substring(0, 10));
  };

  return (
    <Offcanvas
      show={props.showOffcanvas}
      placement="end"
      scroll={true}
      backdrop={false}
      onHide={() => {
        handleHide();
      }}
    >
      <Offcanvas.Header
        closeButton
        onClick={() => {
          handleHide();
        }}
      >
        <Offcanvas.Title>Sökfilter</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Form.Group>
            <FormLabel style={{ fontWeight: "bold" }}>
              {props.searchType === 0 ? "Välj ett sökfilter" : `Filterval`}
            </FormLabel>
            <Form.Select
              onChange={(e) => handleTemplateChange(+e.target.value)}
              value={props.searchType}
              className="wrapper w-100"
            >
              <option disabled value={0}>
                Välj ett sökfilter...
              </option>
              <option value={4}>Närmsta chef</option>
              <option value={3}>Nuvarande uppdragsgivare</option>
              <option value={1}>Kompetens</option>
              <option value={2}>Roll</option>
              <option value={5}>Team</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {props.searchType !== SearchType.FreeSearch && (
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-sm-0">
            <FormLabel className="mt-3" style={{ fontWeight: "bold" }}>
              {getFilterLabel(props.searchType)}
            </FormLabel>
            {props.searchType === SearchType.ManagerSearch && (
              <ManagerTemplate
                activeSearchArea={props.activeSearchArea}
                selectedManager={props.selectedManager}
                setSelectedManager={props.setSelectedManager}
                setSearchResults={props.setSearchResults}
                setSearchInfo={props.setSearchInfo}
                searchType={props.searchType}
              />
            )}
            {props.searchType === SearchType.AssignmentSearch && (
              <AssignmentTemplate
                activeSearchArea={props.activeSearchArea}
                setSearchInfo={props.setSearchInfo}
                setSearchResults={props.setSearchResults}
                searchType={props.searchType}
                selectedAssignment={props.selectedAssignment}
                setSelectedAssignment={props.setSelectedAssignment}
              />
            )}
            {props.searchType === SearchType.TeamSearch && (
              <TeamTemplate
                activeSearchArea={props.activeSearchArea}
                searchType={props.searchType}
                selectedTeam={props.selectedTeam}
                setSearchInfo={props.setSearchInfo}
                setSelectedTeam={props.setSelectedTeam}
              />
            )}
            {props.searchType === SearchType.SkillSearch && (
              <SkillTemplate
                activeSearchArea={props.activeSearchArea}
                setSearchInfo={props.setSearchInfo}
                setSearchResults={props.setSearchResults}
                searchType={props.searchType}
                selectedSkillId={props.selectedSkillId}
                setSelectedSkillId={props.setSelectedSkillId}
              />
            )}
            {props.searchType === SearchType.JobTitleSearch && (
              <JobTitleTemplate
                triggerReRender={props.triggerReRender}
                activeSearchArea={props.activeSearchArea}
                setSearchInfo={props.setSearchInfo}
                setSearchResults={props.setSearchResults}
                selectedJobTitle={props.selectedJobTitle}
                setSelectedJobTitle={props.setSelectedJobTitle}
                searchType={props.searchType}
                setTriggerReRender={props.setTriggerReRender}
              />
            )}
          </Col>
        )}
        <Row>
          <Form.Group>
            <FormCheck
              className="mt-3"
              label={"Visa endast de med utgående uppdrag"}
              style={{ fontWeight: "bold" }}
              checked={props.showMonthBar}
              onChange={handleCheckChangeShowExpiring}
            />
          </Form.Group>
        </Row>
        {props.showMonthBar && (
          <Row>
            <Collapse in={props.showMonthBar}>
              <Form>
                <Form.Group controlId="switchSelector" className="mt-2">
                  <Form.Label>Välj antal månader</Form.Label>
                  <div className="d-flex justify-content-between">
                    {options.map((option, index) => (
                      <Form.Check
                        key={index}
                        type="radio"
                        label={option}
                        name="timeOptions"
                        value={option}
                        checked={props.selectedOption === option}
                        onChange={handleChange}
                      />
                    ))}
                  </div>
                </Form.Group>
                <div className="mt-3 hstack">
                  {props.selectedOption !== "" && (
                    <Form.Text className="text-muted">
                      {props.selectedOption !== "Välj datum" &&
                        `Valt alternativ: `}
                    </Form.Text>
                  )}
                  {props.selectedOption === "1" && (
                    <Form.Text className="text-muted ps-2">1 månad</Form.Text>
                  )}
                  {(props.selectedOption === "3" ||
                    props.selectedOption === "6") && (
                    <Form.Text className="text-muted ps-2">
                      {props.selectedOption} månader
                    </Form.Text>
                  )}
                </div>
              </Form>
            </Collapse>
            {props.showManualDateInput && (
              <Collapse in={props.showManualDateInput}>
                <>
                  {showDateWarning && (
                    <Row>
                      <h6 style={{ color: "red" }}>Välj ett datum nedan!</h6>
                    </Row>
                  )}
                  <Row>
                    <Col className="mt-1">
                      <Form.Group>
                        <Form.Control
                          type="date"
                          required
                          value={props.manualDate}
                          min={minDate}
                          onChange={(e) => dateHandler(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Collapse>
            )}
          </Row>
        )}
        {props.searchType !== SearchType.AssignmentSearch &&
          props.searchType !== SearchType.TeamSearch && (
            <Row>
              <Form.Group>
                <FormCheck
                  className="mt-3"
                  label={"Visa endast de utan uppdrag"}
                  style={{ fontWeight: "bold" }}
                  checked={props.isShowOnlyFreeEmployeesChecked}
                  onChange={handleCheckChangeShowOnly}
                />
              </Form.Group>
            </Row>
          )}

        <Row>
          <Col className="d-flex justify-content-around mt-3">
            <ButtonSecondary
              text="Dölj sökfilter <<"
              handleClick={handleHide}
            />
            <ButtonCancel
              text="Rensa fält"
              handleClick={props.resetTemplates}
            />
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SearchOffcanvas;
