import { Form } from "react-bootstrap";
import "../AdminAccordionSkills.css";

interface RemoveSkillFormProps {
  showUpdateOkButton: boolean;
  updateValue: string;
  setUpdateValue: React.Dispatch<React.SetStateAction<string>>;
}

const RemoveSkillForm: React.FC<RemoveSkillFormProps> = (props) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>
          <h5 className="page-header">Ta bort / Ändra kompetens</h5>
        </Form.Label>
        <Form.Control
          type="text"
          disabled={!props.showUpdateOkButton}
          value={props.updateValue}
          onChange={(e) => props.setUpdateValue(e.target.value)}
          placeholder="Välj kompetens från listan"
        />
      </Form.Group>
    </Form>
  );
};

export default RemoveSkillForm;
