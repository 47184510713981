import { Card, Col, Row } from "react-bootstrap";
import GetStoryIcon from "../atoms/GetStoryIcon";
import { StoryboardEmployeeDto } from "../../../../models/Dto/StoryboardEmployeeDto";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface StoryCardProps {
  event: string;
  eventDate?: string;
  story: StoryboardEmployeeDto | undefined;
  storyboardType?: number;
  officeLocation: string;
  onStoryClick: (story: StoryboardEmployeeDto, event: string) => void;
}

const StoryCard: React.FC<StoryCardProps> = (props) => {
  const cancelledEmployeeTypeValue = 10;
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );

  const isStoryClickable = () =>
    props.storyboardType === cancelledEmployeeTypeValue;

  const handleClick = () => {
    if (isStoryClickable()) {
      return;
    }

    props.onStoryClick(props.story!, props.event);
  };

  return (
    <Card className="my-2 shadow">
      <Card.Body
        className={`${
          isStoryClickable() ? "disabled-card-style" : "card-style"
        }`}
        onClick={handleClick}
      >
        <Row>
          <Col
            className="d-flex justify-content-start"
            xl={7}
            lg={7}
            md={7}
            sm={7}
            xs={7}
          >
            <p>{props.eventDate ? props.eventDate.substring(0, 10) : ""}</p>
          </Col>
          <Col
            className="d-flex justify-content-end"
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
          >
            {officeLocation !== "Alla kontor" ? (
              <p>
                {props.story ? props.story.displayName : props.officeLocation}
              </p>
            ) : (
              <p>{props.officeLocation}</p>
            )}
          </Col>
        </Row>
        <Row className="px-1">
          <Col
            className="d-flex justify-content-start"
            xl={10}
            lg={10}
            md={10}
            sm={10}
            xs={10}
          >
            <h6>{props.event}</h6>
          </Col>
          {props.storyboardType && (
            <Col className="text-end" xl={2} lg={2} md={2} sm={2} xs={2}>
              <GetStoryIcon
                numberOfType={props.storyboardType}
                event={props.event}
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default StoryCard;
