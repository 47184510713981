import { Container } from "react-bootstrap";
import SearchResults from "../../organisms/SearchResults/SearchResults";
import SearchBar from "../../organisms/SearchBar/SearchBar";
import {
  BrowseEmployeeDto,
  Pagination,
} from "../../../models/Dto/EmployeeSummaryDto";
import { useEffect, useState } from "react";
import { SearchType } from "../../../models/Enums/SearchType";
import { SearchFilters } from "../../../models/interfaces/SearchFilters";
import { updateIsMyProfileActive } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { SkillCountDto } from "../../../models/Dto/SkillCountDto";

const BrowsePage: React.FC = () => {
  const paginationFromSessionStorage = sessionStorage.getItem("pagination");
  const searchTypeFromSessionStorage = parseInt(
    sessionStorage.getItem("searchType") ?? ""
  );
  const [showFilterButton, setShowFilterButton] = useState<boolean>(true);
  const [hideFilterButton, setHideFilterButton] = useState<boolean>(false);
  const [isShowOnlyFreeEmployeesChecked, setIsShowOnlyFreeEmployeesChecked] =
    useState<boolean>(false);
  const [showMonthBar, setShowMonthbar] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<BrowseEmployeeDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>(
    sessionStorage.getItem("searchValue") ?? ""
  );
  const [showOffcanvas, setShowOffcanvas] = useState<boolean>(false);
  const [manualDate, setManualDate] = useState<string | undefined>("");
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [showManualDateInput, setShowManualDateInput] =
    useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<string>("");
  const dispatch = useDispatch();

  const getManager = () => {
    const sessionData = sessionStorage.getItem("filters");
    if (!sessionData) return "";
    const filters = JSON.parse(sessionData) as SearchFilters;
    if (!filters || !filters.manager) return "";
    return filters.manager;
  };

  const [triggerReRender, setTriggerReRender] = useState<boolean>(false);
  const [searchInfo, setSearchInfo] = useState<string>("");
  const [selectedManager, setSelectedManager] = useState<string>(getManager());
  const [didSearchReturnEmployee, setDidSearchReturnEmployee] =
    useState<boolean>(true);
  const [searchType, setSearchType] = useState<SearchType>(
    isNaN(searchTypeFromSessionStorage)
      ? SearchType.FreeSearch
      : searchTypeFromSessionStorage
  );
  const [skills, setSkills] = useState<SkillCountDto[]>([]);

  const initialPagination: Pagination = paginationFromSessionStorage
    ? JSON.parse(paginationFromSessionStorage)
    : {
        currentPage: 1,
        limit: 9,
        totalItems: 0,
        totalPages: 1,
      };
  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  useEffect(() => {
    sessionStorage.setItem("pagination", JSON.stringify(pagination));
  }, [pagination]);

  useEffect(() => {
    sessionStorage.setItem("searchType", searchType.toString());
  }, [searchType]);

  useEffect(() => {
    sessionStorage.setItem("searchValue", searchValue);
  }, [searchValue]);

  useEffect(() => {
    const value = sessionStorage.getItem("noAssignmentFilter");
    if (value !== null && value !== "")
      setIsShowOnlyFreeEmployeesChecked(JSON.parse(value));
  }, []);

  useEffect(() => {
    dispatch(updateIsMyProfileActive(false));
  }, [dispatch]);

  return (
    <Container fluid className="responsive-page-container">
      <SearchBar
        triggerReRender={triggerReRender}
        setTriggerReRender={setTriggerReRender}
        setSkills={setSkills}
        pagination={pagination}
        searchType={searchType}
        searchValue={searchValue}
        didSearchReturnEmployee={didSearchReturnEmployee}
        selectedManager={selectedManager}
        setSelectedManager={setSelectedManager}
        setDidSearchReturnEmployee={setDidSearchReturnEmployee}
        setErrorMessage={setErrorMessage}
        setIsLoading={setIsLoading}
        setPagination={setPagination}
        setSearchResults={setSearchResults}
        setSearchType={setSearchType}
        setSearchValue={setSearchValue}
        setSearchInfo={setSearchInfo}
        showOffcanvas={showOffcanvas}
        setShowOffcanvas={setShowOffcanvas}
        showFilterButton={showFilterButton}
        setShowFilterButton={setShowFilterButton}
        hideFilterButton={hideFilterButton}
        setHideFilterButton={setHideFilterButton}
        isShowOnlyFreeEmployeesChecked={isShowOnlyFreeEmployeesChecked}
        setIsShowOnlyFreeEmployeesChecked={setIsShowOnlyFreeEmployeesChecked}
        searchResults={searchResults}
        showMonthBar={showMonthBar}
        setShowMonthBar={setShowMonthbar}
        manualDate={manualDate}
        setManualDate={setManualDate}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        showManualDateInput={showManualDateInput}
        setShowManualDateInput={setShowManualDateInput}
        expirationDate={expirationDate}
        setExpirationDate={setExpirationDate}
      />

      <SearchResults
        triggerReRender={triggerReRender}
        setTriggerReRender={setTriggerReRender}
        skills={skills ?? []}
        searchValue={searchValue}
        searchResults={searchResults}
        isLoading={isLoading}
        errorMessage={errorMessage}
        pagination={pagination}
        setPagination={setPagination}
        searchType={searchType}
        searchInfo={searchInfo}
        didSearchReturnEmployee={didSearchReturnEmployee}
        selectedManager={selectedManager}
        isShowOnlyFreeEmployeesChecked={isShowOnlyFreeEmployeesChecked}
        expirationDate={expirationDate}
      />
    </Container>
  );
};

export default BrowsePage;
