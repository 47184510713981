import { StoryboardDto } from "../models/Dto/StoryboardDto";
import { StoryboardPaginationDto } from "../models/Dto/StoryboardPaginationDto";
import { StoryboardRequestDto } from "../models/Dto/StoryboardRequestDto";
import { axiosClient } from "../utilities/axiosClient";

const uri: string = process.env.REACT_APP_BASE_URI!;

function createEmptyStoryboardDto(): StoryboardDto {    
    return {storyboardPagination: {} as StoryboardPaginationDto, stories: []} as StoryboardDto;
  }

export const getStoryBoard = async (storyboardRequestDto: StoryboardRequestDto): Promise<StoryboardDto> => {
    const emptyResponse = createEmptyStoryboardDto();   
    if(storyboardRequestDto.officeLocation === "") return emptyResponse;
    try {
        if (!storyboardRequestDto.officeLocation) return emptyResponse;          
        const url = `${uri}api/Storyboard?officeLocation=${encodeURIComponent(storyboardRequestDto.officeLocation)}&requestedPage=${storyboardRequestDto.requestedPage}&limit=${storyboardRequestDto.limit}`;        
        const response = await axiosClient.get<StoryboardDto>(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching storyboard', error);
        throw error;
    }
}