import { Col, Row } from "react-bootstrap";

interface ProfileNameProps {
  name: string;
}

const ProfileName: React.FC<ProfileNameProps> = (props) => {
  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <h2>{props.name}</h2>
      </Col>
    </Row>
  );
};

export default ProfileName;
