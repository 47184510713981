import React from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./SearchbarInput.css";

interface SearchBarInputProps {
  searchValue: string;
  handleChange: (value: string) => void;
  setShowTooltip: React.Dispatch<React.SetStateAction<boolean>>;
  showTooltip: boolean;
  tooltipText: string;
  tooltipBgColor: string;
}

const SearchBarInput: React.FC<SearchBarInputProps> = (props) => {
  const tooltip = (
    <Tooltip id="tooltip" className={`tooltip-bg-${props.tooltipBgColor}`}>
      {props.tooltipText}
    </Tooltip>
  );

  return (
    <Form onSubmit={(e) => e.preventDefault()} className="mb-sm-0">
      <>
        <OverlayTrigger
          show={props.showTooltip}
          placement="bottom"
          overlay={tooltip}
        >
          <Form.Control
            type="text"
            value={props.searchValue}
            placeholder="Sök på namn, roll, uppdrag..."
            className="text-start mt-sm-0 w-100"
            onChange={(e) => props.handleChange(e.target.value)}
            maxLength={40}
          />
        </OverlayTrigger>
      </>
    </Form>
  );
};

export default SearchBarInput;
