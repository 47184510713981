import { Button, Image } from "react-bootstrap";
import "./ContactButton.css";

interface ButtonChatProps {
  email: string;
}

const ButtonChat: React.FC<ButtonChatProps> = (props) => {
  const basePath = process.env.PUBLIC_URL;
  const tenantId = process.env.REACT_APP_CONSID_TENANT;
  const handleClick = (e: any) => {
    window.open(
      `https://teams.microsoft.com/l/chat/0/0?tenantId=${tenantId}&users=${props.email}`,
      "_blank"
    );
    e.preventDefault();
  };

  return (
    <Button
      onClick={handleClick}
      className="contact-button d-flex shadow justify-content-center"
    >
      <Image height={"26px"} src={`${basePath}/teams-logo-white.png`} />
    </Button>
  );
};

export default ButtonChat;
