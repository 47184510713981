import { Col, Row } from "react-bootstrap";
import "./SearchQuery.css";
import { SearchType } from "../../../models/Enums/SearchType";
import { useNavigate } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import { BrowseEmployeeDto } from "../../../models/Dto/EmployeeSummaryDto";
import { SearchFilters } from "../../../models/interfaces/SearchFilters";
import { SkillCountDto } from "../../../models/Dto/SkillCountDto";
import { getEmployeeDetails } from "../../../services/EmployeeService";
import { getTeamById } from "../../../services/AssignmentService";

interface SearchQueryProps {
  searchInfo?: string;
  searchType: SearchType;
  searchResults?: BrowseEmployeeDto[];
  didSearchReturnEmployee: boolean;
  selectedManager: string;
  skills: SkillCountDto[];
  isShowOnlyFreeEmployeesChecked: boolean;
  expirationDate: string;
  triggerReRender: boolean;
  setTriggerReRender: React.Dispatch<SetStateAction<boolean>>;
}

const SearchQuery: React.FC<SearchQueryProps> = (props) => {
  const redirect = useNavigate();
  const [truncatedText, setTruncatedText] = useState("");
  const [actualInfo, setActualInfo] = useState<string>("");
  const [filterInfo, setFilterInfo] = useState<string>("");
  const [searchInfo, setSearchInfo] = useState<string>("");

  const handleRedirectToProfilePage = () => {
    redirect(`/userprofile/${props.selectedManager}`);
  };

  useEffect(() => {
    const getManagerName = async () => {
      if (props.selectedManager === "") setManagerName("");
      const result = await getEmployeeDetails(props.selectedManager);

      setManagerName(result.displayName ?? "");
    };

    getManagerName();
  }, [props.selectedManager]);
  const [managerName, setManagerName] = useState<string>("");

  useEffect(() => {
    props.setTriggerReRender(false);

    const setInitialFilterInfo = async () => {
      const sessionData = sessionStorage.getItem("filters");

      if (!sessionData) {
        setFilterInfo("");
        return;
      }
      const filters = JSON.parse(sessionData) as SearchFilters;

      if (!filters) {
        setFilterInfo("");
        setActualInfo("");
      } else if (filters.manager) {
        const getFilterManager = async (id: string) => {
          const result = await getEmployeeDetails(id);
          setFilterInfo(`${result.displayName}'s anställda` ?? "aa");
        };
        getFilterManager(filters.manager);
      } else if (filters.client)
        setFilterInfo(`Uppdragsgivare: ${filters.client}`);
      else if (filters.skill !== undefined) {
        const id = parseInt(filters.skill);
        if (!id || id < 1) {
          setFilterInfo("hoppsan hejsan något gick fel");
        }

        const skill = props.skills.find((x) => x.skill.id === id);
        if (!skill) {
          setFilterInfo("");
        } else {
          setFilterInfo(`Kompetens: ${skill.skill.name}`);
        }
      } else if (filters.jobTitle) setFilterInfo(`Roll: ${filters.jobTitle}`);
      else if (filters.teamId) {
        const getTeamName = async (id: number) => {
          const result = await getTeamById(id);
          setFilterInfo(`Team: ${result.teamName}` ?? "något gick fel");
        };
        getTeamName(+filters.teamId);
      } else {
      }
    };

    setInitialFilterInfo();
    const setInitialSearchInfo = () => {
      const sessionData = sessionStorage.getItem("searchValue");

      setSearchInfo(sessionData ?? "");
    };

    setInitialSearchInfo();
  }, [props.searchResults, props.skills, props.triggerReRender]);

  useEffect(() => {
    truncateText(actualInfo ?? "", 20);
  }, [props.searchInfo]);

  useEffect(() => {
    let result: string = "";

    if (filterInfo) {
      result += filterInfo;
    }

    if (searchInfo) {
      result += filterInfo
        ? ` + Sökvärde: "${searchInfo}"`
        : `Visar resultat för "${searchInfo}"`;
    }

    if (props.isShowOnlyFreeEmployeesChecked) {
      result += result
        ? " + Endast lediga konsulter"
        : "Visar endast lediga konsulter";
    }

    if (props.expirationDate) {
      const expirationText = `endast konsulter med uppdrag som slutar senast ${props.expirationDate}.`;
      if (props.isShowOnlyFreeEmployeesChecked) {
        result += result
          ? ` eller konsulter med uppdrag som slutar senast ${props.expirationDate}.`
          : `Visar endast lediga konsulter eller ${expirationText}`;
      } else {
        result += result ? ` + ${expirationText}` : `Visar ${expirationText}`;
      }
    }

    setActualInfo(result || "");
  }, [
    filterInfo,
    searchInfo,
    props.isShowOnlyFreeEmployeesChecked,
    props.expirationDate,
  ]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      setTruncatedText(text.substr(0, maxLength) + "...");
    } else {
      setTruncatedText(text);
    }
  };

  return (
    <Row className="d-flex flex-wrap text-start ">
      {props.searchResults && props.searchResults.length === 0 && (
        <h5>Tyvärr inga träffar...</h5>
      )}
      {props.searchType !== SearchType.ManagerSearch && (
        <>
          <Col>
            <h5>{actualInfo}</h5>
          </Col>
        </>
      )}
      {props.searchType === SearchType.ManagerSearch &&
        managerName.length > 0 &&
        actualInfo &&
        actualInfo.length > 1 && (
          <h5 className="text-center">
            <span
              className="manager-item"
              onClick={() => handleRedirectToProfilePage()}
            >
              {managerName}
            </span>
            's anställda
          </h5>
        )}
    </Row>
  );
};

export default SearchQuery;
