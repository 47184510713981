import { useEffect, useState } from "react";
import HomePage from "../HomePage/HomePage";
import WelcomeRedirectPage from "../WelcomeRedirectPage/WelcomeRedirectPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useMsal } from "@azure/msal-react";
import {
  setAppRoles,
  updateInitialOfficeLocation,
  updateIsMyProfileActive,
  updateGivenName,
  updateUserId,
  updateOfficeLocation,
} from "../../../redux/slices/userSlice";
import { getEmployeeDetails } from "../../../services/EmployeeService";
import { getRoles } from "../../../utilities/graph";

const RootPage: React.FC = () => {
  const isSigningIn: boolean = useSelector(
    (state: RootState) => state.user.isSigningIn
  );

  const initialOfficeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.initialOfficeLocation
  );
  const pca = useMsal();

  const dispatch = useDispatch();
  const signedInUserId: string | undefined = useSelector(
    (state: RootState) => state.user.userId
  );
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );

  useEffect(() => {
    if (pca.accounts.length === 0) return;
    let user = pca.accounts[0];
    const getAppRoles = async () => {
      const roles = await getRoles();
      dispatch(setAppRoles(roles ?? []));
    };

    getAppRoles();
    if (user && user.localAccountId) {
      const getUserDetails = async () => {
        const details = await getEmployeeDetails(user.localAccountId);
        dispatch(updateGivenName(details.givenName));
      };
      dispatch(updateUserId(user.localAccountId));
      getUserDetails();
    }
  }, [dispatch, pca.accounts, signedInUserId]);

  useEffect(() => {
    if (signedInUserId) {
      const getInitialOfficeLocation = async () => {
        const details = await getEmployeeDetails(signedInUserId);
        dispatch(updateInitialOfficeLocation(details.officeLocation));
      };
      getInitialOfficeLocation();
    }
  }, [dispatch, signedInUserId]);

  useEffect(() => {
    if (officeLocation === "" && initialOfficeLocation) {
      dispatch(updateOfficeLocation(initialOfficeLocation));
    }
  }, [dispatch, officeLocation, initialOfficeLocation]);

  useEffect(() => {
    dispatch(updateIsMyProfileActive(false));
  }, [dispatch]);

  const [showWelcomeMessage, setShowWelcomeMessage] =
    useState<boolean>(isSigningIn);
  const handleRefresh = () => {
    setShowWelcomeMessage(false);
  };

  return (
    <>
      {showWelcomeMessage ? (
        <WelcomeRedirectPage refresh={handleRefresh} />
      ) : (
        <HomePage />
      )}
    </>
  );
};

export default RootPage;
