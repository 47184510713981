import { Button } from "react-bootstrap";
import "./ButtonSecondary.css";

interface ButtonSecondaryProps {
  text: string;
  isLarge?: boolean;
  handleClick: () => void;
  disabled?: boolean;
  className?: string;
  showIndicator: boolean;
}

const ButtonSecondaryWithIndicator: React.FC<ButtonSecondaryProps> = (
  props
) => {
  return (
    <Button
      onClick={props.handleClick}
      size={!props.isLarge ? "sm" : "lg"}
      className={`consid-secondary-btn py-2 px-3 ${props.className}`}
      disabled={props.disabled}
    >
      {props.showIndicator && (
        <span className="indicator indicator-true"></span>
      )}{" "}
      {/* Indikatorn visas endast när showIndicator är true */}
      {props.text}
    </Button>
  );
};

export default ButtonSecondaryWithIndicator;
