import { Row, Col } from "react-bootstrap";

const AdminHeader: React.FC = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center py-3">
        <h1 className="page-header">Admin</h1>
      </Col>
    </Row>
  );
};

export default AdminHeader;
