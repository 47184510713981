import { Modal } from "react-bootstrap";
import { EmployeeActivationDto } from "../../../../models/Dto/EmployeeActivationDto";
import ButtonCancel from "../../../atoms/ButtonCancel/ButtonCancel";
import { useEffect } from "react";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { useQueryClient } from "@tanstack/react-query";
import { updateEmployeeActivation } from "../../../../services/EmployeeService";

export interface ChangeActivationModalProps {
  setConfirmChange: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployeeToUpdate: EmployeeActivationDto | undefined;
  setSelectedEmployeeToUpdate: React.Dispatch<
    React.SetStateAction<EmployeeActivationDto | undefined>
  >;
  showModal: boolean;
  confirmChange: boolean;
  setResponseText: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ChangeActivationModal: React.FC<ChangeActivationModalProps> = (props) => {
  const queryClient = useQueryClient();
  useEffect(() => {}, []);

  const updateActivation = async () => {
    if (props.selectedEmployeeToUpdate) {
      const response = await updateEmployeeActivation(
        props.selectedEmployeeToUpdate
      );
      props.setConfirmChange(true);
      props.setResponseText(response);
      props.setConfirmChange(false);
      props.setSelectedEmployeeToUpdate(undefined);
      queryClient.invalidateQueries({
        queryKey: ["employees"],
      });
    }
    props.setShowModal(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Modal
      show={props.showModal}
      onHide={() => props.setShowModal(false)}
      centered
    >
      <Modal.Header className="enlarged-header d-flex justify-content-center">
        {props.selectedEmployeeToUpdate && (
          <h2 className="text-center">
            {props.selectedEmployeeToUpdate.isActive
              ? "Aktivera"
              : "Inaktivera"}{" "}
            {props.selectedEmployeeToUpdate.displayName}?
          </h2>
        )}
      </Modal.Header>
      <Modal.Footer className="d-flex justify-content-between">
        {props.selectedEmployeeToUpdate && (
          <ButtonSecondary
            isLarge={true}
            text={
              props.selectedEmployeeToUpdate?.isActive
                ? "Aktivera"
                : "Inaktivera"
            }
            handleClick={() => {
              updateActivation();
            }}
          />
        )}
        <ButtonCancel
          text="Avbryt"
          isLarge={true}
          handleClick={() => props.setShowModal(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeActivationModal;
