import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {} from "../../../services/EmployeeService";
import "./AdminAccordionSkills.css";
import { StoryboardSkillAdd } from "../../../models/Dto/StoryBoardSkillAdd";
import { SkillFromDbDto } from "../../../models/Dto/SkillFromDbDto";
import {
  deleteSkill,
  getAllSkills,
  postNewSkill,
  putSkill,
} from "../../../services/SkillService";
import AdminAccordionSkillRemoveSkillModal from "./atoms/AdminAccordionSkillRemoveSkillModal";
import AccordionSkill from "./molecules/AccordionSkill";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const AdminAccordionSkills: React.FC = () => {
  const [skills, setSkills] = useState<SkillFromDbDto[]>([]);
  const [userValue, setUserValue] = useState<string>("");
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [postResponse, setPostResponse] = useState<boolean>(false);
  const [postUpdateResponse, setPostUpdateResponse] = useState<boolean>(false);
  const [postRemoveResponse, setPostRemoveResponse] = useState<boolean>(false);
  const [responseText, setResponseText] = useState<string>("");
  const [showUpdateOkButton, setShowUpdateOkButton] = useState<boolean>(false);
  const [updateValue, setUpdateValue] = useState<string>("");
  const [selectedId, setSelectedId] = useState<StoryboardSkillAdd>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalHeaderAndDeleteButton, setShowModalHeaderAndDeleteButton] =
    useState<boolean>(true);

  // Denna kan läggas till om vi väljer att knyta kompetenser mot specifika officeLocations

  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );

  // Update list useEffect
  useEffect(() => {
    const fetchSkills = async () => {
      const fetchedSkills = await getAllSkills();
      setSkills(fetchedSkills);
    };
    fetchSkills();
    setReloadList(false);
  }, [reloadList]);

  // Add skill useEffects
  useEffect(() => {
    const timer = setInterval(() => {
      setResponseText("");
      setPostResponse(false);
      clearInterval(timer);
    }, 3000);

    return () => clearInterval(timer);
  }, [postResponse]);

  // Remove skill useEffects
  useEffect(() => {
    setSelectedId(undefined);
    setUpdateValue("");
    setUserValue("");
  }, [postRemoveResponse]);

  // Update skill useEffects
  useEffect(() => {
    setUpdateValue("");
    setSelectedId(undefined);
    setShowUpdateOkButton(false);
    const timer = setInterval(() => {
      setResponseText("");
      setPostUpdateResponse(false);
      clearInterval(timer);
    }, 3000);

    return () => clearInterval(timer);
  }, [postUpdateResponse]);

  const addSkill = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (
      !userValue ||
      skills.some((x) => x.name.toLowerCase() === userValue.toLowerCase())
    ) {
      setResponseText(`Fel! Kompetensen finns redan.`);
      setPostResponse(true);
      return;
    }
    if (userValue.trim() !== "") {
      const skillObject: StoryboardSkillAdd = {
        name: userValue,
        officeLocation: officeLocation ?? "all",
      };
      const response = await postNewSkill(skillObject);
      if (response === true) {
        setReloadList(true);
        setUserValue("");
        setResponseText("Tillagd!");
      } else {
        setResponseText("Fel!");
      }
      setPostResponse(true);
    }
  };

  const updateSkill = async (skillId: number, newName: string) => {
    const skillObject: StoryboardSkillAdd = {
      id: skillId,
      name: newName,
      officeLocation: officeLocation ?? "all",
    };
    const response = await putSkill(skillObject);
    if (response) {
      setReloadList(true);
      setResponseText("Uppdaterad!");
    } else {
      setResponseText("Uppdateringsfel!");
    }
    setPostUpdateResponse(true);
  };

  const removeSkill = async (skillId: number) => {
    const skillObject: StoryboardSkillAdd = {
      id: skillId,
      name: "",
      officeLocation: officeLocation ?? "all",
    };
    const response = await deleteSkill(skillObject);
    if (response) {
      setUserValue("");
      setUpdateValue("");
      setReloadList(true);
      setResponseText("Kompetensen har raderats");
    } else {
      setResponseText("Raderingsfel!");
    }
    setShowModalHeaderAndDeleteButton(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserValue(e.target.value);
  };

  const handleFetchSkills = async () => {
    const skills = await getAllSkills();
    setSkills(skills);
  };

  const removeSkillHandler = (skill: StoryboardSkillAdd | undefined) => {
    setShowModal(true);
    setShowUpdateOkButton(false);
  };

  const updateSkillHandler = () => {
    setShowUpdateOkButton(true);
  };

  const handleSkillClick = (skill: StoryboardSkillAdd | undefined) => {
    setSelectedId(skill);
    if (skill && skill.name) {
      setUpdateValue(skill.name);
    }

    if (window.innerWidth <= 768) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // setPostRemoveResponse(false);
    setShowModalHeaderAndDeleteButton(true);
    setSelectedId(undefined);
    setPostRemoveResponse(true);
    setResponseText("");
    // setUpdateValue("");
  };

  return (
    <Row className="mb-3">
      <Col>
        {selectedId && (
          <AdminAccordionSkillRemoveSkillModal
            handleCloseModal={handleCloseModal}
            postRemoveResponse={postRemoveResponse}
            removeSkill={removeSkill}
            responseText={responseText}
            selectedId={selectedId}
            showModal={showModal}
            showModalHeaderAndDeleteButton={showModalHeaderAndDeleteButton}
            updateValue={updateValue}
          />
        )}
        <AccordionSkill
          addSkill={addSkill}
          handleFetchSkills={handleFetchSkills}
          handleInputChange={handleInputChange}
          handleSkillClick={handleSkillClick}
          postResponse={postResponse}
          postUpdateResponse={postUpdateResponse}
          removeSkillHandler={removeSkillHandler}
          responseText={responseText}
          selectedId={selectedId!}
          setUpdateValue={setUpdateValue}
          showUpdateOkButton={showUpdateOkButton}
          skills={skills}
          updateSkill={updateSkill}
          updateSkillHandler={updateSkillHandler}
          updateValue={updateValue}
          userValue={userValue}
        />
      </Col>
    </Row>
  );
};

export default AdminAccordionSkills;
