interface ProfileSubHeaderProps{
    headerValue: string;
}

const ProfileSubHeader: React.FC<ProfileSubHeaderProps> = (props) => {

    return(
        <h4 className="text-center">{props.headerValue}</h4>
    )
}

export default ProfileSubHeader;