import { Container, Row, Col } from "react-bootstrap";
import ButtonPrimary from "../../../atoms/ButtonPrimary/ButtonPrimary";

interface SignInButtonProps {
  handleLogin: () => void;
}

const SignInButton: React.FC<SignInButtonProps> = (props) => {
  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Row>
        <Col>
          <ButtonPrimary
            isLarge={true}
            text="Logga in"
            handleClick={props.handleLogin}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default SignInButton;
