import {
  DeleteFilled,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { EmployeeDetailsDto } from "../../../organisms/UserProfile/models/EmployeeDetailsDto";
import { SkillFromDbDto } from "../../../../models/Dto/SkillFromDbDto";

export interface MyProfileSkillSelectorMobileProps {
  details: EmployeeDetailsDto;
  allSkills?: SkillFromDbDto[];
  skillDuplicate: string;
  skillMissingValue: string;
  showSkillRequest: boolean;
  isThereADuplicate: () => boolean | undefined;
  isThereAnInvalidSkillLevel: () => boolean | undefined;
  handleChange: (newValue: number, changeIndex: number) => void;
  handleSkillLevelChange: (value: string, index: number) => void;
  handleRemove: (removeIndex: number) => void;
  handleAddSkill: () => void;
  setShowSkillRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyProfileSkillSelectorMobile: React.FC<
  MyProfileSkillSelectorMobileProps
> = (props) => {
  const getSkillName = (skillId: number) => {
    const skill = props.allSkills?.find((skill) => skill.id === skillId);
    return skill ? skill.name : "Välj kompetens";
  };

  const handleIncrement = (index: number, currentLevel: number) => {
    const newValue = Math.min(currentLevel + 1, 5).toString();
    props.handleSkillLevelChange(newValue, index);
  };

  const handleDecrement = (index: number, currentLevel: number) => {
    const newValue = Math.max(currentLevel - 1, 1).toString();
    props.handleSkillLevelChange(newValue, index);
  };

  const renderTooltip = () => (
    <Tooltip id="button-tooltip">
      Saknar du en kompetens i listan? Klicka här för att efterfråga den!
    </Tooltip>
  );

  return (
    <>
      <h3
        className="d-flex justify-content-center mb-4"
        style={{ color: "#701131" }}
      >
        Kompetenser
      </h3>
      {props.details.skills.length > 0}
      {props.details.skills.map((chosenSkill, index) => (
        <Card key={`skill_${index}`} className="mb-3">
          <Card.Body>
            <Card.Title>{getSkillName(chosenSkill.skillId)}</Card.Title>
            <Row className="d-flex justify-content-between align-items-center hstack mt-3">
              <Col xs={8} className="d-flex align-items-center">
                <Form.Select
                  value={chosenSkill.skillId}
                  onChange={(e) =>
                    props.handleChange(parseInt(e.target.value), index)
                  }
                >
                  <option value={-1}>Välj kompetens...</option>
                  {props.allSkills?.map((skill) => (
                    <option key={skill.id} value={skill.id}>
                      {skill.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xs={4} className="d-flex justify-content-start hstack">
                <OverlayTrigger placement="top" overlay={renderTooltip()}>
                  <Button
                    className="add-skill-btn d-flex align-items-center h-100 me-3"
                    onClick={() => props.setShowSkillRequest(true)}
                  >
                    <QuestionCircleOutlined />
                  </Button>
                </OverlayTrigger>
                <Button
                  size="lg"
                  onClick={() => props.handleRemove(index)}
                  className="add-skill-btn d-flex align-items-center h-100"
                >
                  <DeleteFilled />
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center hstack mt-3">
              <Col xs={8} className="hstack d-flex align-items-center ">
                <h6 className="my-0 me-2">Nivå </h6>
                <Form.Control
                  className="w-75"
                  value={
                    chosenSkill.skillLevel > 0 ? chosenSkill.skillLevel : ""
                  }
                  type="number"
                  min={1}
                  max={5}
                  onChange={(e) =>
                    props.handleSkillLevelChange(e.target.value, index)
                  }
                />
              </Col>
              <Col xs={4} className="d-flex justify-content-start hstack">
                <ButtonSecondary
                  className="me-3 fw-bold"
                  handleClick={() =>
                    handleDecrement(index, chosenSkill.skillLevel)
                  }
                  text="-"
                />
                <ButtonSecondary
                  className="fw-bold"
                  handleClick={() =>
                    handleIncrement(index, chosenSkill.skillLevel)
                  }
                  text="+"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
      <Col className="d-flex justify-content-center">
        <ButtonSecondary
          handleClick={props.handleAddSkill}
          text="Lägg till ny rad"
          className="d-flex align-items-center w-auto"
        />
      </Col>
      <Col className="mt-3 d-flex justify-content-center align-items center my-0 vstack">
        {props.isThereADuplicate() && (
          <p className="d-flex align-items-center text-danger fw-bold my-0">
            <ExclamationCircleOutlined
              style={{ color: "#dc3545", marginRight: "5px" }}
            />
            {props.skillDuplicate}
          </p>
        )}
        {props.isThereAnInvalidSkillLevel() && (
          <p className="d-flex align-items-center text-danger fw-bold my-0">
            <ExclamationCircleOutlined
              style={{ color: "#dc3545", marginRight: "5px" }}
            />
            {props.skillMissingValue}
          </p>
        )}
      </Col>
    </>
  );
};

export default MyProfileSkillSelectorMobile;
