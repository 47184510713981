import { useEffect, useState } from "react";
import { JobTitleFromDbDto } from "../../../models/Dto/JobTitleFromDbDto";
import {
  Accordion,
  Card,
  Col,
  Form,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {} from "../../../services/EmployeeService";
import "./AdminAccordionJobTitles.css";
import { StoryboardSkillAdd } from "../../../models/Dto/StoryBoardSkillAdd";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import {
  deleteJobTitle,
  getAllJobTitles,
  postNewJobTitle,
  putJobTitle,
} from "../../../services/JobTitleService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const AdminAccordionJobTitles: React.FC = () => {
  const [jobTitles, setJobTitles] = useState<JobTitleFromDbDto[]>([]);
  const [userValue, setUserValue] = useState<string>("");
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [postResponse, setPostResponse] = useState<boolean>(false);
  const [postUpdateResponse, setPostUpdateResponse] = useState<boolean>(false);
  const [postRemoveResponse, setPostRemoveResponse] = useState<boolean>(false);
  const [responseText, setResponseText] = useState<string>("");
  const [showUpdateOkButton, setShowUpdateOkButton] = useState<boolean>(false);
  const [updateValue, setUpdateValue] = useState<string>("");
  const [selectedId, setSelectedId] = useState<StoryboardSkillAdd>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalHeaderAndDeleteButton, setShowModalHeaderAndDeleteButton] =
    useState<boolean>(true);

  // Denna kan läggas till om vi väljer att knyta kompetenser mot specifika officeLocations

  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );

  // Update list useEffect
  useEffect(() => {
    const fetchJobTitles = async () => {
      const fetchedSkills = await getAllJobTitles();
      setJobTitles(fetchedSkills);
    };
    fetchJobTitles();
    setReloadList(false);
  }, [reloadList]);

  // Add jobTitle useEffects
  useEffect(() => {
    const timer = setInterval(() => {
      setResponseText("");
      setPostResponse(false);
      clearInterval(timer);
    }, 3000);

    return () => clearInterval(timer);
  }, [postResponse]);

  // Remove jobTitle useEffects
  useEffect(() => {
    setSelectedId(undefined);
    setUpdateValue("");
  }, [postRemoveResponse]);

  // Update jobTitle useEffects
  useEffect(() => {
    setUpdateValue("");
    setSelectedId(undefined);
    setShowUpdateOkButton(false);
    const timer = setInterval(() => {
      setResponseText("");
      setPostUpdateResponse(false);
      clearInterval(timer);
    }, 3000);

    return () => clearInterval(timer);
  }, [postUpdateResponse]);

  const addJobTitle = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (
      !userValue ||
      jobTitles.some((x) => x.name.toLowerCase() === userValue.toLowerCase())
    ) {
      setResponseText(`Fel! Rollen finns redan.`);
      setPostResponse(true);
      return;
    }
    if (userValue.trim() !== "") {
      const skillObject: StoryboardSkillAdd = {
        name: userValue,
        officeLocation: officeLocation,
      };
      const response = await postNewJobTitle(skillObject);
      if (response === true) {
        setReloadList(true);
        setUserValue("");
        setResponseText("Tillagd!");
      } else {
        setResponseText("Fel!");
      }
      setPostResponse(true);
    }
  };

  const updateJobTitle = async (skillId: number, newName: string) => {
    const skillObject: StoryboardSkillAdd = {
      id: skillId,
      name: newName,
      officeLocation: officeLocation,
    };
    const response = await putJobTitle(skillObject);
    if (response) {
      setReloadList(true);
      setResponseText("Uppdaterad!");
    } else {
      setResponseText("Uppdateringsfel!");
    }
    setPostUpdateResponse(true);
  };

  const removeJobTitle = async (skillId: number) => {
    const skillObject: StoryboardSkillAdd = {
      id: skillId,
      name: "",
      officeLocation: officeLocation,
    };
    const response = await deleteJobTitle(skillObject);
    if (response) {
      setSelectedId(undefined);
      setUserValue("");
      setReloadList(true);
      setResponseText("Rollen har raderats");
    } else {
      setResponseText("Raderingsfel!");
    }
    setShowModalHeaderAndDeleteButton(false);
    setPostRemoveResponse(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserValue(e.target.value);
  };

  const handleFetchJobTitles = async () => {
    const jobTitles = await getAllJobTitles();
    setJobTitles(jobTitles);
  };

  const removeJobTitleHandler = (skill: StoryboardSkillAdd | undefined) => {
    setShowModal(true);
    setShowUpdateOkButton(false);
  };

  const updateJobTitleHandler = () => {
    setShowUpdateOkButton(true);
  };

  const handleJobTitleClick = (jobTitle: StoryboardSkillAdd | undefined) => {
    setSelectedId(jobTitle);
    if (jobTitle && jobTitle.name) {
      setUpdateValue(jobTitle.name);
    }

    if (window.innerWidth <= 768) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPostRemoveResponse(false);
    setShowModalHeaderAndDeleteButton(true);
  };

  return (
    <Row>
      <Col>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {showModalHeaderAndDeleteButton ? "Ta bort denna roll?" : ""}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {updateValue && (
                <Form.Control type="text" value={updateValue} disabled={true} />
              )}
            </Form>
            {postRemoveResponse && (
              <h4 className="response-text text-center my-auto">
                {responseText}
              </h4>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ButtonCancel text="Stäng" handleClick={handleCloseModal} />

            {showModalHeaderAndDeleteButton && (
              <ButtonSecondary
                handleClick={() => removeJobTitle(selectedId?.id!)}
                text="Ta bort"
              />
            )}
          </Modal.Footer>
        </Modal>

        <Accordion onSelect={handleFetchJobTitles}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Hantera roller
              <span className="ms-5" style={{ color: "red" }}>
                ***Ej implementerad, kräver utökad behörighet***
              </span>
            </Accordion.Header>
            <Accordion.Body className="shadow rounded">
              <Row className="accordion-box">
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Row className="mt-3 mb-md-0">
                    <Col>
                      <Form onSubmit={addJobTitle}>
                        <Form.Group>
                          <Form.Label>
                            <h5 className="page-header">Lägg till roll</h5>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={userValue}
                            onChange={handleInputChange}
                            placeholder="Skriv in rollens namn"
                          />
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                      className="d-flex justify-content-start align-items-center my-3"
                    >
                      <ButtonSecondary
                        handleClick={addJobTitle}
                        text="Lägg till"
                        disabled={
                          !userValue ||
                          jobTitles.some(
                            (x) =>
                              x.name.toLowerCase() === userValue.toLowerCase()
                          )
                        }
                      />
                    </Col>
                    <Col
                      xs={8}
                      sm={8}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      className="d-flex justify-content-start align-items-center my-3"
                    >
                      {postResponse && (
                        <p
                          className={`response-text my-auto ${
                            responseText.includes("Fel!")
                              ? "error-text"
                              : "success-text"
                          }`}
                        >
                          {responseText}
                        </p>
                      )}
                      {postUpdateResponse && (
                        <p className="response-text my-auto">{responseText}</p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-md-0">
                    <Form>
                      <Form.Group>
                        <Form.Label>
                          <h5 className="page-header">Ta bort / Ändra roll</h5>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled={!showUpdateOkButton}
                          value={updateValue}
                          onChange={(e) => setUpdateValue(e.target.value)}
                          placeholder="Välj roll från listan"
                        />
                      </Form.Group>
                    </Form>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-start align-items-center my-3">
                      <ButtonCancel
                        text="Ta bort"
                        disabled={selectedId === undefined}
                        handleClick={() => removeJobTitleHandler(selectedId)}
                      />
                      <ButtonSecondary
                        text="Ändra"
                        handleClick={updateJobTitleHandler}
                        disabled={selectedId === undefined}
                        className="ms-4 mb-md-0"
                      />
                      {showUpdateOkButton && (
                        <ButtonSecondary
                          text="Spara"
                          handleClick={() =>
                            updateJobTitle(selectedId?.id!, updateValue)
                          }
                          className="ms-4 mb-md-0"
                          disabled={
                            updateValue === "" ||
                            updateValue === selectedId?.name
                          }
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Row className="mt-3 mb-md-0">
                    <Card
                      className="card-style"
                      style={{
                        overflowY: "scroll",
                      }}
                    >
                      <ListGroup variant="flush">
                        {jobTitles.map((jobTitle, index) => (
                          <ListGroup.Item
                            key={jobTitle.id}
                            onClick={() => handleJobTitleClick(jobTitle)}
                            style={{ cursor: "pointer" }}
                          >
                            {jobTitle.name}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card>
                  </Row>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};

export default AdminAccordionJobTitles;
