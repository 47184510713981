import { Card, CloseButton, Col, Container, Row } from "react-bootstrap";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import "./MissingInformationBanner.css";
import { updateMissingInfo } from "../../../services/EmployeeService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { UpdateMissingInfoDto } from "../../../models/Dto/UpdateMissingInfoDto";
import { updateShowInfo } from "../../../redux/slices/userSlice";

export interface MissingInformationBannerProps {
  missingInfo: string[] | undefined;
  showMissingNow: boolean;
  setShowMissingNow: React.Dispatch<React.SetStateAction<boolean>>;
}

const MissingInformationBanner: React.FC<MissingInformationBannerProps> = (
  props
) => {
  const loggedInUserId: string | undefined = useSelector(
    (state: RootState) => state.user.userId
  );
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const handleRedirect = (path: string, state?: any) => {
    if (state) {
      redirect(path, state);
    } else {
      redirect(path);
    }
  };

  const neverShowAgainAndClose = async () => {
    const change: UpdateMissingInfoDto = {
      userId: loggedInUserId,
      show: false,
    };
    const response = await updateMissingInfo(change);
    if (response !== true) return;
    if (response === true) props.setShowMissingNow(false);
    dispatch(updateShowInfo(change.show));
  };

  return (
    <Container className="mt-1 mb-3">
      {props.showMissingNow &&
        props.missingInfo &&
        props.missingInfo.length > 0 && (
          <Card className="missing-info-card">
            <Card.Header className="header">
              <Row className="d-flex justify-content-between align-items-center">
                <Col
                  xs={5}
                  className="xs-screens d-flex justify-content-start align-items-end "
                >
                  <h5>Att lägga till</h5>
                </Col>
                <Col xs={6} className="xs-screens hstack">
                  {loggedInUserId && (
                    <ButtonCancel
                      text="Visa inte igen"
                      handleClick={() => neverShowAgainAndClose()}
                      className="mt-2 mb-1"
                    />
                  )}
                  <CloseButton
                    onClick={() => props.setShowMissingNow(false)}
                    className="ms-3"
                    variant="white"
                  />
                </Col>

                <Col
                  xl={7}
                  lg={7}
                  md={7}
                  sm={7}
                  className="large-screens  d-flex justify-content-start align-items-end "
                >
                  <h5>Information att lägga till</h5>
                </Col>
                <Col
                  xl={3}
                  lg={3}
                  md={3}
                  sm={5}
                  className="large-screens hstack"
                >
                  {loggedInUserId && (
                    <ButtonCancel
                      text="Visa inte igen"
                      handleClick={() => neverShowAgainAndClose()}
                      className="mt-2 mb-1"
                    />
                  )}
                  <CloseButton
                    onClick={() => props.setShowMissingNow(false)}
                    className="ms-3"
                    variant="white"
                  />
                </Col>
              </Row>
            </Card.Header>

            <Card.Body>
              <Row className="d-flex justify-content-center vstack">
                {props.missingInfo.map((info, index) => (
                  <Col key={`col_${index}`}>
                    <h6>
                      <li>{info}</li>
                    </h6>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <ButtonPrimary
                    text="Uppdatera profil"
                    handleClick={() => handleRedirect(`/myprofile/`)}
                    className="mt-2"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
    </Container>
  );
};

export default MissingInformationBanner;
