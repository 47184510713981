import React from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { EmployeeActivationDto } from "../../../../models/Dto/EmployeeActivationDto";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./EmployeeDisplayer.css";

export interface EmployeeDisplayerProps {
  employees: EmployeeActivationDto[] | undefined;
  confirmChange: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEmployeeToUpdate: React.Dispatch<
    React.SetStateAction<EmployeeActivationDto | undefined>
  >;
  setShowInitialInfo: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEmployeeToUpdate: EmployeeActivationDto | undefined;
  selectedOfficeLocation: string | undefined;
  showInitialInfo: boolean;
}

const EmployeeDisplayer: React.FC<EmployeeDisplayerProps> = (props) => {
  const handleOnChange = (employee: EmployeeActivationDto) => {
    const updatedEmployee = {
      ...employee,
      isActive: !employee.isActive,
    };

    props.setSelectedEmployeeToUpdate(updatedEmployee);
    props.setShowModal(true);
  };

  return (
    <Container>
      <Row>
        {props.employees && (
          <Col className="hstack">
            <h3 className="text-start" style={{ color: "#701131" }}>
              {props.selectedOfficeLocation}
            </h3>
            {!props.showInitialInfo && (
              <h5>
                <QuestionCircleOutlined
                  className="question-mark ms-2"
                  onClick={() => props.setShowInitialInfo(true)}
                />
              </h5>
            )}
          </Col>
        )}
      </Row>
      {props.employees?.map((employee, index) => (
        <Card
          key={index}
          className="d-flex hstack justify-content-between align-items-center my-2 py-2 px-2"
          onClick={() => handleOnChange(employee)}
        >
          <h6 className="my-auto py-auto">{employee.displayName}</h6>
          <Form.Check
            type="switch"
            id={`custom-switch-${index}`}
            checked={employee.isActive}
            onChange={() => handleOnChange(employee)}
            className="my-auto"
          />
        </Card>
      ))}
    </Container>
  );
};

export default EmployeeDisplayer;
