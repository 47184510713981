import { Col, Form, Modal, Row } from "react-bootstrap";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import ButtonPrimary from "../../atoms/ButtonPrimary/ButtonPrimary";
import { useState } from "react";
import { AdminMessageDto } from "../../../models/Dto/AdminMessageDto";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  doesSkillExist,
  skillMessageRequest,
} from "../../../services/SkillService";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import { CheckCircleOutlined } from "@ant-design/icons";

export interface AddSkillRequestModalProps {
  showSkillRequest: boolean;
  setShowSkillRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddSkillRequestModal: React.FC<AddSkillRequestModalProps> = (props) => {
  const [skillname, setSkillname] = useState<string>("");
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [showResponseMessage, setShowResponseMessage] =
    useState<boolean>(false);
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const initialOfficeLocation: string = useSelector(
    (state: RootState) => state.user.initialOfficeLocation
  );

  const addSkillRequestHandler = async () => {
    if (skillname !== "") {
      const exists = await doesSkillExist(skillname);
      if (exists === true) {
        setResponseMessage(`${skillname} finns redan i listan!`);
        setShowResponseMessage(true);
        return;
      } else {
        let newRequest: AdminMessageDto = {
          sender: userId,
          skillName: skillname,
          senderOfficeLocation: initialOfficeLocation,
        };
        const response = await skillMessageRequest(newRequest);
        if (response === "Ok") {
          props.setShowSkillRequest(false);
        } else {
          setResponseMessage(response);
          setShowResponseMessage(true);
        }
      }
    }
  };

  const resetAndCloseModal = () => {
    props.setShowSkillRequest(false);
    setSkillname("");
    setResponseMessage("");
    setShowResponseMessage(false);
  };

  return (
    <Modal show={props.showSkillRequest} centered>
      <Modal.Header className="modal-header-styler w-auto">
        <h5 className="mx-auto pb-2">Förfrågan om att lägga till kompetens</h5>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={skillname}
          type="text"
          placeholder="Skriv in kompetensens namn..."
          onChange={(e) => setSkillname(e.target.value)}
          onBlur={() => setSkillname(skillname.trim())}
          className="w-100"
          maxLength={50}
          readOnly={showResponseMessage}
        ></Form.Control>
      </Modal.Body>
      <Modal.Footer>
        {!showResponseMessage && (
          <Row className="d-flex justify-content-end">
            <Col className="hstack">
              <ButtonCancel text="Avbryt" handleClick={resetAndCloseModal} />
              <ButtonPrimary
                text="Skicka förfrågan"
                handleClick={addSkillRequestHandler}
                className="ms-3"
              />
            </Col>
          </Row>
        )}
        {showResponseMessage && (
          <Row className="hstack d-flex justify-content-start">
            {responseMessage === "Förfrågan skapad." && (
              <Col className="hstack d-flex justify-content-center align-items-center">
                <h1>
                  <CheckCircleOutlined
                    className="mb-1"
                    style={{ color: "green" }}
                  />
                </h1>
                <ButtonSecondary
                  text="Stäng"
                  handleClick={resetAndCloseModal}
                  className="ms-4"
                />
              </Col>
            )}
            {responseMessage !== "Förfrågan skapad." && (
              <Col className="hstack">
                <h6 style={{ color: "red" }}>{responseMessage}</h6>
                <ButtonSecondary
                  text="Stäng"
                  handleClick={resetAndCloseModal}
                  className="ms-4"
                />
              </Col>
            )}
          </Row>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddSkillRequestModal;
