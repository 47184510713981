import { Col, Form } from "react-bootstrap";
import { EmployeeDetailsDto } from "../../../organisms/UserProfile/models/EmployeeDetailsDto";
import "./MyProfileExperience.css";

export interface MyProfileExperienceProps {
  details: EmployeeDetailsDto;
  handleChangeExperience: (value: number) => void;
}

const MyProfileExperience: React.FC<MyProfileExperienceProps> = (props) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1980 + 1),
    (val, index) => 1980 + index
  ).reverse();

  return (
    <Col xl={6} lg={6} md={6} sm={6} xs={12} className="hstack">
      <Form.Group className="w-100">
        <h3
          className="mobile-display d-flex justify-content-center mb-4"
          style={{ color: "#701131" }}
        >
          Erfarenhet
        </h3>
        <Form.Label>Branschvana sedan år</Form.Label>
        <Form.Select
          value={props.details.experienceSince ?? ""}
          className="select-text mb-3"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            props.handleChangeExperience(parseInt(e.target.value))
          }
        >
          <option value="" disabled={true}>
            Välj ditt startår i branschen
          </option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};

export default MyProfileExperience;
