import { Dropdown } from "react-bootstrap";
import { StoryboardDto } from "../../../../models/Dto/StoryboardDto";

interface PaginationDropdownProps {
  requestedPage: number;
  setRequestedPage: React.Dispatch<React.SetStateAction<number>>;
  data: StoryboardDto;
}

const PaginationDropdown: React.FC<PaginationDropdownProps> = (props) => {
  return (
    <Dropdown className="shadow">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Sida: {props.requestedPage}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Array.from(
          { length: props.data.storyboardPagination.totalPages },
          (_, index) => index + 1
        ).map((pageNumber) => (
          <Dropdown.Item
            key={pageNumber}
            onClick={() => props.setRequestedPage(pageNumber)}
          >
            {pageNumber}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PaginationDropdown;
