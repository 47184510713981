import { Card, Col, Row } from "react-bootstrap";
import { BrowseEmployeeDto } from "../../../models/Dto/EmployeeSummaryDto";
import { StarFilled } from "@ant-design/icons";
import { SortingPriority } from "../../../models/Enums/SortingPriority";
import Portrait from "../../atoms/DummyPhotos/Portrait";
import "./EmployeeSummaryCard.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getPhoto } from "../../../services/EmployeeService";

interface EmployeeSummaryCardProps {
  employee: BrowseEmployeeDto;
}

const EmployeeSummaryCard: React.FC<EmployeeSummaryCardProps> = (props) => {
  const queryClient = useQueryClient();
  const redirect = useNavigate();

  const { data: photo } = useQuery<string>({
    queryFn: () => getPhoto(props.employee.userId ?? ""),
    queryKey: [`photo${props.employee.userId}`],
  });

  const capitalize = (value: string) => {
    return value
      .split(" ")
      .map((item) => {
        return (
          item.substring(0, 1).toUpperCase() + item.substring(1, item.length)
        );
      })
      .join(" ");
  };

  const handleEmployeeClick = () => {
    if (!props.employee.userId) {
      alert("Något gick fel");
    }
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    queryClient.invalidateQueries({ queryKey: ["employeedetails"] });
    redirect(`/userprofile/${props.employee.userId}`);
  };

  return (
    <Card className="shadow mt-3 card-given-name" onClick={handleEmployeeClick}>
      <Card.Title className="text-center pt-2 mb-0 rounded">
        <h4 className="">{props.employee.givenName}</h4>
        <h6>{props.employee.surName}</h6>
      </Card.Title>
      <Card.Header className="card-headerBrowse">
        <Row className="image-holder">
          <Col className="d-flex justify-content-center">
            {!photo && (
              <Portrait url="/nophoto.svg"></Portrait>
            )}
            {photo && <Portrait src={photo}></Portrait>}
          </Col>
        </Row>
      </Card.Header>
      <Card.Body
        style={{
          backgroundColor: "#701131",
          color: "white",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <Card.Footer className="text-center border-0 p-0 rounded">
          {(props.employee.sortingPriority === SortingPriority.SkillFullMatch ||
            props.employee.sortingPriority ===
              SortingPriority.SkillPartialMatch) && (
            <Col>
              <Row className="d-flex hstack justify-content-between">
                <strong className="me-2">
                  {props.employee.matchingSkillName &&
                    capitalize(props.employee.matchingSkillName)}
                </strong>
              </Row>
              <Row>
                <Col>
                  {Array.from({
                    length: props.employee.matchingSkillLevel ?? 0,
                  }).map((item, index) => (
                    <StarFilled
                      className="skill-level-star"
                      key={`user_skill_star${index + 1}`}
                    />
                  ))}
                </Col>
              </Row>
            </Col>
          )}
          <Col>
            <div>
              <p className="my-0 wrapper">
                {props.employee.jobTitle ? props.employee.jobTitle : "\u00A0"}
              </p>
            </div>
          </Col>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
};

export default EmployeeSummaryCard;
