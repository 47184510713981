import { Dropdown } from "react-bootstrap";

interface PaginationProps {
  storyLimit: number;
  setStoryLimitAndUpdatePage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationLimitDropdown: React.FC<PaginationProps> = (props) => {
  const limitList: number[] = [5, 10];

  const handleLimitChange = (limit: number) => {
    props.setStoryLimitAndUpdatePage(limit);
  };

  return (
    <Dropdown className="shadow">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Visa antal: {props.storyLimit}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {limitList.map((limit, index) => (
          <Dropdown.Item key={index} onClick={() => handleLimitChange(limit)}>
            {limit}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default PaginationLimitDropdown;
