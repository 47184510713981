import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateIsMyProfileActive } from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/store";
import { getEmployeeDetails } from "../../../services/EmployeeService";
import ButtonSecondary from "../../atoms/ButtonSecondary/ButtonSecondary";
import ErrorHandler from "../../atoms/ReactQueryError/ErrorHandler";
import Loading from "../../atoms/ReactQueryLoading/Loading";
import EnlargedPhotoModal from "../../molecules/EnlargedPhotoModal/EnlargedPhotoModal";
import ProfileCurrentAssignments from "../../molecules/ProfileAssignment/ProfileCurrentAssignments";
import ProfilePreviousAssignments from "../../molecules/ProfileAssignment/ProfilePreviousAssignments";
import ProfileUpcomingAssignments from "../../molecules/ProfileAssignment/ProfileUpcomingAssignments";
import ProfileContact from "../../molecules/ProfileContact/ProfileContact";
import ProfileHeader from "../../molecules/ProfileHeader/ProfileHeader";
import ProfileSkills from "../../molecules/ProfileSkills/ProfileSkills";
import ProfileTrivia from "../../molecules/ProfileTrivia/ProfileTrivia";
import { EmployeeAssignmentDto } from "../../pages/MyProfilePage/interfaces/EmployeeAssignmentDto";
import { EmployeeTotalDto } from "./models/EmployeeTotalDto";
import styles from "./UserProfile.module.css";

 const UserProfile = (): React.JSX.Element => {
  const userId = useParams<{ userId: string }>();
  const [showEnlargedPhoto, setShowEnlargedPhoto] = useState(false);
  const isEligible: boolean = useSelector(
    (state: RootState) => state.user.isEligibleForAssignments
  );
  const [currentAssignments, setCurrentAssignments] = useState<
    EmployeeAssignmentDto[]
  >([]);
  const [upcomingAssignments, setUpcomingAssignments] = useState<
    EmployeeAssignmentDto[]
  >([]);
  const [previousAssignments, setPreviousAssignments] = useState<
    EmployeeAssignmentDto[]
  >([]);
  const dispatch = useDispatch();
  const redirect = useNavigate();

  const {
    data: details,
    isLoading,
    error,
  } = useQuery<EmployeeTotalDto, Error>({
    queryFn: () => getEmployeeDetails(userId.userId ?? ""),
    queryKey: ["employeedetails", userId.userId],
  });

  const loggedInUserId: string | undefined = useSelector(
    (state: RootState) => state.user.userId
  );

  useEffect(() => {
    if (details?.assignments) {
      setCurrentAssignments(validCurrentAssignments(details.assignments));
      setUpcomingAssignments(validUpcomingAssignments(details.assignments));
      setPreviousAssignments(validPreviousAssignments(details.assignments));
    }
  }, [details]);

  useEffect(() => {
    dispatch(updateIsMyProfileActive(true));
  }, [dispatch]);

  if (isLoading) {
    return <Loading loadingMessage="Laddar din profil..." />;
  }

  if (error) {
    return <ErrorHandler queryError={error.message} />;
  }

  return (
    <>
      {!isLoading && details && (
        <Container className="py-4">
          <Container
            className={
              styles["profile-container"] + " rounded shadow bg-white pb-3 mb-2"
            }
          >
            {showEnlargedPhoto && details.photo && (
              <EnlargedPhotoModal
                displayName={details.displayName}
                imageSource={details.photo}
                setShowEnlargedPhoto={setShowEnlargedPhoto}
                showEnlargedPhoto={showEnlargedPhoto}
              />
            )}
            <Row className="d-flex justify-content-between mt-2 px-2 pt-2">
              <ButtonSecondary
                handleClick={() => redirect(-1)}
                text="Tillbaka"
                className="w-auto mt-2 mt-sm-3 ms-2 ms-sm-3"
              />
              {loggedInUserId === details.userId && (
                <ButtonSecondary
                  handleClick={() => redirect("/myprofile", { replace: true })}
                  text="Redigera"
                  className="w-auto mt-2 mt-sm-3 me-2 me-sm-3"
                />
              )}
            </Row>
            <div className={styles["fixed-width-container"]}>
              <ProfileHeader
                imageSource={details.photo ?? ""}
                name={details.displayName ? details.displayName : "Saknas"}
                role={details.jobtitle}
                officeLocation={details.officeLocation ?? ""}
                businessPhones={details.businessPhones}
                experienceSince={details.experienceSince}
                hiredSince={
                  details.createdDate ? details.createdDate : "Saknas"
                }
                email={
                  details.userPrincipalName
                    ? details.userPrincipalName
                    : "Saknas"
                }
                managerId={details.managerId}
                managerDisplayName={
                  details.managerDisplayName
                    ? details.managerDisplayName
                    : "Saknas"
                }
                setShowEnlargedPhoto={setShowEnlargedPhoto}
              />
              <ProfileContact
                email={
                  details.userPrincipalName
                    ? details.userPrincipalName
                    : "Saknas"
                }
              />

              <hr />
              <ProfileSkills skills={details.skills} />
              {isEligible && (
                <>
                  <ProfileCurrentAssignments
                    currentAssignments={currentAssignments}
                  />
                  <hr />
                  {upcomingAssignments.length > 0 && (
                    <>
                      <ProfileUpcomingAssignments
                        upcomingAssignment={upcomingAssignments}
                      />
                      <hr />
                    </>
                  )}
                </>
              )}
              {previousAssignments.length > 0 && (
                <ProfilePreviousAssignments
                  previousAssignments={previousAssignments}
                />
              )}
              <hr />
              <ProfileTrivia trivia={details.trivia} />
            </div>
          </Container>
        </Container>
      )}
    </>
  );
}

function validCurrentAssignments(assignments: EmployeeAssignmentDto[]) {
  return assignments?.filter((a) => {
    const startDate = new Date(
      a.assignmentStartDate ? a.assignmentStartDate : ""
    );
    const endDate = a.assignmentEndDate ? new Date(a.assignmentEndDate) : null;
    const today = new Date();
    return startDate <= today && (endDate === null || today <= endDate);
  });
}

function validUpcomingAssignments(assignments: EmployeeAssignmentDto[]) {
  return assignments?.filter((a) => {
    const startDate = new Date(
      a.assignmentStartDate ? a.assignmentStartDate : ""
    );
    return startDate > new Date();
  });
}

function validPreviousAssignments(assignments: EmployeeAssignmentDto[]) {
  return (
    assignments?.filter(
      (assignment) =>
        assignment.assignmentEndDate &&
        new Date(assignment.assignmentEndDate) < new Date() &&
        assignment.client !== "Saknar uppdrag"
    ) || []
  );
}

export default UserProfile;