import { Form } from "react-bootstrap";
import "../AdminAccordionSkills.css";

interface AddSkillFormProps {
  addSkill: (event?: React.FormEvent<HTMLFormElement>) => Promise<void>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userValue: string;
}

const AddSkillForm: React.FC<AddSkillFormProps> = (props) => {
  return (
    <Form onSubmit={props.addSkill}>
      <Form.Group>
        <Form.Label>
          <h5 className="page-header">Lägg till kompetens</h5>
        </Form.Label>
        <Form.Control
          type="text"
          value={props.userValue}
          onChange={props.handleInputChange}
          placeholder="Skriv in kompetensnamn"
          maxLength={50}
        />
      </Form.Group>
    </Form>
  );
};

export default AddSkillForm;
