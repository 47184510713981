import { Row, Col } from "react-bootstrap";
import ButtonEmail from "../../atoms/ContactButtons/ButtonEmail";
import ButtonChat from "../../atoms/ContactButtons/ButtonChat";

interface ProfileContactProps{
    email: string;
}

const ProfileContact: React.FC<ProfileContactProps> = (props) => {

    return (
        <>
            <Row className="d-flex justify-content-center mt-3 mb-4">
                <Col className="d-flex justify-content-end">
                    <ButtonEmail email={props.email}/>
                </Col>
                <Col className="d-flex justify-content-start">
                    <ButtonChat email={props.email}/>
                </Col>
            </Row>
        </>
    )
}

export default ProfileContact;