import { Modal, Image } from "react-bootstrap";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import "./EnlargedPhotoModal.css";

interface EnlargedPhotoModalProps {
  displayName?: string;
  imageSource: string;
  showEnlargedPhoto: boolean;
  setShowEnlargedPhoto: React.Dispatch<React.SetStateAction<boolean>>;
}

const EnlargedPhotoModal: React.FC<EnlargedPhotoModalProps> = (props) => {
  const getImage = () => {
    if (!props.imageSource) return;
    try {
      const byteArray = Array.from(atob(props.imageSource), (c) =>
        c.charCodeAt(0)
      );
      const imageData = btoa(String.fromCharCode.apply(null, byteArray));
      const imageUrl = `data:image/png;base64,${imageData}`;

      return imageUrl;
    } catch {
      return;
    }
  };

  return (
    <Modal
      show={props.showEnlargedPhoto}
      onHide={() => props.setShowEnlargedPhoto(false)}
    >
      <Modal.Header className="enlarged-header d-flex justify-content-center">
        <h2 className="text-center">{props.displayName}</h2>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Image
          src={getImage()}
          alt="User profile picture"
          tabIndex={1}
          width={"auto"}
          className="rounded-circle shadow"
          style={{
            width: "400px",
            height: "400px",
            objectFit: "cover",
          }}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <ButtonCancel
          text="Stäng"
          isLarge={true}
          handleClick={() => props.setShowEnlargedPhoto(false)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EnlargedPhotoModal;
