import { Col, Container, Row } from "react-bootstrap";
import { StoryboardDto } from "../../../models/Dto/StoryboardDto";
import { useQuery } from "@tanstack/react-query";
import { StoryboardRequestDto } from "../../../models/Dto/StoryboardRequestDto";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeStoryboard.css";
import { StoryboardEmployeeDto } from "../../../models/Dto/StoryboardEmployeeDto";
import { getStoryBoard } from "../../../services/StoryboardService";
import Loading from "../../atoms/ReactQueryLoading/Loading";
import ErrorHandler from "../../atoms/ReactQueryError/ErrorHandler";
import NoStories from "./atoms/NoStories";
import StoryHeader from "./atoms/StoryHeader";
import PaginationLimitDropdown from "./atoms/PaginationLimitDropdown";
import StoryCard from "./molecules/StoryCard";
import NoStoriesCard from "./atoms/NoStoriesCard";
import PaginationPageToggler from "./atoms/PaginationPageToggler";
import MissingInformationBanner from "../MissingInfo/MissingInformationBanner";
import {
  getMissingInfo,
  isMissingInfoDisplayed,
  showTutorial,
} from "../../../services/EmployeeService";
import FirstTimeModal from "../../organisms/FirstTimeModal/FirstTimeModal";
import OfficeLocationSelecter from "./atoms/OfficeLocationSelecter";

const HomeStoryboard: React.FC = () => {
  // TEMPORARY UNTIl FIRST TIME MODAL IS FIXED
  const hideFirstTimeModal = true;

  const [showModal, setShowModal] = useState<boolean>(true);
  const [storyLimit, setStoryLimit] = useState<number>(5);
  const [requestedPage, setRequestedPage] = useState<number>(1);
  const showMissingBanner: boolean = useSelector(
    (state: RootState) => state.user.showInfo
  );
  const [showMissingNow, setShowMissingNow] =
    useState<boolean>(showMissingBanner);
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const userId: string = useSelector((state: RootState) => state.user.userId);
  const showTutorialForUser: string = useSelector(
    (state: RootState) => state.user.latestLogin
  );

  const redirect = useNavigate();
  const storyboardRequest: StoryboardRequestDto = {
    officeLocation: officeLocation ?? "",
    limit: storyLimit,
    requestedPage: requestedPage,
  };

  const { data, error, isLoading } = useQuery<StoryboardDto, Error>({
    queryFn: () => getStoryBoard(storyboardRequest),
    queryKey: ["storyboard", officeLocation, requestedPage, storyLimit],
  });

  const { data: show, isLoading: showLoading } = useQuery<boolean, Error>({
    queryFn: () => isMissingInfoDisplayed(userId),
    queryKey: [showMissingBanner],
  });

  const { data: showTutorialData } = useQuery<boolean, Error>({
    queryFn: () => showTutorial(userId),
    queryKey: [showTutorialForUser],
  });

  useEffect(() => {
    if (showMissingBanner !== true) return;
    setShowMissingNow(true);
  }, [showMissingBanner]);

  const {
    data: missing,
    error: missingError,
    isLoading: missingIsLoading,
  } = useQuery<string[]>({
    queryFn: () => getMissingInfo(userId),
    queryKey: [userId],
  });

  const setStoryLimitAndUpdatePage: React.Dispatch<
    React.SetStateAction<number>
  > = (newLimit: number | ((prevLimit: number) => number)) => {
    const limit =
      typeof newLimit === "function"
        ? newLimit(storyLimit)
        : newLimit === 0
        ? 1
        : newLimit;
    if (data) {
      const newTotalPages = Math.ceil(
        data.storyboardPagination.totalItems / limit
      );
      const newRequestedPage =
        requestedPage > newTotalPages ? newTotalPages : requestedPage;

      setStoryLimit(limit);
      setRequestedPage(newRequestedPage);
    }
  };

  const clickHandler = (story?: StoryboardEmployeeDto, event?: string) => {
    const regexPattern = /roll|kompetens/i;
    if (event && regexPattern.test(event)) {
      handleRedirectToMyProfile(event);
    }
    if (story?.userId) {
      handleRedirectToProfilePage(story.userId);
    } else return;
  };

  const handleRedirectToProfilePage = (userId?: string) => {
    if (!userId) return;
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    redirect(`/userprofile/${userId}`, { state: { id: userId } });
  };

  const handleRedirectToMyProfile = (event: string) => {
    if (!event) return;
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    redirect("/myprofile", {replace: true});
  };

  if (isLoading) {
    return <Loading loadingMessage="Laddar stories" />;
  }
  if (error) {
    <ErrorHandler queryError={error.message} />;
  }
  if (!data?.stories) {
    <NoStories />;
  }

  if (missingIsLoading) {
    return <Loading />;
  }
  if (missingError) {
    <ErrorHandler queryError={missingError.message} />;
  }

  return (
    <Row className="mt-1 d-flex justify-content-center">
      <Container>
        {showTutorialData && !hideFirstTimeModal && (
          <FirstTimeModal showModal={showModal} setShowModal={setShowModal} />
        )}
        <Row className="vstack">
          <Col className="homePage-header d-flex justify-content-between align-items-center mt-3 mb-2 gap-2">
            <StoryHeader />
            <OfficeLocationSelecter mobileOrDesktop="officeSelect-desktop" />
            <PaginationLimitDropdown
              storyLimit={storyLimit}
              setStoryLimitAndUpdatePage={setStoryLimitAndUpdatePage}
            />
          </Col>
          <Col>
            {data &&
              data.stories &&
              data.stories.length > 0 &&
              data.stories.map((story) => (
                <StoryCard
                  key={story.id}
                  story={story.storyboardEmployee}
                  event={story.event}
                  eventDate={story.eventDate}
                  officeLocation={story.officeLocation}
                  storyboardType={story.storyboardType}
                  onStoryClick={clickHandler}
                />
              ))}
          </Col>
        </Row>
        <Row>
          <Col>{data && data.stories.length === 0 && <NoStoriesCard />}</Col>
        </Row>
        <Row className="my-3">
          <OfficeLocationSelecter mobileOrDesktop="officeSelect-mobile" />
          {data && data.storyboardPagination.currentPage && (
            <PaginationPageToggler
              data={data}
              requestedPage={requestedPage}
              setRequestedPage={setRequestedPage}
            />
          )}
        </Row>
        <Row>
          {" "}
          {show && showMissingBanner && showMissingNow && (
            <MissingInformationBanner
              missingInfo={missing}
              setShowMissingNow={setShowMissingNow}
              showMissingNow={showMissingNow}
            />
          )}
        </Row>
        {showLoading && (
          <Row>
            <Loading />
          </Row>
        )}
      </Container>
    </Row>
  );
};

export default HomeStoryboard;
