import ProfileSubHeader from "../../atoms/ProfileSubHeader";
import { SkillDto } from "../../organisms/UserProfile/models/SkillDto";
import ProfileSkillList from "./ProfileSkillList";

interface ProfileSkillsProps{
    skills: SkillDto[];
}

const ProfileSkills: React.FC<ProfileSkillsProps> = (props) => {
    
    return(
        <>
            {props.skills && props.skills.length > 0 &&
            <>
                <ProfileSubHeader headerValue="Kompetenser"/>
                <ProfileSkillList skills={props.skills}/>
                <hr/>
            </>
            }
        </>
    )
}

export default ProfileSkills;