import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Pagination } from "../../../models/Dto/EmployeeSummaryDto";

interface SearchResultsLimiterProps {
  pagination: Pagination;
  setPagination: React.Dispatch<SetStateAction<Pagination>>;
}

const SearchResultsLimiter: React.FC<SearchResultsLimiterProps> = (props) => {
  const getLimits = () => {
    return window.innerWidth < 992 ? [10, 25, 50, 100] : [9, 15, 30, 60, 90];
  };

  const [limits, setLimits] = useState<number[]>(getLimits());

  const { pagination, setPagination } = props;

  const handleChangeLimit = useCallback(
    (limit: number) => {
      let temp: Pagination = { ...pagination };
      temp.limit = limit;
      temp.totalPages = Math.ceil(temp.totalItems / temp.limit);
      if (temp.currentPage > temp.totalPages)
        temp.currentPage = temp.totalPages;
      setPagination(temp);
    },
    [pagination, setPagination]
  );

  useEffect(() => {
    const handleResize = () => {
      const newLimits = getLimits();
      setLimits(newLimits);
      handleChangeLimit(newLimits[0]);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleChangeLimit]);

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown-basic">
        Visa antal: {props.pagination.limit}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {limits.map((limit, index) => (
          <Dropdown.Item key={index} onClick={() => handleChangeLimit(limit)}>
            {limit}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchResultsLimiter;
