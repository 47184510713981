import "../AdminAccordionSkills.css";

interface AddUpdateSkillResponseProps {
  postResponse: boolean;
  responseText: string;
  postUpdateResponse: boolean;
}

const AddUpdateSkillResponse: React.FC<AddUpdateSkillResponseProps> = (
  props
) => {
  return (
    <>
      {props.postResponse && (
        <p
          className={`response-text my-auto py-0 ${
            props.responseText.includes("Fel!") ? "error-text" : "success-text"
          }`}
        >
          {props.responseText}
        </p>
      )}
      {props.postUpdateResponse && (
        <p className="response-text my-auto">{props.responseText}</p>
      )}
    </>
  );
};

export default AddUpdateSkillResponse;
