import { Col, Row, Image } from "react-bootstrap";
import "./ProfilePhoto.css";

interface ProfilePhotoProps {
  imageSource: string;
  setShowEnlargedPhoto: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = (props) => {
  const getImage = () => {
    if (!props.imageSource) return;
    try {
      const byteArray = Array.from(atob(props.imageSource), (c) =>
        c.charCodeAt(0)
      );
      const imageData = btoa(String.fromCharCode.apply(null, byteArray));
      const imageUrl = `data:image/png;base64,${imageData}`;

      return imageUrl;
    } catch {
      return;
    }
  };

  return (
    <Row className="mb-3">
      <Col className="d-flex justify-content-center">
        <Image
          src={getImage()}
          alt="User profile picture"
          tabIndex={1}
          width={"auto"}
          className="profile-pic rounded-circle shadow"
          onClick={() => props.setShowEnlargedPhoto(true)}
          style={{
            width: "150px",
            height: "150px",
            objectFit: "cover",
          }}
        />
      </Col>
    </Row>
  );
};

export default ProfilePhoto;
