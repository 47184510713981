import { loginRequest } from "../../../utilities/authConfig";
import { useMsal } from "@azure/msal-react";
import { Container, Row } from "react-bootstrap";
import "./SignInPage.css";
import SignInButton from "./atoms/SignInButton";
import SignInPageBackground from "./atoms/SignInPageBackground";

const SignInPage: React.FC = () => {
  const { instance } = useMsal();
  const heroPath: string = "./Consid-social-photo.jpg";

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      data-testid="background-image-container"
      className="d-flex min-vh-100 min-vw-100 set-bg"
      style={{ backgroundImage: `url(${heroPath})` }}
    >
      <Row className="w-100">
        <SignInPageBackground />
        <SignInButton handleLogin={handleLogin} />
        <div></div>
      </Row>
    </Container>
  );
};

export default SignInPage;
