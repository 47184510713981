import { DeleteFilled, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { EmployeeDetailsDto } from "../../../organisms/UserProfile/models/EmployeeDetailsDto";
import { SkillFromDbDto } from "../../../../models/Dto/SkillFromDbDto";
import { useState } from "react";

export interface MyProfileSkillSelectorDesktopProps {
  details: EmployeeDetailsDto;
  allSkills?: SkillFromDbDto[];
  skillDuplicate: string;
  skillMissingValue: string;
  showSkillRequest: boolean;
  isThereADuplicate: () => boolean | undefined;
  isThereAnInvalidSkillLevel: () => boolean | undefined;
  handleChange: (newValue: number, changeIndex: number) => void;
  handleSkillLevelChange: (value: string, index: number) => void;
  handleRemove: (removeIndex: number) => void;
  handleAddSkill: () => void;
  setShowSkillRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

const MyProfileSkillSelectorDesktop: React.FC<
  MyProfileSkillSelectorDesktopProps
> = (props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const renderTooltip = () => (
    <Tooltip id="button-tooltip">
      Saknar du en kompetens i listan? Klicka här för att efterfråga den!
    </Tooltip>
  );

  return (
    <>
      <Col className="hstack">
        <Col
          xl={6}
          lg={6}
          md={6}
          sm={6}
          className="hstack d-flex align-items-center mb-2"
        >
          <Form.Label className="kompetenser-label my-auto">
            Kompetenser
          </Form.Label>
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip()}
            show={showTooltip}
          >
            <Button
              className="add-skill-btn d-flex align-items-center h-100 ms-3"
              onClick={() => {
                props.setShowSkillRequest(true);
                setShowTooltip(false);
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <QuestionCircleOutlined />
            </Button>
          </OverlayTrigger>
        </Col>
        <Col xl={6} lg={6} md={6} sm={6}>
          <Form.Label className="niva-label mb-2">Nivå</Form.Label>
        </Col>
      </Col>
      <Row className="hstack">
        {props.details.skills.length > 0 && <hr />}
        {props.details.skills.map((chosenSkill, index) => (
          <Row key={`skill_${index}`}>
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              className="d-flex justify-content-start align-items-center mb-3"
            >
              <Form.Select
                value={chosenSkill.skillId}
                onChange={(e) =>
                  props.handleChange(parseInt(e.target.value), index)
                }
                className="me-3 skill-select w-75"
              >
                <option value={-1}>Välj kompetens...</option>
                {props.allSkills &&
                  props.allSkills.map((skill) => (
                    <option key={skill.id} value={skill.id}>
                      {skill.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} className="hstack mb-3">
              <Form.Control
                value={
                  chosenSkill.skillLevel > 0
                    ? chosenSkill.skillLevel.toString()
                    : ""
                }
                type="number"
                min={0}
                max={5}
                onChange={(e) =>
                  props.handleSkillLevelChange(e.target.value, index)
                }
              />
              <Button
                onClick={() => props.handleRemove(index)}
                className="add-skill-btn d-flex align-items-center ms-5 h-100"
              >
                <DeleteFilled />
              </Button>
              <ButtonSecondary
                handleClick={props.handleAddSkill}
                text="+"
                className="d-flex align-items-center ms-5 w-auto"
              />
            </Col>
          </Row>
        ))}
        {props.isThereADuplicate() && (
          <p className="text-danger">{props.skillDuplicate}</p>
        )}
        {props.isThereAnInvalidSkillLevel() && (
          <p className="text-danger">{props.skillMissingValue}</p>
        )}
        {props.details.skills.length > 0 && <hr />}
        {props.details.skills.length === 0 && <Row className="mt-3"></Row>}
      </Row>
    </>
  );
};

export default MyProfileSkillSelectorDesktop;
