import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import React, { SetStateAction } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Pagination } from "../../../models/Dto/EmployeeSummaryDto";

interface PageSelectorProps {
  pagination: Pagination;
  setPagination: React.Dispatch<SetStateAction<Pagination>>;
}

const PageSelector: React.FC<PageSelectorProps> = (props) => {
  const decrementPage = () => {
    if (props.pagination.currentPage > 1) {
      let temp: Pagination = { ...props.pagination };
      temp.currentPage = props.pagination.currentPage - 1;
      props.setPagination(temp);
    }
  };

  const incrementPage = () => {
    if (props.pagination.currentPage < props.pagination.totalPages) {
      let temp: Pagination = { ...props.pagination };
      temp.currentPage = props.pagination.currentPage + 1;
      props.setPagination(temp);
    }
  };

  const handleChangeCurrentPage = (currentPage: number) => {
    let temp: Pagination = { ...props.pagination };
    temp.currentPage = currentPage;
    props.setPagination(temp);
  };

  return (
    <Row className="d-flex ">
      <Col className="d-flex align-items-center my-4">
        <Col className="d-flex align-items-center justify-content-end">
          <h2 className="me-3 align-text-center text-secondary">
            <LeftCircleOutlined
              className={`arrow-button ${
                props.pagination.currentPage === 1 ? "visually-hidden" : ""
              }`}
              onClick={decrementPage}
            />
          </h2>
        </Col>
        <Col className="d-flex justify-content-center mx-0">
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              Sida: {props.pagination.currentPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {Array.from(
                {
                  length: props.pagination.totalPages,
                },
                (_, index) => index + 1
              ).map((pageNumber) => (
                <Dropdown.Item
                  key={pageNumber}
                  onClick={() => handleChangeCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col className="d-flex justify-content-start">
          <h2 className="ms-3 align-text-center text-secondary">
            <RightCircleOutlined
              className={`arrow-button ${
                props.pagination.currentPage < props.pagination.totalPages
                  ? ""
                  : "visually-hidden"
              }`}
              onClick={incrementPage}
            />
          </h2>
        </Col>
      </Col>
    </Row>
  );
};

export default PageSelector;
