import { Row } from "react-bootstrap";
import ProfileSkillListItem from "../ProfileSkillListItem";
import { SkillDto } from "../../../organisms/UserProfile/models/SkillDto";
import "./MoreThanFiveStyler.css";

export interface MoreThanFiveAndShowMobileProps {
  skills: SkillDto[];
  sortBySkillLevelThenBySkillName: (skills: SkillDto[]) => SkillDto[];
  getSkillNameById: (skillID: number) => string;
}

const MoreThanFiveAndShowMobile: React.FC<MoreThanFiveAndShowMobileProps> = (
  props
) => {
  return (
    <Row className="mobile-skills mt-3 d-flex justify-content-center">
      {props.skills &&
        props.skills.length > 0 &&
        props
          .sortBySkillLevelThenBySkillName(props.skills)
          .sort((a, b) => b.skillLevel - a.skillLevel)
          .map((skill) => (
            <ProfileSkillListItem
              key={skill.id}
              skill={skill}
              skillName={props.getSkillNameById(skill.skillId)}
            />
          ))}
    </Row>
  );
};

export default MoreThanFiveAndShowMobile;
