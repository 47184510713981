import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faUsers,
  faFileSignature,
  faHandshake,
  faSkullCrossbones,
  faPen,
  faXmarkCircle,
  faSackXmark,
  faHand,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { StarFilled } from "@ant-design/icons";
import "./GetStoryIcon.css";

interface TypeNumberProps {
  numberOfType: number;
  event?: string;
}

const GetStoryIcon: React.FC<TypeNumberProps> = (props) => {
  if (props.event && props.event.includes("tillgänglig för nya kunduppdrag")) {
    return (
      <Container>
        <Row>
          <Col className="icon-styler">
            <h4>
              <FontAwesomeIcon icon={faSackXmark} />
            </h4>
          </Col>
        </Row>
      </Container>
    );
  }

  const getIcon = (typeNumber: number) => {
    switch (typeNumber) {
      case 1:
        return <FontAwesomeIcon icon={faFileSignature} />;
      case 2:
        return <FontAwesomeIcon icon={faHandshake} />;
      case 3:
        return <FontAwesomeIcon icon={faBriefcase} />;
      case 4:
        return <FontAwesomeIcon icon={faUsers} />;
      case 5:
        return <StarFilled />;
      case 6:
        return <FontAwesomeIcon icon={faSkullCrossbones} />;
      case 7:
        return <FontAwesomeIcon icon={faPen} />;
      case 8:
        return <FontAwesomeIcon icon={faSkullCrossbones} />;
      case 9:
        return <FontAwesomeIcon icon={faPen} />;
      case 10:
        return <FontAwesomeIcon icon={faXmarkCircle} />;
      case 11:
        return <FontAwesomeIcon icon={faHand} />;

      default:
        return null;
    }
  };

  return (
    <Container>
      <Row>
        {props.numberOfType && (
          <Col className="icon-styler">
            <h4>{getIcon(props.numberOfType)}</h4>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default GetStoryIcon;
