import { SetStateAction, useEffect } from "react";
import { BrowseEmployeeDto } from "../../../../models/Dto/EmployeeSummaryDto";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllManagersByOfficeLocation } from "../../../../services/OfficeLocationService";
import { ManagerNameDto } from "../../../../models/Dto/ManagerNameDto";
import { Form } from "react-bootstrap";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../../models/Enums/SearchType";

interface ManagerTemplateProps {
  activeSearchArea: string;
  selectedManager: string;
  searchType: SearchType;
  setSelectedManager: React.Dispatch<SetStateAction<string>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
}

const ManagerTemplate: React.FC<ManagerTemplateProps> = (props) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<ManagerNameDto[]>({
    queryKey: ["managers", props.activeSearchArea],
    queryFn: () => getAllManagersByOfficeLocation(props.activeSearchArea),
  });

  const handleChange = async (managerId: string) => {
    props.setSelectedManager(managerId);
    const filter: SearchFilters = { manager: managerId };
    sessionStorage.setItem("filters", JSON.stringify(filter));

    queryClient.invalidateQueries({ queryKey: ["employees"] });
  };

  useEffect(() => {
    if (props.searchType !== SearchType.ManagerSearch) return;
    handleChange(props.selectedManager);
  }, [props.selectedManager]);

  if (isLoading)
    return (
      <h6 className="d-flex justify-content-center align-items-center my-auto">
        Laddar chefer...
      </h6>
    );

  return (
    <Form.Select
      value={props.selectedManager}
      onChange={(e) => handleChange(e.target.value)}
      className="wrapper"
    >
      <option value={""} disabled={props.selectedManager !== ""}>
        Välj en chef
      </option>
      {data && data?.length > 0 && <option disabled>-----------------</option>}
      {data &&
        data.map((manager) => (
          <option key={`skill_${manager.displayName}`} value={manager.userId}>
            {manager.displayName}
          </option>
        ))}
    </Form.Select>
  );
};

export default ManagerTemplate;
