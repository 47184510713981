import { Alert, Container } from "react-bootstrap";

interface ErrorProps {
  queryError?: string;
}

const Error: React.FC<ErrorProps> = (props) => {
  return (
    <Container className="mt-3 text-center">
      <Alert variant="danger">
        {props.queryError ? props.queryError : "Något gick fel"}
      </Alert>
    </Container>
  );
};

export default Error;
