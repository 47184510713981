import React from "react";
import { Col, Row } from "react-bootstrap";

interface officeLocationProps {
  officeLocation: string;
}

const ProfileOfficeLocation: React.FC<officeLocationProps> = ({
  officeLocation,
}) => {
  if (!officeLocation) {
    return null;
  }

  return (
    <Row>
      <Col className="d-flex justify-content-end">
        <label>Kontor:</label>
      </Col>
      <Col className="d-flex justify-content-start gx-0">
        <p className="my-0 ">{officeLocation}</p>
      </Col>
    </Row>
  );
};

export default ProfileOfficeLocation;
