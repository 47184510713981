import axios from "axios";
import { msalInstance } from "..";
import { protectedResources } from "./authConfig";

export const axiosClient = axios.create();

export const getToken = async () =>{ 

    const account = msalInstance.getActiveAccount();
    if(!account) return;
    const result = await msalInstance.acquireTokenSilent({
    scopes: protectedResources.api.scopes,
    redirectUri: "/",
    account: account,
  });

  return result.accessToken
}
axiosClient.interceptors.request.use(
    
    async config => {
        const token = await getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);