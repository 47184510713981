import { SetStateAction, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllTeamsByOfficeLocation } from "../../../../services/AssignmentService";
import { Form } from "react-bootstrap";
import { SearchFilters } from "../../../../models/interfaces/SearchFilters";
import { SearchType } from "../../../../models/Enums/SearchType";
import { AssignmentTeamDto } from "../../../../models/Dto/AssignmentTeamDto";

interface TeamTemplateProps {
  activeSearchArea: string;
  searchType: SearchType;
  selectedTeam: number;
  setSelectedTeam: React.Dispatch<SetStateAction<number>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
}

const TeamTemplate: React.FC<TeamTemplateProps> = (props) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<AssignmentTeamDto[]>({
    queryKey: ["teams", props.activeSearchArea],
    queryFn: () => getAllTeamsByOfficeLocation(props.activeSearchArea),
  });

  const handleChange = async (teamId: number) => {
    if (isNaN(teamId)) props.setSelectedTeam(0);
    else props.setSelectedTeam(teamId);

    const teamName = data
      ? data.find((x) => x.teamId === teamId)?.teamName ?? ""
      : "";
    props.setSearchInfo(teamName);

    const filter: SearchFilters = { teamId: teamId.toString() };
    sessionStorage.setItem("filters", JSON.stringify(filter));

    queryClient.invalidateQueries({ queryKey: ["employees"] });
  };

  useEffect(() => {
    if (props.searchType === SearchType.TeamSearch)
      if (data && !data.find((x) => x.teamId === props.selectedTeam)) {
        props.setSelectedTeam(0);
      }
    queryClient.invalidateQueries({ queryKey: ["employees"] });
  }, [props, data, queryClient]);

  if (isLoading) return <p>Laddar teams...</p>;

  return (
    <Form.Select
      value={props.selectedTeam}
      onChange={(e) => handleChange(+e.target.value)}
      className="wrapper"
    >
      <option value={0} disabled={props.selectedTeam !== 0}>
        Välj ett team
      </option>
      {data &&
        data.map((team) => (
          <option key={team.teamId} value={team.teamId}>
            {`${team.teamName} - ${team.client}`}
          </option>
        ))}
    </Form.Select>
  );
};

export default TeamTemplate;
