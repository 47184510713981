import { Col, Row } from "react-bootstrap";
import AccordionActivation from "./molecules/AccordionActivation";
import ChangeActivationModal from "./molecules/ChangeActivationModal";
import { useState } from "react";
import { EmployeeActivationDto } from "../../../models/Dto/EmployeeActivationDto";

const AdminAccordionActivation: React.FC = () => {
  const [confirmChange, setConfirmChange] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedEmployeeToUpdate, setSelectedEmployeeToUpdate] = useState<
    EmployeeActivationDto | undefined
  >();
  const [responseText, setResponseText] = useState<string | undefined>(
    undefined
  );

  return (
    <Row className="mb-3">
      <Col>
        {showModal && (
          <ChangeActivationModal
            confirmChange={confirmChange}
            selectedEmployeeToUpdate={selectedEmployeeToUpdate}
            showModal={showModal}
            setConfirmChange={setConfirmChange}
            setResponseText={setResponseText}
            setShowModal={setShowModal}
            setSelectedEmployeeToUpdate={setSelectedEmployeeToUpdate}
          />
        )}
        <AccordionActivation
          confirmChange={confirmChange}
          responseText={responseText}
          selectedEmployeeToUpdate={selectedEmployeeToUpdate}
          setResponseText={setResponseText}
          setSelectedEmployeeToUpdate={setSelectedEmployeeToUpdate}
          setShowModal={setShowModal}
        />
      </Col>
    </Row>
  );
};

export default AdminAccordionActivation;
