import { Container } from "react-bootstrap";
import OrganisationTree from "../../organisms/OrganisationTree/OrganisationTreeDesktop";
import "./OrganisationPage.css";
import { useEffect, useState } from "react";
import OrganisationTreeMobile from "../../organisms/OrganisationTree/OrganisationTreeMobile";

const OrganisationPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container fluid>
      {isMobile ? <OrganisationTreeMobile /> : <OrganisationTree />}
    </Container>
  );
};

export default OrganisationPage;
