import { Col, Form, FormLabel, Row } from "react-bootstrap";
import {
  BrowseDto,
  BrowseEmployeeDto,
  Pagination,
} from "../../../models/Dto/EmployeeSummaryDto";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { getBrowseEmployee } from "../../../services/EmployeeService";
import SearchBarInput from "../../atoms/SearchBarInput/SearchBarInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { SearchType } from "../../../models/Enums/SearchType";
import SearchArea from "../../molecules/SearchArea/SearchArea";
import { SearchFilters } from "../../../models/interfaces/SearchFilters";
import SearchOffcanvas from "../../molecules/SearchOffcanvas/SearchOffcanvas";
import ButtonCancel from "../../atoms/ButtonCancel/ButtonCancel";
import SearchResultsLimiter from "../../molecules/SearchResultsLimiter/SearchResultsLimiter";
import ButtonSecondaryWithIndicator from "../../atoms/ButtonSecondary/ButtonSecondaryWithIndicator";
import "./SearchBar.css";
import { SkillCountDto } from "../../../models/Dto/SkillCountDto";

export interface SearchBarProps {
  didSearchReturnEmployee: boolean;
  pagination: Pagination;
  searchType: SearchType;
  searchValue: string;
  selectedManager: string;
  showOffcanvas: boolean;
  showFilterButton: boolean;
  hideFilterButton: boolean;
  isShowOnlyFreeEmployeesChecked: boolean;
  searchResults?: BrowseEmployeeDto[];
  showMonthBar: boolean;
  manualDate?: string;
  selectedOption: string;
  showManualDateInput: boolean;
  expirationDate: string;
  triggerReRender: boolean;
  setTriggerReRender: React.Dispatch<SetStateAction<boolean>>;
  setSkills: React.Dispatch<React.SetStateAction<SkillCountDto[]>>;
  setShowFilterButton: React.Dispatch<React.SetStateAction<boolean>>;
  setHideFilterButton: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOffcanvas: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedManager: React.Dispatch<SetStateAction<string>>;
  setDidSearchReturnEmployee: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<SetStateAction<Pagination>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSearchInfo: React.Dispatch<SetStateAction<string>>;
  setSearchType: React.Dispatch<React.SetStateAction<SearchType>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchResults: React.Dispatch<
    React.SetStateAction<BrowseEmployeeDto[] | undefined>
  >;
  setIsShowOnlyFreeEmployeesChecked: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setShowMonthBar: React.Dispatch<React.SetStateAction<boolean>>;
  setManualDate: React.Dispatch<SetStateAction<string | undefined>>;
  setSelectedOption: React.Dispatch<SetStateAction<string>>;
  setShowManualDateInput: React.Dispatch<React.SetStateAction<boolean>>;
  setExpirationDate: React.Dispatch<SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const officeLocation: string | undefined = useSelector(
    (state: RootState) => state.user.officeLocation
  );
  const userSelectedSearchArea: string | undefined = useSelector(
    (state: RootState) => state.user.userSelectedSearchArea
  );
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>("");
  const [tooltipBgColor, setTooltipBgColor] = useState<string>("");
  const [searchFilterApplied, setSearchFilterApplied] =
    useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    setErrorMessage,
    setSearchResults,
    setDidSearchReturnEmployee,
    setPagination
  } = props;

  const getSearchArea = () => {
    return userSelectedSearchArea
      ? userSelectedSearchArea
      : officeLocation
      ? officeLocation
      : "";
  };

  const getFilters = () => {
    const sessionFilters = sessionStorage.getItem("filters");
    if (sessionFilters) {
      return JSON.parse(sessionFilters) as SearchFilters;
    }
  };

  const getJobTitle = () => {
    const sessionData = sessionStorage.getItem("filters");
    if (!sessionData) return "";
    const filters = JSON.parse(sessionData) as SearchFilters;
    if (!filters || !filters.jobTitle) return "";
    return filters.jobTitle;
  };
  const [selectedJobTitle, setSelectedJobTitle] = useState<string>(getJobTitle);

  const getAssignmentFilters = () => {
    const sessionData = sessionStorage.getItem("filters");
    if (!sessionData) return "";
    const filters = JSON.parse(sessionData) as SearchFilters;
    if (!filters || !filters.client) return "";
    return filters.client;
  };

  const getTeamFilters = () => {
    const sessionData = sessionStorage.getItem("filters");
    if (!sessionData) return 0;
    const filters = JSON.parse(sessionData) as SearchFilters;

    if (!filters || !filters.teamId) return 0;
    const parsedResult = parseInt(filters.teamId);

    if (isNaN(parsedResult)) return 0;

    return parsedResult;
  };

  const [selectedAssignment, setSelectedAssignment] = useState<string>(
    getAssignmentFilters()
  );

  const [selectedTeam, setSelectedTeam] = useState<number>(getTeamFilters());

  const getSkillId = () => {
    const sessionData = sessionStorage.getItem("filters");
    if (!sessionData) return 0;
    const filters = JSON.parse(sessionData) as SearchFilters;
    if (!filters || !filters.skill) return 0;
    const parsedSkillId = parseInt(filters.skill);
    return isNaN(parsedSkillId) ? 0 : parsedSkillId;
  };
  const [selectedSkillId, setSelectedSkillId] = useState<number>(getSkillId());

  const { data, error: browseError } = useQuery<BrowseDto>({
    queryKey: [
      "employees",
      props.searchType,
      props.searchValue,
      userSelectedSearchArea,
      props.pagination.limit,
      props.pagination.currentPage,
      props.isShowOnlyFreeEmployeesChecked,
      props.expirationDate,
    ],
    queryFn: () =>
      getBrowseEmployee(
        getSearchArea(),
        props.pagination.currentPage,
        props.pagination.limit,
        props.searchValue,
        getFilters(),
        props.isShowOnlyFreeEmployeesChecked,
        props.expirationDate
      ),
  });

  const handleChangeSearchValue = (value: string) => {
    if (value.length <= 24) setShowTooltip(false);
    if (value.length > 24 && value.length < 40) {
      setTooltipBgColor("darkorange");
      setTooltipText(tooltipTextHandler(value.length));
      setShowTooltip(true);
    }
    if (value.length === 40) {
      setTooltipBgColor("crimson");
      setTooltipText("Max antal tecken nådd!");
      setShowTooltip(true);
    }

    if (value[0] === " ") {
      value = value.trimStart();
    }

    props.setSearchValue(value);
    if (props.searchType === SearchType.FreeSearch) props.setSearchInfo(value);
  };

  const tooltipTextHandler = (letters: number) => {
    let text: string;
    let lettersLeft = 40 - letters;
    text = `${lettersLeft} tecken kvar...`;
    return text;
  };

  useEffect(() => {
    if (!browseError) {
      return;
    }
    setErrorMessage(browseError.message);
  }, [browseError, setErrorMessage]);

  useEffect(() => {
    if (!data) return;
    setSearchResults(data.employeeSummaryDto);
    setPagination(data.employeePaginationDto);
    if (data.employeeSummaryDto.length > 0) setDidSearchReturnEmployee(true);
    else setDidSearchReturnEmployee(false);
  }, [data, setSearchResults, setPagination, setDidSearchReturnEmployee]);

  const resetTemplates = useCallback(() => {
    sessionStorage.setItem("filters", "");
    sessionStorage.setItem("noAssignmentFilter", "false");
    queryClient.invalidateQueries({ queryKey: ["employees"] });
    props.setSearchType(SearchType.FreeSearch);
    props.setSearchInfo("");
    props.setSearchValue("");
    props.setSelectedManager("");
    setSelectedAssignment("");
    setSelectedJobTitle("");
    setSelectedTeam(0);
    setSelectedSkillId(0);
    props.setIsShowOnlyFreeEmployeesChecked(false);
    props.setManualDate(undefined);
    props.setShowMonthBar(false);
    props.setSelectedOption("");
    props.setShowManualDateInput(false);
    props.setExpirationDate("");
  }, [queryClient, props]);

  useEffect(() => {
    const sessionFilters = sessionStorage.getItem("filters");

    if (sessionFilters === "" || sessionFilters === null) {
      setSearchFilterApplied(false);
    } else {
      try {
        const parsed = JSON.parse(sessionFilters) as SearchFilters;
        if (parsed.manager === "") {
          setSearchFilterApplied(false);
          return;
        } else {
          setSearchFilterApplied(true);
        }
      } catch {
        setSearchFilterApplied(false);
      }
    }

    if (
      props.isShowOnlyFreeEmployeesChecked ||
      props.manualDate !== undefined
    ) {
      setSearchFilterApplied(true);
    }
  }, [
    props.searchResults,
    props.isShowOnlyFreeEmployeesChecked,
    props.manualDate,
  ]);

  return (
    <>
      <Row className="display-desktop hstack mt-3 d-flex align-items-end mb-1">
        <Col
          xxl={4}
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={6}
          className="d-flex align-items-end"
        >
          <Form.Group>
            <FormLabel>Aktuellt sökområde</FormLabel>
            <SearchArea
              searchType={props.searchType}
              setSelectedManager={props.setSelectedManager}
            />
          </Form.Group>
        </Col>
        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
          <Form.Group>
            <FormLabel>Sökfält</FormLabel>
            <SearchBarInput
              handleChange={handleChangeSearchValue}
              searchValue={props.searchValue}
              setShowTooltip={setShowTooltip}
              showTooltip={showTooltip}
              tooltipText={tooltipText}
              tooltipBgColor={tooltipBgColor}
            />
          </Form.Group>
        </Col>
        <Col
          xxl={4}
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={6}
          className="display-desktop d-flex justify-content-end vstack"
        >
          <Row className="display-desktop">
            <Col className="hstack d-flex justify-content-between ">
              <ButtonCancel text="Rensa fält" handleClick={resetTemplates} />
              <SearchResultsLimiter
                pagination={props.pagination}
                setPagination={props.setPagination}
              />
            </Col>
            <Col className="d-flex justify-content-end mt-3">
              {props.showFilterButton && (
                <ButtonSecondaryWithIndicator
                  text="Visa sökfilter >>"
                  handleClick={() => {
                    props.setShowOffcanvas(true);
                    props.setHideFilterButton(true);
                    props.setShowFilterButton(false);
                  }}
                  showIndicator={searchFilterApplied}
                />
              )}
              {props.hideFilterButton && (
                <ButtonSecondaryWithIndicator
                  text="Dölj sökfilter <<"
                  handleClick={() => {
                    props.setShowOffcanvas(false);
                    props.setHideFilterButton(false);
                    props.setShowFilterButton(true);
                  }}
                  showIndicator={searchFilterApplied}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="display-mobile">
        <Col className="display-mobile d-flex justify-content-around mt-3">
          <SearchResultsLimiter
            pagination={props.pagination}
            setPagination={props.setPagination}
          />
          <ButtonCancel text="Rensa" handleClick={resetTemplates} />
          {props.showFilterButton && (
            <ButtonSecondaryWithIndicator
              text="Visa filter >>"
              handleClick={() => {
                props.setShowOffcanvas(true);
                props.setHideFilterButton(true);
                props.setShowFilterButton(false);
              }}
              showIndicator={searchFilterApplied}
            />
          )}
          {props.hideFilterButton && (
            <ButtonSecondaryWithIndicator
              text="Dölj sökfilter <<"
              handleClick={() => {
                props.setShowOffcanvas(false);
                props.setHideFilterButton(false);
                props.setShowFilterButton(true);
              }}
              showIndicator={searchFilterApplied}
            />
          )}
        </Col>
      </Row>
      <SearchOffcanvas
        setTriggerReRender={props.setTriggerReRender}
        triggerReRender={props.triggerReRender}
        setShowOffcanvas={props.setShowOffcanvas}
        showOffcanvas={props.showOffcanvas}
        activeSearchArea={getSearchArea()}
        selectedManager={props.selectedManager}
        setSelectedManager={props.setSelectedManager}
        setSearchValue={props.setSearchValue}
        searchType={props.searchType}
        setSearchType={props.setSearchType}
        setSearchResults={props.setSearchResults}
        setSearchInfo={props.setSearchInfo}
        showFilterButton={props.showFilterButton}
        setShowFilterButton={props.setShowFilterButton}
        hideFilterButton={props.hideFilterButton}
        setHideFilterButton={props.setHideFilterButton}
        isShowOnlyFreeEmployeesChecked={props.isShowOnlyFreeEmployeesChecked}
        setIsShowOnlyFreeEmployeesChecked={
          props.setIsShowOnlyFreeEmployeesChecked
        }
        resetTemplates={resetTemplates}
        selectedJobTitle={selectedJobTitle}
        setSelectedJobTitle={setSelectedJobTitle}
        selectedAssignment={selectedAssignment}
        setSelectedAssignment={setSelectedAssignment}
        selectedSkillId={selectedSkillId}
        setSelectedSkillId={setSelectedSkillId}
        setSelectedTeam={setSelectedTeam}
        selectedTeam={selectedTeam}
        setShowMonthBar={props.setShowMonthBar}
        showMonthBar={props.showMonthBar}
        manualDate={props.manualDate}
        setManualDate={props.setManualDate}
        selectedOption={props.selectedOption}
        setSelectedOption={props.setSelectedOption}
        setShowManualDateInput={props.setShowManualDateInput}
        showManualDateInput={props.showManualDateInput}
        expirationDate={props.expirationDate}
        setExpirationDate={props.setExpirationDate}
      />
    </>
  );
};

export default SearchBar;
