import React, { useEffect, useState } from "react";
import { SkillDto } from "../../organisms/UserProfile/models/SkillDto";
import { useQuery } from "@tanstack/react-query";
import { SkillFromDbDto } from "../../../models/Dto/SkillFromDbDto";
import { getAllSkills } from "../../../services/SkillService";
import ShowMoreSkillsSwitch from "./atoms/ShowMoreSkillsSwitch";
import MoreThanFiveAndDontShow from "./atoms/MoreThanFiveAndDontShow";
import NotMoreThanFiveSkills from "./atoms/NotMoreThanFiveSkills";
import MoreThanFiveAndShowDesktop from "./atoms/MoreThanFiveAndShowDesktop";
import MoreThanFiveAndShowMobile from "./atoms/MoreThanFiveAndShowMobile";

interface ProfileSkillListProps {
  skills: SkillDto[];
}

const ProfileSkillList: React.FC<ProfileSkillListProps> = (props) => {
  const [isThereMoreThanFiveSkills, setIsThereMoreThanFiveSkills] =
    useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(true);
  const { data } = useQuery<SkillFromDbDto[], Error>({
    queryFn: () => getAllSkills(),
    queryKey: [],
  });

  const getSkillNameById = (skillId: number): string => {
    const skillFromDb = data?.find((skill) => skill.id === skillId);
    return skillFromDb ? skillFromDb.name : "Okänd kompetens";
  };

  const sortBySkillLevelThenBySkillName = (skills: SkillDto[]) => {
    return skills.sort((a, b) => {
      const skillLevelDifference = b.skillLevel - a.skillLevel;
      if (skillLevelDifference !== 0) {
        return skillLevelDifference;
      }
      return getSkillNameById(a.skillId).localeCompare(
        getSkillNameById(b.skillId)
      );
    });
  };

  useEffect(() => {
    if (props.skills && props.skills.length > 5)
      setIsThereMoreThanFiveSkills(true);
  }, [props.skills]);

  useEffect(() => {
    setShowDetails(!isThereMoreThanFiveSkills);
  }, [isThereMoreThanFiveSkills]);

  return (
    <>
      {!isThereMoreThanFiveSkills && (
        <NotMoreThanFiveSkills
          getSkillNameById={getSkillNameById}
          skills={props.skills}
          sortBySkillLevelThenBySkillName={sortBySkillLevelThenBySkillName}
        />
      )}
      {isThereMoreThanFiveSkills && showDetails && (
        <>
          <MoreThanFiveAndShowDesktop
            getSkillNameById={getSkillNameById}
            skills={props.skills}
            sortBySkillLevelThenBySkillName={sortBySkillLevelThenBySkillName}
          />
          <MoreThanFiveAndShowMobile
            getSkillNameById={getSkillNameById}
            skills={props.skills}
            sortBySkillLevelThenBySkillName={sortBySkillLevelThenBySkillName}
          />
        </>
      )}
      {isThereMoreThanFiveSkills && !showDetails && (
        <MoreThanFiveAndDontShow
          getSkillNameById={getSkillNameById}
          skills={props.skills}
          sortBySkillLevelThenBySkillName={sortBySkillLevelThenBySkillName}
        />
      )}
      {isThereMoreThanFiveSkills && (
        <ShowMoreSkillsSwitch
          setShowDetails={setShowDetails}
          showDetails={showDetails}
        />
      )}
    </>
  );
};

export default ProfileSkillList;
