import { StoryboardSkillAdd } from "../models/Dto/StoryBoardSkillAdd";
import { JobTitleFromDbDto } from "../models/Dto/JobTitleFromDbDto";
import { axiosClient } from "../utilities/axiosClient";

const uri: string = process.env.REACT_APP_BASE_URI!;

export const deleteJobTitle = async (storyboardSkillUpdate: StoryboardSkillAdd): Promise<boolean> => {
    try {                       
        const response = await axiosClient.delete<boolean>(`${uri}api/JobTitle/JobTitles`, { data: storyboardSkillUpdate });
        return response.data;
    }
    catch (error) {
        console.error('Error deleting jobTitle', error);
        throw error;
    }
}

export const putJobTitle = async (storyboardSkillUpdate: StoryboardSkillAdd): Promise<boolean> => {
    try {                           
        const response = await axiosClient.put<boolean>(`${uri}api/JobTitle/JobTitles`, storyboardSkillUpdate);
        return response.data;
    }
    catch (error) {
        console.error('Error updating jobTitle', error);
        throw error;
    }
}

export const getAllJobTitles = async (): Promise<JobTitleFromDbDto[]> => {
    try {
        const response = await axiosClient.get<JobTitleFromDbDto[]>(`${uri}api/JobTitle/JobTitles`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching JobTitles', error);
        throw error;
    }
}

export const postNewJobTitle = async (storyboardJobTitleAdd: StoryboardSkillAdd): Promise<boolean> => {
    try {          
        const response = await axiosClient.post<boolean>(`${uri}api/JobTitle/JobTitles`, storyboardJobTitleAdd);
        return response.data;
    }
    catch (error) {
        console.error('Error posting new jobTitle', error);
        return false;
    }
}