import { Form, Modal } from "react-bootstrap";
import ButtonCancel from "../../../atoms/ButtonCancel/ButtonCancel";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { StoryboardSkillAdd } from "../../../../models/Dto/StoryBoardSkillAdd";
import "../AdminAccordionSkills.css";

interface RemoveProps {
  postRemoveResponse: boolean;
  responseText: string;
  selectedId: StoryboardSkillAdd;
  showModal: boolean;
  showModalHeaderAndDeleteButton: boolean;
  updateValue: string;
  handleCloseModal: () => void;
  removeSkill: (skillId: number) => Promise<void>;
}

const AdminAccordionSkillRemoveSkillModal: React.FC<RemoveProps> = (props) => {
  return (
    <Modal show={props.showModal} onHide={props.handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.showModalHeaderAndDeleteButton
            ? "Ta bort denna kompetens?"
            : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {props.showModalHeaderAndDeleteButton && (
            <Form.Control
              type="text"
              value={props.updateValue}
              disabled={true}
            />
          )}
        </Form>
        {!props.showModalHeaderAndDeleteButton && (
          <h4 className="response-text text-center my-auto">
            {props.responseText}
          </h4>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonCancel text="Stäng" handleClick={props.handleCloseModal} />

        {props.showModalHeaderAndDeleteButton && (
          <ButtonSecondary
            handleClick={() => props.removeSkill(props.selectedId?.id!)}
            text="Ta bort"
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AdminAccordionSkillRemoveSkillModal;
