import { Modal } from "react-bootstrap";
import ButtonSecondary from "../../../atoms/ButtonSecondary/ButtonSecondary";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface ModalErrorProps {
  show: boolean;
  handleClickYes: () => void;
  text: string;
  onHide?: () => void;
  centered?: boolean;
}

const ModalError: React.FC<ModalErrorProps> = ({
  show,
  handleClickYes,
  text,
  onHide,
  centered = false,
}) => {
  return (
    <Modal onHide={onHide} show={show} centered={centered} className="mx-auto">
      <Modal.Header className="w-auto">
        <ExclamationCircleOutlined
          style={{ color: "red" }}
          className="fs-4 me-2"
        />
        {text}
        <ExclamationCircleOutlined
          style={{ color: "red" }}
          className="fs-4 ms-2"
        />
      </Modal.Header>
      <Modal.Footer>
        <ButtonSecondary text="Tillbaka" handleClick={handleClickYes} />
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
