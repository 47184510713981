import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: process.env.REACT_APP_MSAL_SCOPES!.split(',') // add scopes here
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
    graphMePhotoEndPoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};

export const protectedResources = {
    api: {
      scopes: ["api://1ff05f78-8d46-4877-a0ee-0d1fed9c584c/access_as_user"]
    }  
}

