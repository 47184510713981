import React from "react";
import { Container, Spinner } from "react-bootstrap";

export interface LoadingProps {
  loadingMessage?: string;
}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <Container fluid className="mt-3 d-flex justify-content-center">
      <div className="d-flex justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Laddar...</span>
        </Spinner>
        <span className="ms-3">
          {props.loadingMessage ? props.loadingMessage : "Laddar..."}
        </span>
      </div>
    </Container>
  );
};

export default Loading;
